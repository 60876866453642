import React, { useState, useEffect, useMemo } from "react";
import { Card, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Common from "../../utils/Common";
import { useMeasure, useSetState } from "react-use";
import Tooltip from "@mui/material/Tooltip";
import { useQueryListPromotion } from "../../services/hooks/promotion";
import { formatDateTime, formatDDMMYYYYToMMDDYYYY, getCommonValues } from "../../utils/typeUltils";
import Configuration from "../../configs/Config";
import { Modal } from "react-bootstrap";
import { calCulatePromotion } from "./Form";
import { asyncToast } from "../../utils/toast";

enum PromotionType {
  applyCurrently = 1,
  setCalendar = 2,
}
const listTest = [
  {
    name: "Áp dụng luôn",
    value: PromotionType.applyCurrently,
  },
  {
    name: "Đặt lịch",
    value: PromotionType.setCalendar,
  },
];
export enum PluginType {
  Amount = "f033a5b6-8810-4053-9934-68a19035c763",
  Percent = "f033a5b6-8810-4053-9934-68a19035c767",
  FixedAmount = "7726e2fe-20c7-46b1-b732-10d31ccf7ace",
}
export enum MessagePromotionResponse {
  AllPromotion = 1,
  ApplyCurrently = 0,
}
const convertData = (pluginData: any) => {
  //console.log(pluginData);
  let temp: any = undefined;
  switch (pluginData?.pluginId) {
    case PluginType.Amount:
      temp = JSON.parse(pluginData?.data);
      return "Giảm" + Common.formatMoney(temp.Amount);
    case PluginType.Percent:
      temp = JSON.parse(pluginData?.data);
      return (
        "Giảm " + temp.Percent + "% nhưng không quá " + Common.formatMoney(temp.MaxDiscountAmount)
      );

    case PluginType.FixedAmount:
      return pluginData?.dataContent;
    default:
      break;
  }
};

type Props = {
  onChange: any;
  value: any;
  onSubmit: any;

  isVisible: boolean;
  setIsVisible: any;
};
const VoucherItem = ({
  item,
  setSelected,
  selected,
}: {
  item: any;
  setSelected: any;
  selected: any;
}) => {
  const [status, setStatus] = useState<number>(PromotionType.applyCurrently);
  const [day, setDay] = useState<any>(0);

  useEffect(() => {
    if (status !== PromotionType.setCalendar) {
      setDay(0);
    }
  }, [status]);
  useEffect(() => {
    setDay(selected[item.id]?.ApplyBeforeCheckIn || 0);
  }, []);

  const [ref, { height }] = useMeasure<any>();
  return (
    <Tooltip
      sx={{ marginBottom: "8px" }}
      title={
        <div className="">
          <Typography>
            HSD:{" "}
            <span>
              {formatDateTime(item.fromDate, "DD/MM/YYYY")} -{" "}
              {formatDateTime(item.toDate, "DD/MM/YYYY")}
            </span>
          </Typography>
          <Typography>{convertData(item.pluginDatas[0])}</Typography>
          <Typography>
            Độ ưu tiên: <span>{item.pluginDatas[0]?.priority}</span>
          </Typography>
        </div>
      }
    >
      <div
        ref={ref}
        className="tw-rounded border-1 border-solid tw-shadow-md tw-border-gray-100 tw-border-solid d-flex align-items-center mt-4"
      >
        <div
          style={{ borderRightColor: "#eee", height }}
          className="tw-w-1/4  tw-border-0 tw-border-dashed tw-border-r"
        >
          <img
            src={
              Configuration.staticDomain + item.images[0]?.path + "?mode=crop&width=100&height=100"
            }
            className="w-100 h-100 object-cover"
            alt="img"
          />
        </div>
        <div className="tw-w-3/4 h-100">
          <div className="p-5 d-flex align-items-center justify-content-between">
            <div className="">
              <Typography variant="h6">{item.name}</Typography>

              <RadioGroup value={status} onChange={(e) => setStatus(+e.target.value)} row>
                {listTest.map((data) => (
                  <FormControlLabel value={data.value} label={data.name} control={<Radio />} />
                ))}
              </RadioGroup>
              {status === PromotionType.setCalendar && (
                <div className="d-flex align-items-center">
                  Áp dụng trước ngày check-in{" "}
                  <input
                    type="number"
                    min={1}
                    className="w-50px h-50px px-3 mx-3 form-control"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  />{" "}
                  ngày
                </div>
              )}
            </div>
            <Checkbox
              onChange={(e) => {
                const temp: any = { ...selected };
                if (e.target.checked) {
                  temp[item.id] = {
                    ApplyBeforeCheckIn: +day,
                    PromotionId: item.id,
                    PluginDatas: item.pluginDatas[0],
                    Name: item.name,
                  };
                } else {
                  temp[item.id] = false;
                }
                setSelected(temp);
              }}
              checked={selected[item.id]}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const VoucherList = ({ onChange, value, isVisible, setIsVisible, onSubmit }: Props) => {
  const { result } = useQueryListPromotion({ type: 16 });
  const [selected, setSelected] = useSetState<any>({});
  const arraySelected = useMemo(() => {
    return _.map(selected, (v) => v).filter((v) => !!v);
  }, [selected]);
  const items = useMemo(() => {
    return result.data?.promotions?.items || [];
  }, [result]);
  useEffect(() => {
    if (value?.length > 0) {
      const temp: any = [...value];
      const tmpSelected = temp.reduce((acc, value) => {
        const temp = { ...acc };
        temp[value.PromotionId] = { ...value };
        return temp;
      }, {});

      setSelected(tmpSelected);
    }
  }, []);

  const renderModal = () => (
    <Modal
      show={isVisible}
      onHide={() => setIsVisible(false)}
      dialogClassName="modal-dialog tw-max-w-3xl modal-dialog-centered"
    >
      <Modal.Header className="d-flex justify-content-between">
        <h5>Chọn chương trình giảm giá</h5>
        <i className="bi bi-x fs-1 cursor-pointer" onClick={() => setIsVisible(false)}></i>
      </Modal.Header>
      <Modal.Body className="h-550px tw-overflow-y-auto">
        {_.map(items, (item: any) => {
          return <VoucherItem selected={selected} setSelected={setSelected} item={item} />;
        })}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light">
          Đóng
        </button>
        <button
          type="button"
          className="btn btn-primary flex items-center"
          onClick={() => {
            setIsVisible(false);
            onSubmit(
              arraySelected.map(({ PromotionId, ApplyBeforeCheckIn }) => ({
                PromotionId,
                ApplyBeforeCheckIn,
              }))
            );

            onChange(arraySelected);
            setIsVisible(false);
          }}
        >
          Áp dụng
        </button>
      </Modal.Footer>
    </Modal>
  );

  if (result.fetching) return <div>loading...</div>;
  return <div className="">{renderModal()}</div>;
};

export default VoucherList;
