import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../partials/table/Table";
import { getPointTransactions } from "../../../services/TransactionService";
import Common from "../../../utils/Common";
import { t } from "../../../utils/utils";
import NavbarProfile from "../components/NavbarProfile";

const PointTransaction = () => {
  return (
    <Table
      dataSource={getPointTransactions}
      columns={[
        {
          dataIndex: "orderCode",
          title: "Transaction Code",
          render(v, row) {
            return (
              <Link
                className="text-gray-800 text-hover-primary fw-bolder"
                to={`/order/detail/${row.orderId}`}
              >
                {v}
              </Link>
            );
          },
          //onSort: this.handleSort
        },
        {
          dataIndex: "targetName",
          title: "Name",
          className: "max-w-600px w-600px",
          //onSort: this.handleSort
        },

        {
          dataIndex: "point",
          title: "Point",
          className: "text-center",
          render(v) {
            return v < 0 ? (
              <span className="text-danger">{v}</span>
            ) : (
              <span className="text-success">{v}</span>
            );
          },
          //onSort: this.handleSort
        },
        {
          dataIndex: "createdDate",
          title: "Created Date",
          className: "text-center",
          render(v) {
            return Common.formatDateTime(v, "dd-mm-yyyy");
          },
        },

        //onSort: this.handleSort
      ]}
    />
  );
};

export default PointTransaction;
