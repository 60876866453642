import { NOTIFICATION } from '../configs/constants/NotificaionConstant';
import {
  getNotification,
  readNotification
} from '../services/NotificationService';

export const getNotificationAction = (limt, offset) => ({
  type: NOTIFICATION.GET_NOTIFICATION,
  payload: getNotification( limt, offset)
});

export const readNotificationAction = (notificationDTO) => ({
  type: NOTIFICATION.READ_NOTIFICATION,
  payload: readNotification(notificationDTO)
});






