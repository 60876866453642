import React, { useState } from "react";
import Table from "../../partials/table/Table";
import Common from "../../utils/Common";
import { range } from "lodash";
import { useSetState } from "react-use";
import { t } from "../../utils/utils";

import { useHistory } from "react-router-dom";
import { getRentVacationProduct } from "../../services/ProductService";

type Props = {};

export function convertStatus(allStatus: number) {
  let status = "";
  let className = "badge-light";
  if (allStatus === 1) {
    status = "Đã phát hành";
    className = "badge-primary";
  }
  if (allStatus === 2) {
    status = "Chưa phát hành";
    className = "badge-warning";
  }
  if (allStatus === 4) {
    status = "Quá hạn xử lý";
    className = "badge-dark";
  }

  return { status, className };
}

const ProductRental = (props: Props) => {
  const history = useHistory();

  return (
    <>
      <Table
        toolbarRender={[
          {
            component: (
              <span
                onClick={() => history.push("/rent-vacation/create")}
                className="btn btn-primary"
              >
                {t("Thêm phòng cho thuê")}
              </span>
            ),
          },
        ]}
        dataSource={getRentVacationProduct}
        columns={[
          {
            dataIndex: "addressName",
            title: "Address",
          },
          {
            dataIndex: "name",
            title: "Room Class",
          },
          {
            title: "Checkin - Checkout",
            className: "text-center",
            render: (cell, row) => (
              <span>
                {Common.formatDateTime(new Date(row.productVacationRent?.fromDate), "dd/mm/yyyy")} -{" "}
                {Common.formatDateTime(new Date(row.productVacationRent?.toDate), "dd/mm/yyyy")}
              </span>
            ),
          },

          /* {
            dataIndex: "price",
            title: "Price",
            render(value, row, index) {
              return <span>{Common.formatMoney(value)}</span>;
            },
          }, */
          {
            dataIndex: "price",
            title: "Price",
            render(value, row, index) {
              return <span>{Common.formatMoney(value)}</span>;
            },
          },
          {
            dataIndex: "status",
            className: "text-center",
            title: "Status",
            render(value, row, index) {
              return (
                <span className={`badge ${convertStatus(value).className}`}>
                  {convertStatus(value).status}
                </span>
              );
            },
          },
          {
            dataIndex: "verifyStatus",
            title: "Xác minh",
            className: "text-center",
            render(v, row, index) {
              return v ? (
                <i className="bi bi-check-circle text-success"></i>
              ) : (
                <i className="bi bi-x-circle text-danger"></i>
              );
            },
          },
        ]}
        actions={[
          {
            name: "View",
            type: "navigation",
            render(item) {
              return `/rent-vacation/detail/${item.id}`;
            },
          },
        ]}
      />
    </>
  );
};

export default ProductRental;
