import { actionTypes } from "../reducers/auth";


export const setAuthSuccessAction = () => ({
  type: actionTypes.AuthSuccess

});




