// import Configuration from "../../../../configs/Config";
// import { t } from "../../../../utils/utils";
import moment from "moment";

export function caculationDiscounts(discounts:any, price:number) {
  let discountResults = {
    price: price,
    oldPrice: 0,
    percent: 0,
    cash: 0
  };
  if (discounts == undefined || (discounts!=null && discounts.length == 0) ) {
    return discountResults;
  } else {
    var now = moment();
    var tempPrice = price;
    var percent = 0;
    var cash = 0;
    if (discounts.length > 0) {
      discounts.map((val) => {
        if (moment(val.from) <= now && moment(val.to) >= now) {
          if (val.resultType == 0) {
            tempPrice = tempPrice - (tempPrice * val.resultValue) / 100;
            percent += parseInt(val.resultValue);
          } else {
            tempPrice = tempPrice - val.resultValue;
            cash += parseInt(val.resultValue);
            percent += ((price - tempPrice)/price)*100;
          }
        }
        if (price != tempPrice) {          
          discountResults = {
            price: tempPrice,
            oldPrice: price,                
            percent: percent,
            cash: cash,                
          }
        }
      });
    }    
    return discountResults;      
  }
}
