import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
// import SubHeader from "./sub-header/SubHeader";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import MenuAsideApp from "./aside/MenuAsideApp";
import "./assets/Base.scss";
import { ToastContainer } from "react-toastify";
import Toolbar from "./toolbar";

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  Merchant
}) {
  
  htmlClassService.setConfig({...layoutConfig,izziMember: Merchant.merchant?.izziMember});
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const contentContainerCssClasses = htmlClassService.classes.content_container.join(
    " "
  );
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
    >
      {/* <!-- begin:: Header Mobile --> */}

      {/* <!-- end:: Header Mobile --> */}

      {/* <!-- begin::Body --> */}

      <div className="d-flex flex-column flex-root h-100">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />

            <Toolbar />

            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Aside Left --> */}
            {asideDisplay && (
              <>
                <div
                  className={`kt-container ${contentContainerCssClasses} kt-container--fit kt-grid kt-grid--ver`}
                >
                  <AsideLeft />
                  <div
                    className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                    id="kt_content"
                  >
                    <KtContent>{children}</KtContent>
                  </div>
                </div>
              </>
            )}
            {!asideDisplay && (
              <>
                <div
                  id="kt_content_container"
                  className="d-flex flex-column-fluid align-items-start container-xxl"
                >
                  <div className="content flex-row-fluid" id="kt_content">
                    {children}
                  </div>
                </div>
              </>
            )}

            {/* <!-- end:: Aside Left --> */}

            <Footer />
          </div>
        </div>
      </div>

      {/* <!-- end:: Body --> */}

      {/* <QuickPanel />
      <ScrollTop />
      <StickyToolbar /> */}
      <ToastContainer />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig },Merchant }) => ({
  Merchant,
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
});

export default withRouter(connect(mapStateToProps)(Layout));
