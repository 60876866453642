/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../reducers/builder";
import Config from '../../configs/Config';
import {getMerchantLogo} from '../../utils/MerchantUtils';

class Brand extends React.Component {

  componentDidMount() {
    // eslint-disable-next-line no-undef
    console.log("Brand - componentDidMount");
  }

  render() {
    const { merchant } = this.props;
    // console.log(merchant, "merchant")
    const logoPath = merchant ? getMerchantLogo(merchant.images) : '';
    // console.log("Brand - render");
    return (
      <div
        className={`kt-header__brand ${this.props.brandClasses} kt-grid__item`}
        id="kt_header_brand"
      >
        <Link to="" className="kt-header__brand-logo">
          <img height="45" width="59"
            alt="logo"
            src={logoPath ? Config.staticDomain + logoPath : toAbsoluteUrl("/media/logos/logo-4.png")}
            className="kt-header__brand-logo-default"
          />
          <img
            height="35" width="47"
            alt="logo"
            src={
              logoPath ? Config.staticDomain + logoPath : toAbsoluteUrl("/media/logos/logo-4-sm.png")}
            className="kt-header__brand-logo-sticky"
          />
        </Link>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    }),
    merchant: store.Merchant.merchant,

  };
};

export default connect(mapStateToProps)(Brand);
