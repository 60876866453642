import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../../services/UserService";
import Config from "../../configs/Config";
import { connect } from "react-redux";
import { getUserInfoAction, getTotalPointAction } from "../../actions/User";
import { getMerchantDetailAction } from "../../actions/Merchant";
import Swal from "sweetalert2";
import Spinner from "../../layout/assets/icon/spinner/Spinner";
import { Link } from "react-router-dom";

const Login = ({ getUserInfoAction, getTotalPointAction, getMerchantDetailAction }) => {
  const [isLoading, setLoading] = useState(false);
  const refMerchantCode = useRef();
  useEffect(() => {
    let merchantCode = window.location.host.startsWith("localhost")
      ? Config.merchantCode
      : window.location.host.split(".")[0];
    refMerchantCode.current = merchantCode;
  }, []);
  const { register, handleSubmit, errors } = useForm();

  const getUserData = async token => {
    localStorage.setItem(Config.tokenId, token);
    await getUserInfoAction(token);
    await getTotalPointAction(token);
    await getMerchantDetailAction();
  };

  const onSubmit = ({ email, password }) => {
    let objData = {
      UserName: email,
      Password: password,
      MerchantCode: refMerchantCode.current
    };
    setLoading(true);
    login(objData).then(res => {
      setLoading(false);
      const { Success, Data } = res.data;
      if (Success) {
        const { tokenId } = Data;
        getUserData(tokenId);
      } else {
        Swal.fire("Sai tên hoặc mật khẩu! Mời bạn đăng nhập lại.");
      }
    });
  };

  const siginButton = useMemo(() => {
    return isLoading ? <Spinner /> : <>Sign In</>;
  }, [isLoading]);

  return (
    <div className="kt-login__form">
      <div className="kt-login__title">
        <h3>Sign In</h3>
      </div>

      <form className="kt-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            className={clsx("form-control", errors.email && "error")}
            type="text"
            placeholder="Email"
            name="email"
            ref={register({ required: "Email is required." })}
            autoComplete="off"
          />
          {errors.email && (
            <span className="error-message">{errors.email.message}</span>
          )}
        </div>
        <div className="form-group">
          <input
            className={clsx("form-control", errors.password && "error")}
            type="password"
            placeholder="Password"
            ref={register({ required: "Password is required." })}
            name="password"
          />
          {errors.password && (
            <span className="error-message">{errors.password.message}</span>
          )}
        </div>

        <div className="kt-login__actions">
          <Link
            to="/auth/forgot-password"
            className="kt-link kt-login__link-forgot"
          >
            Forgot Password ?
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary btn-elevate kt-login__btn-primary"
          >
            {siginButton}
          </button>
        </div>
      </form>
    </div>
  );
};
const mapStateToProp = state => ({
  userInfo: state.User.userInfo
});
const mapDispatchToProps = {
  getUserInfoAction,
  getTotalPointAction,
  getMerchantDetailAction
};

export default connect(mapStateToProp, mapDispatchToProps)(Login);
