import { combineReducers } from 'redux';
import { reducer as i18nReducer } from "./i18n";
import { reducer as builderReducer } from "./builder";
import { reducer as authReducer } from "./auth";
import { reducer as User } from "./User";
import { reducer as Order } from "./Order";
import { reducer as Notification } from "./Notification";
import { reducer as Merchant } from "./Merchant";
import { reducer as FcmMessageToken } from "./FcmMessageToken";
import { reducer as Affiliate } from "./Affiliate";
import { reducer as Address } from "./Address";
import { reducer as Product } from "./Product";

const rootReducer = combineReducers({
  auth: authReducer,
  i18n: i18nReducer,
  builder: builderReducer,
  User, 
  Order,
  Notification,
  Merchant,
  FcmMessageToken,
  Affiliate,
  Address,
  Product
});

export default rootReducer
