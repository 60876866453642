import React from "react";
import { Link, useLocation } from "react-router-dom";
import { t } from "../../utils/utils";
import { initLayoutConfig } from "../LayoutConfig";

import "./style.scss";

type Props = {};

const Toolbar = (props: Props) => {
  const location = useLocation();
  const currentRouteName = location.pathname.split("/")[1];
  const currentSubBreadCrumbs =
    initLayoutConfig.breadCrumbConvert[currentRouteName]?.items ;
  const arrRouteName =
    location.pathname !== "/" && location.pathname.split("/");

  return (
    <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
      <div
        id="kt_toolbar_container"
        className="container-xxl d-flex flex-stack flex-wrap"
      >
        <div className="page-title d-flex flex-column me-3">
          {/*begin::Title*/}
          <h1 className="d-flex text-white fw-bolder my-1 fs-3 first-letter-capitalize">
            {currentRouteName && t(initLayoutConfig.breadCrumbConvert[currentRouteName]?.title ||"N / A")}
          </h1>
          {/*end::Title*/}
          {/*begin::Breadcrumb*/}
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
            {/*begin::Item*/}
            <li className="breadcrumb-item text-white opacity-75">
              <Link to="/" className="text-white text-hover-primary">
                {t("Home")}
              </Link>
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}

            <li className="breadcrumb-item">
              <span className="bullet bg-white opacity-75 w-5px h-2px" />
            </li>

            {currentSubBreadCrumbs && currentSubBreadCrumbs.length > 0 ? (
              currentSubBreadCrumbs.map((item, index) => {
                if (location.pathname.includes(currentRouteName + item.path)) {
                  return (
                    <>
                      <li className="breadcrumb-item text-white opacity-75 first-letter-capitalize">
                        {t(item.title || "N / A") }
                      </li>
                      {item.bullet && (
                        <li className="breadcrumb-item">
                          <span className="bullet bg-white opacity-75 w-5px h-2px" />
                        </li>
                      )}
                    </>
                  );
                }
              })
            ) : (
              <li className="breadcrumb-item text-white opacity-75 first-letter-capitalize">
                {t(currentRouteName || "N / A")}
              </li>
            )}
          </ul>
          {/*end::Breadcrumb*/}
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
