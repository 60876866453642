import { FCM } from '../configs/constants/FcmMessageTokenConstant';
import {
  registerDeviceTokenUser,
  removeDeviceTokenUser
} from '../services/FcmMessageTokenService';

export const registerDeviceTokenUserAction = (tokenDTO) => ({
  type: FCM.REGISTER_DEVICE_TOKEN,
  payload: registerDeviceTokenUser(tokenDTO)
});

export const removeDeviceTokenUserAction = (tokenDTO) => ({
  type: FCM.REMOVE_DEVICE_TOKEN,
  payload: removeDeviceTokenUser(tokenDTO)
});






