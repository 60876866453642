import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfoAction } from "../../../actions/User";
import Config from "../../../configs/Config";
import { IUser } from "../../../types/interface";
import Common from "../../../utils/Common";
import { t, toAbsoluteUrl } from "../../../utils/utils";
import NavbarProfile from "../components/NavbarProfile";

interface IProps {
  userInfo: IUser;
  setCurrentTab: any
}

const OverView = (props: IProps) => {
  const {
    userName,
    name,
    email,
    mobile,
    dateOfBirth,
  } = props.userInfo;

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      {/*begin::Card header*/}
      <div className="card-header cursor-pointer">
        {/*begin::Card title*/}
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">{t("Profile Details")}</h3>
        </div>
        {/*end::Card title*/}
        {/*begin::Action*/}
        <span
         onClick={()=>props.setCurrentTab("setting")}
          className="btn btn-primary align-self-center"
        >
          {t("Edit Profile")}
        </span>
        {/*end::Action*/}
      </div>
      {/*begin::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-9">
        {/*begin::Row*/}
        <div className="row mb-7">
          {/*begin::Label*/}
          <label className="col-lg-4 fw-semibold text-muted">
            {t("Full name")}
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8">
            <span className="fw-bold fs-6 text-gray-800">{name}</span>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
        {/*begin::Input group*/}
        <div className="row mb-7">
          {/*begin::Label*/}
          <label className="col-lg-4 fw-semibold text-muted">Email</label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 fv-row">
            <span className="fw-bold text-gray-800 fs-6">{email}</span>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-7">
          {/*begin::Label*/}
          <label className="col-lg-4 fw-semibold text-muted">
            {t("Mobile")}
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">
              {mobile || "N/A"}
            </span>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row mb-7">
          {/*begin::Label*/}
          <label className="col-lg-4 fw-semibold text-muted">
            {t("Date of birth")}
          </label>
          {/*end::Label*/}
          {/*begin::Col*/}
          <div className="col-lg-8">
            <span className="fw-bold fs-6 text-gray-800 text-hover-primary">
              {Common.formatDateTime(dateOfBirth, "dd/mm/yyyy") || "N / A"}
            </span>
          </div>
          {/*end::Col*/}
        </div>
      </div>
      {/*end::Card body*/}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.User.userInfo,
  };
};
const mapDispatchToProps = {
  getUserInfoAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(OverView);
