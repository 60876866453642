import React from "react";
import { connect } from "react-redux";
import UserSchedule from "./UserSchedule";

const Schedule = ({ userInfo }) => {
  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
      <div className="row">
        <div className="col-xl-12">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Lịch cá nhân </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <UserSchedule userInfo={userInfo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.User.userInfo
  };
};
export default connect(mapStateToProps)(Schedule);
