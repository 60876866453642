import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Configuration from "../configs/Config";
import { getCurrentLanguageId, languageMapping } from "../utils/utils";

const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE",
};

const initialState = {
  lang: "vi-VN",
  langId: window.localStorage.getItem(Configuration.languageId) || "838aef56-78bb-11e6-b5a6-00155d582814"
};

export const reducer = persistReducer(
  { storage, key: "i18n" },
  (state = initialState, action) => {
    if(action.payload?.langId){
      localStorage.setItem(Configuration.languageId,action.payload.langId)
    }
    switch (action.type) {
      case actionTypes.SetLanguage:
        
        return {
          ...state,
          lang: action.payload.lang,
          langId: action.payload.langId
        };

      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: (lang,langId) => ({ type: actionTypes.SetLanguage, payload: { lang,langId } }),
};
