import Configuration from "../configs/Config";
import _ from "lodash";
import Common from "./Common";
import moment from "moment";

export function getCommonValues(
  data: Array<
    | "ModifiedBy"
    | "ModifiedDate"
    | "CreatedBy"
    | "CreatedDate"
    | "TokenUser"
    | "MerchantId"
    | "LanguageId"
    | "ActionBy"
  >,
  camelCase?: boolean
) {
  const defaultValues: any = {};
  type TDefaultValues = {
    MerchantId: string;
    ModifiedBy: string;
    ModifiedDate: string;
    CreatedBy: string;
    CreatedDate: string;
    Id?: string;
    LanguageId: string;
    ActionBy: string;
  };

  if (Array.isArray(data) && data.length > 0) {
    _.forEach(data, (key) => {
      if (["ModifiedBy", "CreatedBy", "ActionBy"].includes(key)) {
        defaultValues[key as keyof TDefaultValues] =
          localStorage.getItem(Configuration.userId) || Common.guid();
      } else if (["ModifiedDate", "CreatedDate"].includes(key)) {
        defaultValues[key as keyof TDefaultValues] = Common.formatDateTime(new Date());
      } else if (["MerchantId"].includes(key)) {
        defaultValues[key as keyof TDefaultValues] = localStorage.getItem(Configuration.merchantId);
      } else if (["LanguageId"].includes(key)) {
        defaultValues[key as keyof TDefaultValues] = localStorage.getItem(Configuration.languageId);
      } else if (["TokenUser"].includes(key)) {
        defaultValues[key as keyof TDefaultValues] = localStorage.getItem(Configuration.tokenId);
      }
    });
  }

  if (camelCase) {
    const newObject = {};
    _.forEach(defaultValues, (v, k) => {
      const k0 = k.split("");
      k0[0] = k0[0].toLowerCase();
      newObject[k0.join("")] = v;
    });
    return newObject;
  }

  return defaultValues;
}
export const upperCaseFirstLetter = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1);
};
export const stringBooleanToRealBoolean = (value: string) => {
  if (value === "false") {
    return false;
  } else if (value === "true") {
    return true;
  } else {
    return false;
  }
};
export const arrayMoveMutate = (array: Array<any>, from: number, to: number): any => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;
    const [item] = array.splice(from, 1);

    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array: Array<any>, from: number, to: number): Array<any> => {
  arrayMoveMutate(array, from, to);

  return array;
};
export const formatDDMMYYYYToMMDDYYYY = (date?: any) => {
  let temp = date;
  if (typeof temp === "string") {
    return arrayMove(temp.split("/"), 0, 1)?.join("/");
  }
};
export function formatDateTime(dateTime?: any, format?: string): string {
  if (dateTime === "0001-01-01T00:00:00") return "N/A";
  return moment(dateTime || new Date()).format(format || "DD-MM-YYYY");
}
