import Configuration from "../../../configs/Config";
import { sendCommandAndAffect } from "../../../utils/command";

export type TUser = {
  Id: string;
  Name: string;
  Email: string;
  Mobile: string;
  AllType: { id: number; name?: string };
};
export type PromotionVacationRent = {
  PromotionId: string;
  ApplyBeforCheckIn?: number;
};

export interface RentProduct extends IServiceCommon {
  AddressId: string;
  Adult: number;
  Children: number;
  FromAge: number;
  ToAge: number;
  BathRoom: number;
  BedRoom: number;
  DoubleBed: number;
  SingleBed: number;
  HasKitchen: boolean;
  IncludeBreakfast: boolean;
  FromDate: Date;
  ToDate: Date;
  Price: Number; //Giá cho thuê
  ContractCode: string; // Mã hợp đồng

  VerifyTerm: boolean; // Đồng ý điều khoản
  VerifyStatus: boolean;
  Images: RelationObjectArray[]; // Album,
  ContractRoomImageId: string;
  ContractInformationOwnerImageId: string;
  ContractVerifyImageId: string;
  PromotionVacationRents: PromotionVacationRent[];
}

export const createRentProduct = (values: RentProduct) => {
  return sendCommandAndAffect({
    domain: "Sale",
    name: "CreateVacationRentProduct",
    values,
  });
};
