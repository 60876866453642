import React, { useEffect, useState } from "react";
import Configuration from "../../configs/Config";
// import { getMerchantLogo } from "../../utils/MerchantUtils";
import { t, toAbsoluteUrl } from "../../utils/utils";
// import * as builder from "../../reducers/builder";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfoAction } from "../../actions/User";
import { actions } from "../../reducers/i18n";
import {
  getNotificationAction,
  readNotificationAction,
} from "../../actions/Notification";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/vi";
import Common from "../../utils/Common";
import {
  getMembershipMenuNavigations,
  // getNavigation,
} from "../../services/Navigation";

const Header = (props) => {
  const { merchant, stateFull, notifications, getNotificationAction } = props;
  const { avatar, name, email, id, firstName, lastName } = props.userInfo;
  const dispatch = useDispatch();
  // console.log(merchant, "merchant")
  const logoDark = JSON.parse(window.merchant?.data?.configuration || "")["DarkLogoPath"];  
  const logoLight = JSON.parse(window.merchant?.data?.configuration || "")["LightLogoPath"];
  // console.log(logoLight, "logo light")
  // const logoPath = merchant ? getMerchantLogo(merchant.images) : "";
  const [currentLangInfo, setCurrentLangInfo] = useState();
  const [menu, setMenu] = useState([]);
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const onMessage = () => {
      getNotificationAction(50, 0);
    };
    navigator.serviceWorker.addEventListener(
      "message",
      (event) =>
        event.data["firebase-messaging-msg-data"] &&
        onMessage(event.data["firebase-messaging-msg-data"])
    );
    getNotificationAction(50, 0);

    if (window.config.data.languages) {
      setLanguages(window.config.data.languages);

      setCurrentLangInfo(
        window.config.data.languages.find(
          (lang) => lang.code === stateFull.i18n.lang
        )
      );
    }

    getMembershipMenuNavigations({ languageId: stateFull.i18n.langId }).then(
      (result) => {
        if (result.membershipmenunavigations?.items?.length > 0) {
          setMenu(
            JSON.parse(result.membershipmenunavigations.items[0].navigation)
          );
        }
      }
    );
    return () => {
      navigator.serviceWorker.removeEventListener("message", onMessage);
    };
  }, [stateFull.i18n]);

  return (
    <div
      id="kt_header"
      className="header align-items-stretch"
      data-kt-sticky="true"
      data-kt-sticky-name="header"
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
    >
      {/*begin::Container*/}
      <div className="container-xxl d-flex align-items-center">
        {/*begin::Heaeder menu toggle*/}
        <div
          className="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
            <span className="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
        </div>
        {/*end::Heaeder menu toggle*/}
        {/*begin::Header Logo*/}
        <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
          <Link to="/">
            <img
              height="45"
              
              alt="logo"
              src={
                (logoLight!= undefined && logoLight != "")? logoLight + "?mode=crop&height=45"
                  : Configuration.logoDefaultWhite
              }
              className="logo-default"
            />
            <img
              height="45"
              
              alt="logo"
              src={
                (logoDark!= undefined && logoDark != "")? logoDark + "?mode=crop&height=45"
                  : Configuration.logoDefault
              }
              className="logo-sticky"
            />
          </Link>
        </div>
        {/*end::Header Logo*/}
        {/*begin::Wrapper*/}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {/*begin::Navbar*/}
          <div className="d-flex align-items-stretch" id="kt_header_nav">
            {/*begin::Menu wrapper*/}
            <div
              className="header-menu align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
            >
              {/*begin::Menu*/}
              <div
                className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                id="#kt_header_menu"
                data-kt-menu="true"
              >
                {menu.length > 0 &&
                  menu.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`${item.Description}`}
                        className="menu-item menu-lg-down-accordion me-lg-1"
                      >
                        <span className="menu-link py-3">
                          <span className="menu-title">{t(item.Name)}</span>
                          <span className="menu-arrow d-lg-none" />
                        </span>
                      </Link>
                    );
                  })}
              </div>
              {/*end::Menu*/}
            </div>
            {/*end::Menu wrapper*/}
          </div>
          {/*end::Navbar*/}
          {/*begin::Toolbar wrapper*/}
          <div className="topbar d-flex align-items-stretch flex-shrink-0">
            {/*begin::Search*/}

            {/*end::Activities*/}
            {/*begin::Notifications*/}
            <div className="d-flex align-items-center ms-1 ms-lg-3">
              {/*begin::Menu- wrapper*/}
              <div
                className="btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen022.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                      fill="white"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*begin::Menu*/}
              <div
                className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                data-kt-menu="true"
              >
                {/*begin::Heading*/}
                <div
                  className="d-flex flex-column bgi-no-repeat rounded-top"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/misc/bg-1.jpg"
                    )})`,
                  }}
                >
                  {/*begin::Title*/}
                  <h3 className="text-white text-center fw-bold px-9 mt-10 mb-6">
                    {t("Notification")}
                  </h3>
                  {/*end::Title*/}
                  {/*begin::Tabs*/}
                  <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
                    <li className="nav-item">
                      <a
                        className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                        data-bs-toggle="tab"
                        href="#kt_topbar_notifications_1"
                      >
                        {t("Message")}
                      </a>
                    </li>
                  </ul>
                  {/*end::Tabs*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Tab content*/}
                <div className="tab-content">
                  {/*begin::Tab panel*/}
                  <div
                    className="tab-pane fade active show"
                    id="kt_topbar_notifications_1"
                    role="tabpanel"
                  >
                    {/*begin::Items*/}

                    {/*end::Items*/}
                    {/*begin::View more*/}

                    {/*begin::Item*/}
                    {notifications && notifications.items?.length > 0 && (
                      <div className="scroll-y mh-325px my-5 px-8">
                        {notifications.items.map((item) => {
                          return (
                            <div
                              key={item.id}
                              onClick={async () => {
                                const objData = {
                                  Id: item.id,
                                  ModifiedDate: Common.formatDateTime(
                                    new Date()
                                  ),
                                  ModifiedBy: localStorage.getItem(
                                    Configuration.userId
                                  ),
                                };
                                await props.readNotificationAction(objData);
                                if (item.urlReturn) {
                                  if (item.urlReturn.startsWith("http")) {
                                    window
                                      .open(item.urlReturn, "_blank")
                                      .focus();
                                  }
                                }
                              }}
                              className="d-flex flex-stack py-4 cursor-pointer"
                            >
                              {/*begin::Section*/}
                              <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}

                                {/*end::Symbol*/}
                                {/*begin::Title*/}
                                <div className="mb-0 me-2">
                                  <div
                                    style={{
                                      width: "200px",
                                      wordWrap: "break-word",
                                    }}
                                    className="text-gray-400 fs-7 text-hover-primary"
                                  >
                                    {item.message}
                                  </div>
                                </div>
                                {/*end::Title*/}
                              </div>
                              {/*end::Section*/}
                              {/*begin::Label*/}
                              <span className="badge badge-light fs-8">
                                <Moment locale="vi" fromNow>
                                  {item.createdDate}
                                </Moment>
                              </span>
                              {/*end::Label*/}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {/*end::Item*/}
                    {/*begin::Item*/}

                    {/*end::Item*/}

                    {!notifications ||
                      (notifications && notifications.length === 0 && (
                        <div className="py-3 text-center border-top">
                          <Link
                            to="/"
                            className="btn btn-color-gray-600 btn-active-color-primary"
                          >
                            Chưa có thông báo mới
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                            {/*end::Svg Icon*/}
                          </Link>
                        </div>
                      ))}

                    {/*end::View more*/}
                  </div>
                  {/*end::Tab panel*/}
                  {/*begin::Tab panel*/}
                  <div
                    className="tab-pane fade"
                    id="kt_topbar_notifications_2"
                    role="tabpanel"
                  >
                    {/*begin::Wrapper*/}

                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Tab panel*/}
                  {/*begin::Tab panel*/}
                  <div
                    className="tab-pane fade"
                    id="kt_topbar_notifications_3"
                    role="tabpanel"
                  >
                    {/*begin::Items*/}

                    {/*end::Items*/}
                    {/*begin::View more*/}

                    {/*end::View more*/}
                  </div>
                  {/*end::Tab panel*/}
                </div>
                {/*end::Tab content*/}
              </div>
              {/*end::Menu*/}
              {/*end::Menu wrapper*/}
            </div>
            {/*end::Notifications*/}
            {/*begin::Chat*/}

            {/*end::Chat*/}
            {/*begin::Quick links*/}

            {/*end::Theme mode*/}
            {/*begin::User*/}
            <div
              className="d-flex align-items-center me-n3 ms-1 ms-lg-3"
              id="kt_header_user_menu_toggle"
            >
              {/*begin::Menu wrapper*/}
              <div
                className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <img
                  className="h-30px w-30px rounded"
                  src={avatar? Configuration.staticDomain + avatar: Configuration.imageAvatarDefault}
                  alt=""
                />
              </div>
              {/*begin::User account menu*/}

              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                data-kt-menu="true"
              >
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-50px me-5">
                      <img
                        alt="Logo"
                        src={avatar? Configuration.staticDomain + avatar: Configuration.imageAvatarDefault}
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Username*/}
                    <div className="d-flex flex-column">
                      <div className="fw-bolder d-flex align-items-center text-break fs-5">
                        {firstName + " " + lastName}
                      </div>
                      <a
                        href="#"
                        className="fw-bold text-muted text-hover-primary fs-7 text-break"
                      >
                        {email}
                      </a>
                    </div>
                    {/*end::Username*/}
                  </div>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                <div className="separator my-2" />
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}
                <div
                  className="menu-item px-5"
                  data-kt-menu-trigger="hover"
                  data-kt-menu-placement="left-start"
                >
                  <Link to="/profile" className="menu-link px-5">
                    {t("User information")}
                  </Link>
                  {/*begin::Menu sub*/}

                  {/*end::Menu sub*/}
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}

                {/*end::Menu item*/}
                {/*begin::Menu item*/}

                {/*end::Menu item*/}
                {/*begin::Menu item*/}

                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                <div className="separator my-2" />
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}
                <div
                  className="menu-item px-5"
                  data-kt-menu-trigger="hover"
                  data-kt-menu-placement="left-start"
                >
                  <a href="#" className="menu-link px-5">
                    <span className="menu-title position-relative">
                      {t("Language")}
                      <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                        {currentLangInfo?.name}
                        <img
                          className="w-15px h-15px rounded-1 ms-2"
                          src={currentLangInfo?.imagePath}
                          alt=""
                        />
                      </span>
                    </span>
                  </a>
                  {/*begin::Menu sub*/}
                  <div className="menu-sub menu-sub-dropdown w-175px py-4">
                    {/*begin::Menu item*/}
                    {languages.length > 0 &&
                      languages.map((lang) => {
                        return (
                          <div
                            key={lang.id}
                            onClick={() =>
                              dispatch(actions.setLanguage(lang.code, lang.id))
                            }
                            className="menu-item px-3"
                          >
                            <span
                              className={`menu-link d-flex px-5 ${stateFull.i18n
                                .lang === lang.lang && "active"}`}
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src={lang.imagePath}
                                  alt=""
                                />
                              </span>
                              {lang.name}
                            </span>
                          </div>
                        );
                      })}

                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu sub*/}
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}

                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-5">
                  <Link to="/logout" className="menu-link px-5">
                    {t("Logout")}
                  </Link>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                <div className="separator my-2" />
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}

                {/*end::Menu item*/}
              </div>

              {/*end::User account menu*/}
              {/*end::Menu wrapper*/}
            </div>
            {/*end::User */}
            {/*begin::Aside mobile toggle*/}
            {/*end::Aside mobile toggle*/}
          </div>
          {/*end::Toolbar wrapper*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Container*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stateFull: state,
    menuConfig: state.builder.menuConfig,
    userInfo: state.User.userInfo,
    totalPoint: state.User.totalPoint,
    userInfoMember: state.User.userInfoMember,
    merchant: state.Merchant.merchant,
    userInfo: state.User.userInfo,
    notifications: state.Notification.notifications,
  };
};
const mapDispatchToProps = {
  getUserInfoAction,
  getNotificationAction,
  readNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
