import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetState } from "react-use";
import Configuration from "../../configs/Config";
import { getDeposit } from "../../services/OrderService";
import Common from "../../utils/Common";
import { GoongGeocode } from "../../utils/Gonggeocode";
import { t } from "../../utils/utils";
import { depositStatus } from "./Functions";

const DepositDetail = (props) => {
  const history = useHistory();
  const { id } = useParams();
  //console.log(props.location.state, "4");
  const [deposit, setDeposit] = useState({});

  const {
    status,
    createdDate,
    owner,
    fromDate,
    toDate,
    addressName,
    detailAddress,
  
    images,
    standardOccupancy,
    productName,
    note,
    ownerMobile,
    ownerEmail,
    referenceCode,
  } = deposit;
  //console.log(deposit, "1555");

  useEffect(() => {
    getDeposit(id).then((res) => setDeposit({ ...res.depositorder }));
  }, []);

  const depositImageLabel = [
    "Thông tin khu nghỉ trên hợp đồng",
    "Thông tin chủ sở hữu trên hợp đồng",
    "Chữ ký và Dấu mộc của chủ sở hữu và khu nghỉ trên hợp đồng",
  ];
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
        {/*begin:::Tabs*/}
        <ul
          className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto"
          role="tablist"
        >
          {/*begin:::Tab item*/}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link text-active-primary pb-4 active"
              data-bs-toggle="tab"
              href="#kt_ecommerce_sales_order_summary"
              aria-selected="true"
              role="tab"
            >
              {t("Detail Deposit")}
            </a>
          </li>
          {/*  <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-bs-toggle="tab"
              href="#kt_ecommerce_sales_order_history"
              aria-selected="false"
              tabindex="-1"
              role="tab"
            >
              {t("Deposit History")}
            </a>
          </li> */}
          {/*end:::Tab item*/}
          {/*begin:::Tab item*/}

          {/*end:::Tab item*/}
        </ul>
        {/*end:::Tabs*/}
        {/*begin::Button*/}
        <span
          onClick={() => history.goBack()}
          className="btn btn-icon btn-light btn-sm ms-auto"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr074.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        {/*end::Button*/}
        {/*begin::Button*/}

        {/*end::Button*/}
      </div>
      {/*begin::Order summary*/}
      <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
        {/*begin::Order details*/}

        {/*end::Order details*/}
        {/*begin::Customer details*/}
        <div className="card card-flush py-4 flex-row-fluid w-xl-25">
          {/*begin::Card header*/}
          <div className="card-header">
            <div className="card-title">
              <h2>{t("Detail Address")}</h2>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                {/*begin::Table body*/}
                <tbody className="fw-semibold text-gray-600">
                  {/*begin::Customer name*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}

                        {/*end::Svg Icon*/}
                        {t("Address Name")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">
                        {/*begin:: Avatar */}

                        {/*end::Avatar*/}
                        {/*begin::Name*/}
                        <span className="text-gray-600 text-hover-primary">
                          {addressName}
                        </span>
                        {/*end::Name*/}
                      </div>
                    </td>
                  </tr>
                  {/*end::Customer name*/}
                  {/*begin::Customer email*/}

                  {/*end::Payment method*/}
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}

                        {/*end::Svg Icon*/}
                        {t("Detail Address")}
                      </div>
                    </td>
                    <td className="fw-bold text-end w-250px">
                      {detailAddress || "N / A"}
                    </td>
                  </tr>
                  {/*end::Date*/}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        <div className="card card-flush py-4 flex-row-fluid w-xl-25">
          {/*begin::Card header*/}
          <div className="card-header">
            <div className="card-title">
              <h2>{t("Room Type Information")}</h2>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                {/*begin::Table body*/}
                <tbody className="fw-semibold text-gray-600">
                  {/*begin::Customer name*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}

                        {t("Room class")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">
                        {/*begin:: Avatar */}

                        {/*end::Avatar*/}
                        {/*begin::Name*/}
                        <span className="text-gray-600 text-hover-primary w-225px">
                          {productName || "N / A"}
                        </span>
                        {/*end::Name*/}
                      </div>
                    </td>
                  </tr>
                  {/*end::Customer name*/}
                  {/*begin::Customer email*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}

                        {/*end::Svg Icon*/}
                        {t("Number of standard visited customer")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <span className="text-gray-600 text-hover-primary">
                        {standardOccupancy || "N / A"}
                      </span>
                    </td>
                  </tr>
                  {/*end::Payment method*/}
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}

                        {/*end::Svg Icon*/}
                        {t("Number of nights")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {moment(toDate).diff(moment(fromDate), "days")}
                    </td>
                  </tr>
                  {/*end::Date*/}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Customer details*/}
        {/*begin::Documents*/}

        <div className="card card-flush py-4 flex-row-fluid w-xl-25">
          {/*begin::Card header*/}
          <div className="card-header">
            <div className="card-title">
              <h2>{t("Date Timeshare")}</h2>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                {/*begin::Table body*/}
                <tbody className="fw-semibold text-gray-600">
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/files/fil002.svg*/}

                        {/*end::Svg Icon*/}
                        <div>{t("Created Date")}</div>
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {createdDate &&
                        Common.formatDateTime(createdDate, "DD-MM-YYYY")}
                    </td>
                  </tr>
                  {/*end::Date*/}
                  {/*begin::Payment method*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/finance/fin008.svg*/}

                        {/*end::Svg Icon*/}
                        {t("Status")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {status && (
                        <span
                          className={`badge badge-${
                            status ? depositStatus[status].color : ""
                          }`}
                        >
                          {depositStatus[status].name}
                        </span>
                      )}
                    </td>
                  </tr>
                  {/*end::Payment method*/}
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm006.svg*/}
                        {/*end::Svg Icon*/} {t("Check-in date")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {moment(fromDate).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm006.svg*/}
                        {/*end::Svg Icon*/} {t("Check-out date")}
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {moment(toDate).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                  {/*end::Date*/}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>

        {/*end::Documents*/}
      </div>
      {/*end::Order summary*/}
      {/*begin::Tab content*/}
      <div className="tab-content">
        {/*begin::Tab pane*/}
        <div
          className="tab-pane fade active show"
          id="kt_ecommerce_sales_order_summary"
          role="tab-panel"
        >
          {/*begin::Orders*/}
          <div className="d-flex flex-column gap-7 gap-lg-10">
            <div className="row g-10">
              {/*begin::Payment address*/}
              <div className="col-12 col-lg-6">
                <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                  {/*begin::Background*/}
                  <div className="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                    <img
                      src="/metronic8/demo2/assets/media/icons/duotune/ecommerce/ecm001.svg"
                      className="w-175px"
                    />
                  </div>
                  {/*end::Background*/}
                  {/*begin::Card header*/}
                  <div className="card-header">
                    <div className="card-title">
                      <h2>{t("Owner Information")}</h2>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body pt-0">
                    <p className="mb-3 d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 me-2">
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                            fill="currentColor"
                          />
                          <path
                            d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                            fill="currentColor"
                          />
                          <rect
                            x={7}
                            y={6}
                            width={4}
                            height={4}
                            rx={2}
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {owner}
                    </p>
                    <p className="mb-3 d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 me-2">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                            fill="currentColor"
                          />
                          <path
                            d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {ownerEmail}
                    </p>
                    <p className="mb-3 d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 me-2">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M19 4H5V20H19V4Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {ownerMobile}
                    </p>
                    <p className="mb-3 d-flex align-items-center">
                      <span className="me-2 fw-bolder">
                        {t("Contract Code")}
                      </span>
                      {referenceCode}
                    </p>
                  </div>
                  {/*end::Card body*/}
                </div>
              </div>
              {/*end::Payment address*/}
              {/*begin::Shipping address*/}
              <div className="col-12 col-lg-6">
                <div className="card card-flush py-4 flex-row-fluid overflow-hidden h-100">
                  {/*begin::Background*/}

                  {/*end::Background*/}
                  {/*begin::Card header*/}
                  <div className="card-header">
                    <div className="card-title">
                      <h2>{t("Note")}</h2>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body pt-0">{note}</div>
                  {/*end::Card body*/}
                </div>
              </div>
              {/*end::Shipping address*/}
            </div>
            {/*begin::Product List*/}
            <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>{t("Image of deposit information")}</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="row">
                  {images?.length > 0 ? (
                    images.map((image, index) => {
                      return (
                        <div className="col-lg-4 col-12 mb-10" key={image.id}>
                          <p className="fw-bolder h-25px">
                            {depositImageLabel[index]}
                          </p>
                          <img
                            className="w-100 mt-5"
                         
                            src={
                              Configuration.staticDomain +
                              image.path 
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 text-center">
                      Chưa có ảnh thông tin
                    </div>
                  )}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Product List*/}
          </div>
          {/*end::Orders*/}
        </div>
        {/*end::Tab pane*/}
        {/*begin::Tab pane*/}
        <div
          className="tab-pane fade"
          id="kt_ecommerce_sales_order_history"
          role="tab-panel"
        >
          {/*begin::Orders*/}
          <div className="d-flex flex-column gap-7 gap-lg-10">
            {/*begin::Order history*/}
            <div className="card card-flush py-4 flex-row-fluid">
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2> {t("History")}</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    {/*begin::Table head*/}
                    <thead>
                      <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-100px">Date Added</th>
                        <th className="min-w-175px">Comment</th>
                        <th className="min-w-70px">Order Status</th>
                        <th className="min-w-100px">Customer Notifed</th>
                      </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="fw-semibold text-gray-600">
                      <tr>
                        {/*begin::Date*/}
                        <td>23/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order completed</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-success">
                            Completed
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>22/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order received by customer</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-success">
                            Delivered
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>21/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order shipped from warehouse</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-primary">
                            Delivering
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>20/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment received</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-primary">
                            Processing
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>19/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Pending payment</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>18/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment method updated</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>17/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment method expired</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-danger">Failed</div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>16/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Pending payment</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>15/09/2022</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order received</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                    </tbody>
                    {/*end::Table head*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Order history*/}
            {/*begin::Order data*/}

            {/*end::Order data*/}
          </div>
          {/*end::Orders*/}
        </div>
        {/*end::Tab pane*/}
      </div>
      {/*end::Tab content*/}
    </div>
  );
};

export default DepositDetail;
