import { USER } from "../configs/constants/UserConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../routers/RouterHelpers";
import Config from "../configs/Config";
const initialState = {
  userInfo: {},
  userInfoMember: "",
};

export const reducer = persistReducer(
  {
    storage,
    key: "auth2",
    blacklist: [
      "updateUserInfoResult",
      "isLoading",
      "isUserAvatarLoading",
      "changePasswordResult",
      "updateUserAvatarResult",
    ],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(USER.GET_USER_INFO):
        return {
          ...state,
          updateUserInfoResult: "",
          updateUserAvatarResult: "",
          changePasswordResult: "",
        };

      case FAILURE(USER.GET_USER_INFO):
        return {
          ...state,
          updateUserInfoResult: "",
          updateUserAvatarResult: "",
          changePasswordResult: "",
        };

      case SUCCESS(USER.GET_USER_INFO):
        if (
          action.payload.userInformation &&
          action.payload.userInformation.item
        ) {
          localStorage.setItem(
            Config.merchantId,
            action.payload.userInformation.item.merchantId
          );
          localStorage.setItem(
            Config.userId,
            action.payload.userInformation.item.id
          );
          return {
            ...state,
            updateUserInfoResult: "",
            updateUserAvatarResult: "",
            changePasswordResult: "",
            userInfo: action.payload.userInformation.item,
          };
        } else {
          return {
            ...state,
            updateUserInfoResult: "",
            updateUserAvatarResult: "",
            changePasswordResult: "",
          };
        }
      case REQUEST(USER.UPDATE_USER_INFO):
        return {
          ...state,
          updateUserInfoMessage: "",
          updateUserAvatarResult: "",
          isLoading: true,
        };
      case FAILURE(USER.UPDATE_USER_INFO):
        //console.log("USER.GET_USER_INFO_FAILURE", action);
        return {
          ...state,
          updateUserInfoResult: "",
          updateUserAvatarResult: "",
          isLoading: false,
        };

      case SUCCESS(USER.UPDATE_USER_INFO):
        //console.log("USER.GET_USER_INFO", action);
        return {
          ...state,
          updateUserInfoResult: action.payload.Success
            ? "Cập nhật thành công"
            : action.payload.Message,
          updateUserAvatarResult: "",
          isLoading: false,
        };

      case REQUEST(USER.CHANGE_PASSWORD):
        return {
          ...state,
          changePasswordResult: "",
          updateUserAvatarResult: "",
          isLoading: true,
        };
      case FAILURE(USER.CHANGE_PASSWORD):
        return {
          ...state,
          changePasswordResult: "",
          updateUserAvatarResult: "",
          isLoading: false,
        };

      case SUCCESS(USER.CHANGE_PASSWORD):
        return {
          ...state,
          changePasswordResult: action.payload.Success
            ? "Cập nhật thành công"
            : action.payload.Message,
          updateUserAvatarResult: "",
          isLoading: false,
        };

      case REQUEST(USER.LOGOUT):
        return {
          ...state,
        };
      case FAILURE(USER.LOGOUT):
        return {
          ...state,
        };

      case SUCCESS(USER.LOGOUT):
        routerHelpers.forgotLastLocation();
        localStorage.removeItem(Config.tokenId);
        localStorage.removeItem(Config.languageId);
        localStorage.removeItem(Config.userId);
        localStorage.removeItem(Config.returnUrl);
        return {
          initialState,
        };

      case USER.CLEAR_USER_INFO_MESS:
        return {
          ...state,
          changePasswordResult: "",
          updateUserAvatarResult: "",
          updateUserInfoResult: "",
        };
      case REQUEST(USER.UPLOAD_AVATAR):
        return {
          ...state,
          isUserAvatarLoading: true,
          updateUserAvatarResult: "",
        };
      case FAILURE(USER.UPLOAD_AVATAR):
        return {
          ...state,
          isUserAvatarLoading: false,
          updateUserAvatarResult: action.payload.Success
            ? "Cập nhật avatar thành công"
            : action.payload.Message,
        };

      case SUCCESS(USER.UPLOAD_AVATAR):
        return {
          ...state,
          isUserAvatarLoading: false,
          updateUserAvatarResult: action.payload.Success
            ? "Cập nhật avatar thành công"
            : action.payload.Message,
        };
      case REQUEST(USER.GET_POINT):
        return {
          ...state,
          totalPoint: "",
        };
      case FAILURE(USER.GET_POINT):
        return {
          ...state,
          totalPoint: "",
        };

      case SUCCESS(USER.GET_POINT):
        return {
          ...state,
          totalPoint: action.payload.Data,
        };

      case REQUEST(USER.GET_INFO_MEMBER):
        return {
          ...state,
        };
      case FAILURE(USER.GET_INFO_MEMBER):
        return {
          ...state,
        };

      case SUCCESS(USER.GET_INFO_MEMBER):
        return {
          ...state,
          userInfoMember: action.payload.Data.Member,
        };

      default:
        return state;
    }
  }
);
