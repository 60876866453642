import React, { useState, ReactNode } from "react";
import FsLightBox from "fslightbox-react";
import Configuration from "../../configs/Config";
type Props = {
  sources: string[];
  isStandAlone?: boolean
  children?: ReactNode
};

const LightBoxWrapper = (props: Props) => {
  const { sources,isStandAlone,children } = props;
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <FsLightBox toggler={toggler} sources={sources} />
      <div className="overlay cursor-pointer w-100 h-100" onClick={() => setToggler(!toggler)}>
        {
          isStandAlone &&  <img
          className="w-100 h-100"
          src={sources ? sources[0] + "?mode=crop&width=800&height=800" : ""}
          alt="image"
        />
        }
        {children}
        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
          <span contentEditable={false} className="text-white">
            Xem thêm
          </span>
        </div>
      </div>
    </>
  );
};

export default LightBoxWrapper;
