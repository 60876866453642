import { t } from "../utils/utils";

export default {
  header: {
    self: {},
    items: [
      {
        title: "Thông tin cá nhân",
        root: true,
        alignment: "left",
        page: "profile",
        translate: "Profile",
      },
      {
        title: "Danh sách đơn hàng",
        root: true,
        alignment: "left",
        page: "order",
        translate: "Order List",
      },
      {
        title: "Danh sách deposit",
        root: true,
        alignment: "left",
        page: "deposit",
     
      },
      {
        title: "Danh sách khu nghỉ",
        root: true,
        alignment: "left",
        page: "product-vacation/list",
      
      },
      // {
      //   title: "Affiliate",
      //   root: true,
      //   alignment: "left",
      //   page: "affiliate",
      //   translate: "MENU.DASHBOARD"
      // },
      {
        title: "Đổi mật khẩu",
        root: true,
        alignment: "left",
        page: "change-password",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Đăng xuất",
        root: true,
        alignment: "left",
        page: "logout",
        translate: "MENU.DASHBOARD",
      },
    ],
  },
  aside: {
    self: {},
    items: [],
  },
  appAside: {
    self: {},
    items: [
     
      {
        title: "Deposit List",
        root: true,
        alignment: "left",
        page: "deposit",
       
        icon: "statement",
      },
      {
        title: "Order List",
        root: true,
        alignment: "left",
        page: "order",
       
        icon: "statement",
      },
      {
        title: "Danh sách khu nghỉ",
        root: true,
        alignment: "left",
        page: "product-vacation/list",
       
        icon: "statement",
      },
      // {
      //   title: "Affiliate",
      //   root: true,
      //   alignment: "left",
      //   page: "affiliate",
      //   translate: "MENU.DASHBOARD",
      //   icon: "stack"
      // },
    ],
  },
};
