import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Config from "../../configs/Config";
import Swal from "sweetalert2";
import Spinner from "../../layout/assets/icon/spinner/Spinner";
import { Link, useHistory } from "react-router-dom";
import Common from "../../utils/Common";
import { t } from "../../utils/utils";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

const Registration = ({ merchant }) => {
  const [isLoading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm();
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const refMerchantCode = useRef();
  const password = useRef({});
  password.current = watch("password", "");
  useEffect(() => {
    let merchantCode = window.location.host.startsWith("localhost")
      ? Config.merchantCode
      : window.location.host.split(".")[0];
    refMerchantCode.current = merchantCode;
  }, []);

  const onSubmit = ({ fullname, mobile, email, password }) => {
    var id = Common.guid();
    let objData = {
      Id: id,
      Name: fullname,
      Mobile: mobile,
      Email: email,
      MerchantCode: merchant.code,
      Password: password,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: id,
    };
    setLoading(true);
    Common.sendSyncCommand(
      "RegisterMemberMerchant",
      "CustomerRelationshipManagement",
      objData,
      () => {
        setLoading(false);
        Swal.fire("Cảm ơn đã đăng ký tài khoản.");
        setTimeout(() => {
          history.push("/auth/login");
        }, 3000);
      },
      () => {
        setLoading(false);
        Swal.fire("Đăng kí thất bại!");
      }
    );
  };

  return (
    <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/*begin::Heading*/}
        <div className="mb-10 text-center">
          {/*begin::Title*/}
          <h1 className="text-dark mb-3">{t("Create an Account")}</h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            {t("Already have an account")} ?{" "}
            <Link to="/auth/login" className="link-primary fw-bolder">
              {t("Sign in here")}
            </Link>
          </div>
          {/*end::Link*/}
        </div>
        {/*end::Heading*/}
        {/*begin::Action*/}

        {/*end::Action*/}
        {/*begin::Separator*/}
        <div className="d-flex align-items-center mb-10">
          <div className="border-bottom border-gray-300 mw-50 w-100" />
          <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
          <div className="border-bottom border-gray-300 mw-50 w-100" />
        </div>
        {/*end::Separator*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <label className="form-label required fw-bolder required text-dark fs-6">
            {t("Full name")}
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            placeholder={formatMessage({ id: "Full name" })}
            name="fullname"
            ref={register({ required: "is a required property" })}
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.fullname && <div>{t(errors.fullname.message)}</div>}
          </div>
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <label className="form-label required fw-bolder text-dark fs-6">
            Email
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            placeholder="Email"
            name="email"
            ref={register({ required: "is a required property" })}
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.email && <div>{t(errors.email.message)}</div>}
          </div>
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <label className="form-label required fw-bolder text-dark fs-6">
            {t("Mobile")}
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            placeholder={formatMessage({ id: "Mobile" })}
            name="mobile"
            ref={register({ required: "is a required property" })}
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.mobile && <div>{t(errors.mobile.message)}</div>}
          </div>
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          {/*begin::Wrapper*/}
          <div className="mb-1">
            {/*begin::Label*/}
            <label className="form-label required fw-bolder text-dark fs-6">
              {t("Password")}
            </label>
            {/*end::Label*/}
            {/*begin::Input wrapper*/}
            <div className="position-relative mb-3">
              <input
                className="form-control form-control-lg form-control-solid"
                type={passwordVisibility ? "password" : "text"}
                name="password"
                placeholder={formatMessage({ id: "Password" })}
                ref={register({ required: "is a required property" })}
                autoComplete="off"
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              >
                <i className="bi bi-eye-slash fs-2" />
                <i className="bi bi-eye fs-2 d-none" />
              </span>
            </div>
            {/*end::Input wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Hint*/}

          {/*end::Hint*/}
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.password && <div>{t(errors.password.message)}</div>}
          </div>
        </div>
        {/*end::Input group=*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-5 fv-plugins-icon-container">
          <label className="form-label required fw-bolder text-dark fs-6">
            {t("Confirm Password")}
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder={formatMessage({ id: "Confirm Password" })}
            ref={register({
              required: "is a required property",
              validate: (value) => {
                // console.log(
                //   { value, passwordCurrent: password.current },
                //   "1877"
                // );
                return (
                  value === password.current || t("Password does not match!")
                );
              },
            })}
            name="confirmPassword"
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.confirmPassword && (
              <div>{t(errors.confirmPassword.message)}</div>
            )}
          </div>
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}

        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          {/*begin::Submit button*/}
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-lg btn-primary w-100 mb-5"
          >
            <span className="indicator-label">{t("Sign Up")}</span>
            <span className="indicator-progress">
              {t("Please wait")}...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
          {/*end::Submit button*/}
          {/*begin::Separator*/}

          {/*end::Google link*/}
        </div>
        {/*end::Actions*/}
        <div />
      </form>
      {/*end::Form*/}
    </div>
  );
};
const mapStateToProp = (state) => ({
  merchant: state.Merchant.merchant,
});
export default connect(mapStateToProp)(Registration);
