import { gql, useQuery } from "urql";
import Configuration from "../configs/Config";

export const queryImages = gql`
  query Images(
    $keyword: String = ""
    $limit: Int = 10
    $offset: Int = 0
    $order: String = "desc"
    $sort: String = "createdDate"
    $from: DateTime
    $to: DateTime
   
    $tokenUser: String = ""
    $languageId: String = ""
    $active: Boolean
    $merchantId: String = ""
  ) {
    images(
      param: {
        keyword: $keyword
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        tokenUser: $tokenUser
        from: $from
        active: $active
        to: $to
      }
    ) {
      totalCount
      message
      success
      items {
        id
        name
        path
        categories{
            id
            name
        }
      }
      message
      success
      totalCount
    }
  }
`;

export function useQueryImages({
  keyword = "",
  limit = 10,
  offset = 0,
  order = "desc",
  sort = "createdDate",
  from = undefined,
  to = undefined,
  languageId = "",
  merchantId = "",
  tokenUser = "",
  active = false,
}) {
  const [list, refetch] = useQuery({
    query: queryImages,
    variables: (() => {
      return {
        active,
        keyword,
        limit,
        offset,
        order,
        sort,
        from,
        to,
        languageId: languageId || localStorage.getItem(Configuration.languageId),
        merchantId: merchantId || localStorage.getItem(Configuration.merchantId),
        tokenUser: tokenUser || localStorage.getItem(Configuration.tokenId),
      };
    })(),
    requestPolicy: "network-only",
  });

  return { list, refetch };
}