import classNames from "classnames";
import React, { useState, useEffect } from "react";

import { t } from "../../utils/utils";
import Select from "react-select";
import { Controller, FormContextValues } from "react-hook-form";

import { getCommonValues } from "../../utils/typeUltils";
import _ from "lodash";
import Common from "../../utils/Common";
import Box from "@mui/material/Box/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSetState } from "react-use";
import Slider from "@mui/material/Slider";
import { intl } from "../../i18n/I18nProvider";
import Tooltip from "@mui/material/Tooltip";

type TFormItem = {
  required?: boolean;
  label?: string;
  key?: string;
  noLabel?: boolean;
  minDate?: any;
  maxDate?: any;
  isSolidInput?: boolean;
  type?: "select" | "radio" | "date" | "date-range" | "slider";
  renderOptions?: (data: any) => { label: string; value: any }[];
  dataSource?: any;
  dataSourceParams?: Object;
  containerProps?: string;
  placeholder?: string;
  inputProps?: {
    readonly?: boolean;
    disabled?: boolean;
    [key: string]: any;
  };
};

interface IProps {
  items: TFormItem[];
  formKey: string;
  form: FormContextValues<any>;
}

export const FormItemSelect = ({
  ref,
  item,
  onChange,
  value,
}: {
  item: TFormItem;
  ref?: any;

  onChange: (data: any) => void;
  value: any;
}) => {
  const [keyword, setKeyword] = useState("");

  const dataSourceValues = (() => {
    if (Array.isArray(item.dataSource)) {
      return item.dataSource;
    } else {
      const { list, refetch } = item.dataSource({
        keyword,
        order: "asc",
        sort: "createdDate",
        active: true,
        limit: 10,
        offset: 0,
        ...getCommonValues(["MerchantId", "LanguageId"], true),
      });
      let tempOptions: any[] = [];
      if (list.data) {
        //console.log(Object.values(list.data), "6333");
        if (item.renderOptions) {
          tempOptions = item.renderOptions((Object.values(list.data as any)[0] as any).items);
        } else {
          tempOptions = (Object.values(list.data as any)[0] as any).items.map((item: any) => ({
            label: item.code && item.name ? `${item.code} - ${item.name}` : item.name || item.code,
            value: item.id,
          }));
        }
      }
      return tempOptions;
    }
  })();

  return (
    <Select
      noOptionsMessage={(inputValue) => {
        return item.inputProps?.noOptionsMessage || "Chưa có dữ liệu";
      }}
      onInputChange={(value) => setKeyword(value)}
      onChange={onChange}
      placeholder="Chọn"
      value={value}
      styles={{
        container: (base) => ({
          ...base,

          padding: 0,
        }),
        /*  menu: (base) => ({
          ...base,
          zIndex: "10",
        }), */
        indicatorSeparator: (base) => ({
          display: "none",
        }),
        indicatorsContainer: (base) =>
          item.inputProps?.readonly
            ? {
                ...base,
                display: "none",
              }
            : { ...base },
        control: (base, state) => ({
          ...base,
          padding: "0px 10px",
          borderRadius: "6px",
          minHeight: "44px",
          height: "44px",
          border: item.isSolidInput ? "none" : "1px solid #e4e6ef",
          backgroundColor: item.isSolidInput
            ? `${item.inputProps?.disabled || item.inputProps?.readonly ? "" : "#f5f8fa"}`
            : `${item.inputProps?.disabled || item.inputProps?.readonly ? "" : "#fff"}`,
          boxShadow: "none",
          color: "#5e6278 !important",
          "&:hover": {
            color: "#5e6278 !important",
            border: item.isSolidInput ? "none" : "1px solid #e4e6ef",
            // backgroundColor: "#f5f8fa !important",
            boxShadow: "none",
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
          top: -2,
        }),
      }}
      options={dataSourceValues}
      name={item.key}
      ref={ref}
    />
  );
};
export const FormItemDateRange = ({
  onChange,
  value,
  item,
}: {
  item: TFormItem;
  ref?: any;

  onChange: (data: any) => void;
  value: any;
}) => {
  return;
};
export const FormItemLabel = (props: { required?: boolean; label?: string }) => {
  const { label, required } = props;
  return (
    <label className="d-flex align-items-center fs-5 fw-bolder mb-2">
      <span
        className={classNames("fs-5 fw-bolder mb-2", {
          required: required,
        })}
      >
        {t(label)}
      </span>
    </label>
  );
};

const FormItems = (props: IProps) => {
  const { items, formKey, form } = props;

  return (
    <>
      {items.map(({ containerProps, ...item }: TFormItem) => {
        return (
          <div
            data-form-field-key={`${formKey}_${item.key}`}
            className={classNames("mb-5 d-flex flex-column", {
              [`${containerProps}`]: containerProps,
            })}
          >
            {item.label && <FormItemLabel required={item.required} label={item.label} />}

            {item.type === "select" ? (
              <Controller
                as={({ onChange, value, ref }) => (
                  <FormItemSelect item={item} onChange={onChange} value={value} ref={ref} />
                )}
                control={form.control}
                name={item.key || Common.guid()}
              />
            ) : item.type === "radio" ? (
              <Controller
                control={form.control}
                name={item.key || Common.guid()}
                as={({ onChange, value }) => (
                  <RadioGroup
                    defaultValue={item.inputProps?.defaultValue}
                    row
                    value={value}
                    onChange={onChange}
                  >
                    {Array.isArray(item.dataSource)
                      ? item.dataSource.map((option) => (
                          <FormControlLabel
                            value={option.value}
                            label={option.label}
                            control={<Radio />}
                          />
                        ))
                      : []}
                  </RadioGroup>
                )}
              />
            ) : item.type === "slider" ? (
              <Controller
                control={form.control}
                name={item.key || Common.guid()}
                defaultValue={[item.inputProps?.min || 0, item.inputProps?.max || 100]}
                as={({ onChange, value }) => (
                  <Slider
                    sx={{
                      "& .MuiSlider-valueLabel": {
                        fontSize: 12,
                        fontWeight: "normal",
                        top: -6,
                        backgroundColor: "unset",
                        color: "black",
                        "&:before": {
                          display: "none",
                        },
                        "& *": {
                          background: "transparent",
                          color: "black",
                        },
                      },
                      "& .MuiSlider-thumb": {
                        width: "12px",
                        height: "12px",
                      },
                      "& .MuiSlider-root": {
                        marginTop: "6px",
                      },
                    }}
                    style={{}}
                    min={item.inputProps?.min || 0}
                    max={item.inputProps?.max || 100}
                    value={value}
                    valueLabelDisplay="on"
                    onChange={onChange}
                  />
                )}
              />
            ) : (
              <input
                {...item.inputProps}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) &&
                  item.inputProps?.type === "number" &&
                  evt.preventDefault()
                }
                placeholder={"Nhập vào"}
                ref={form.register}
                className={classNames("form-control", {
                  "form-control-solid": item.isSolidInput,
                })}
                name={item.key}
              />
            )}
            <div
              className={classNames("tw-line-clamp-1 tw-text-sm", {
                "tw-opacity-0 tw-pointer-events-none": _.isEmpty(form.errors),
                "text-danger": form.errors[`${item.key}`],
              })}
            >
              <Tooltip
                title={form.errors[`${item.key}`] && intl(form.errors[`${item.key}`].message)}
              >
                <span>
                  {" "}
                  {form.errors[`${item.key}`] && intl(form.errors[`${item.key}`].message)}
                </span>
              </Tooltip>
            </div>
            {/*  <div className="fv-plugins-message-container invalid-feedback">
              
            </div> */}
          </div>
        );
      })}
    </>
  );
};

export const scrollToField = (formKey: string, fieldKey: string) => {
  const el = document.querySelector(`[data-form-field-key="${formKey}_${fieldKey}"]`);

  if (el) {
    const y = el.getBoundingClientRect().top + window.pageYOffset - 100;
    window.scrollTo({ top: y });
  }
};

export default FormItems;
