import Axios from "axios";
import moment from "moment";

import React, { useState, useEffect, useRef } from "react";
import { useLocalStorage, useSetState } from "react-use";
import Configuration from "../../../../configs/Config";
import Common from "../../../../utils/Common";
import { t } from "../../../../utils/utils";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import Select from "react-select";
import { getProductsVacation } from "../../../../services/ProductService";
import { useHistory } from "react-router";
import { GoongGeocode } from "../../../../utils/Gonggeocode";
import "./style.scss";
import { useComponentVisible } from "../../../../utils/customHook";

import Stars from "../../../../partials/Stars";

export const getStarName = (attributeList) => {
  let name = "";
  if (attributeList && attributeList.length > 0) {
    attributeList.forEach((attribute) => {
      if (attribute.name === "Hạng sao") {
        attribute.attributeValues.forEach((attributeValue) => {
          name = attributeValue.value;
        });
      }
    });
  }

  return name;
};

const ProductVacation = () => {
  const { push } = useHistory();
  const [options, setOptions] = useState([]);
  const [
    currentAddress,
    setCurrentAddress,
    removeCurrentAddress,
  ] = useLocalStorage("address");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [initialSettings, setInitialSettings] = useSetState({
    minDate: new Date(),
    autoApply: true,
    showDropdowns: true,
    autoUpdateInput: false,
    locale: {
      cancelLabel: true,
    },
  });
  const [picker, setPicker] = useState();
  const [location, setLocation, removeLocation] = useLocalStorage("location");
  const [childAges, setChildAges] = useLocalStorage("childAges", []);

  const [peopleParams, setPeopleParams] = useLocalStorage("peopleParams", {
    adult: 1,
    child: 0,
  });
  const {
    isComponentVisible: isFilterVisible,
    ref: filterRef,
    setIsComponentVisible: setIsFilterVisible,
  } = useComponentVisible();
  const {
    isComponentVisible,
    ref,
    setIsComponentVisible,
  } = useComponentVisible();
  const [productType, setProductType] = useLocalStorage("productType", "0");
  const [sizeParams, setSizeParams] = useSetState({
    from: 0,
    size: 10,
  });
  const [keyword, setKeyword] = useState("");
  const [datePickerKey, setDatePickerKey] = useState(Common.guid());
  const [filterParams, setFilterParams] = useState("");
  const [dateParams, setDateParams, removeDateParams] = useLocalStorage(
    "dateParams"
  );
  const [filterList, setFilterList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortParam, setSortParam] = useSetState({
    order: "asc",
  });
  const selectInputRef = useRef();

  const getAttributes = async () => {
    return await Axios.post(
      `https://es.izzi.asia/attributes/attribute/_search?from=0&size=${10}`,
      {
        _source: { includes: [] },
        query: {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        merchantId: localStorage.getItem(
                          Configuration.merchantId
                        ),
                      },
                    },
                  ],
                },
              },

              {
                match: {
                  languageId: localStorage.getItem(Configuration.languageId),
                },
              },

              {
                match: {
                  id:
                    "3fee7713-d206-9f5f-beeb-72fe25672ae1,48735999-dec3-da81-ebb6-5ddc014f70d0,15f88ad6-b990-aaba-0786-7d1e09bb859c",
                },
              },
            ],
          },
        },
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Basic ${window.btoa("amara:dSPKMcdQkG5X97b")}`,
        },
      }
    );
  };
  const getStars = async () => {
    return await Axios.post(
      `https://es.izzi.asia/attributes/attribute/_search?from=0&size=${10}`,
      {
        _source: { includes: [] },
        query: {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        merchantId: localStorage.getItem(
                          Configuration.merchantId
                        ),
                      },
                    },
                  ],
                },
              },

              {
                match: {
                  languageId: localStorage.getItem(Configuration.languageId),
                },
              },
              {
                match: {
                  id: "867ebf80-6135-c782-b91f-1ea57cd2a807",
                },
              },
            ],
          },
        },
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Basic ${window.btoa("amara:dSPKMcdQkG5X97b")}`,
        },
      }
    );
  };
  const handleUpdatepeopleParams = (type, direction) => {
    if (
      (direction === "minus" && type === "adult" && peopleParams.adult === 1) ||
      (direction === "minus" && type === "child" && peopleParams.child === 0)
    ) {
      return;
    }
    if (direction === "plus" && type === "adult") {
      setPeopleParams({
        ...peopleParams,
        adult: peopleParams.adult + 1,
      });
    }
    if (direction === "plus" && type === "child") {
      setPeopleParams({
        ...peopleParams,
        child: peopleParams.child + 1,
      });
      const tempChildAges = [...childAges];
      tempChildAges.push(1);
      setChildAges(tempChildAges);
    }
    if (direction === "minus" && type === "child") {
      setPeopleParams({
        ...peopleParams,
        child: peopleParams.child - 1,
      });
      const tempChildAges = [...childAges];
      tempChildAges.pop();
      setChildAges(tempChildAges);
    }
    if (direction === "minus" && type === "adult") {
      setPeopleParams({
        ...peopleParams,
        adult: peopleParams.adult - 1,
      });
    }
  };
  const handleGetProductVacation = ({
    peopleParams,
    dateParams,
    filterParams,
    keyword,
    sortParam,
    productType,
    location,
  }) => {
    setSizeParams({
      from: 0,
      size: 10,
    });
    setLoading(true);
    getProductsVacation({
      dateParams,
      filterParams,
      keyword,
      sortParam,
      productType,
      location,
      peopleParams,
    })
      .then((res) => {
        setProducts(res.data.hits.hits);
        setTotalCount(res.data.hits.total);
      })
      .finally(() => setLoading(false));
  };

  const handleSetProductType = (type) => {
    setProductType(type);

    handleGetProductVacation({
      peopleParams,
      dateParams,
      filterParams,
      location,
      keyword,
      sortParam,
      productType: type,
    });
  };

  useEffect(() => {
    setLoading(true);

    const tempChildAges = childAges;

    const adultTotal = peopleParams.adult;
    setChildAges([...tempChildAges]);
    setPeopleParams({
      adult: adultTotal,
      child: tempChildAges.length,
    });
    getStars().then((res) => {
      const datas = res.data.hits.hits;
      /* setFilterParams([
        ...datas.map((data) => ({ id: data._id, currentValue: "" })),
      ]); */
      setFilterList((oldFilterList) => {
        const tempList = datas.map((data) => ({
          id: data._id,
          label: data._source.name,
          values: [...data._source.attributeValues],
        }));
        return [...oldFilterList, ...tempList];
      });
    });
    getAttributes().then((res) => {
      const datas = res.data.hits.hits;
      /* setFilterParams([
        ...datas.map((data) => ({ id: data._id, currentValue: "" })),
      ]); */
      setFilterList((oldFilterList) => {
        const tempList = datas.map((data) => ({
          id: data._id,
          label: data._source.name,
          values: [...data._source.attributeValues],
        }));
        return [...oldFilterList, ...tempList];
      });
    });
    
    if (childAges || peopleParams.adult) {
      getProductsVacation({
        productType,
        location,
        dateParams,
        peopleParams: {
          adult: peopleParams.adult,
          child: childAges.length,
        },
      })
        .then((res) => {
          setProducts(res.data.hits.hits);
          setTotalCount(res.data.hits.total);
        })
        .finally(() => setLoading(false));
    }
    if (dateParams?.from && dateParams?.to) {
      const tempFrom = dateParams.from;

      const tempTo = dateParams.to;

      setInitialSettings({
        autoUpdateInput: true,
        startDate: moment(tempFrom).format("DD-MM-YYYY"),
        endDate: moment(tempTo).format("DD-MM-YYYY"),
      });
    }
  }, []);
  useEffect(() => {
    setDatePickerKey(Common.guid());
  }, [initialSettings]);
  useEffect(() => {
    if (_.isEmpty(filterParams)) {
      setFilterParams("");
    }
  }, [filterParams]);

  return (
    <>
      <div className="card mb-10 mt-10">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <div className="d-flex flex-wrap align-items-center w-100 w-lg-400px my-1 me-lg-5 position-relative">
              {/*begin::Select2*/}
              <i class="fonticon-location fs-3 ps-4 position-absolute z-index-3"></i>
              <Select
                onMenuOpen={() => {
                  if (options.length === 0) {
                    GoongGeocode.getPlacePredictions("ha noi").then(
                      (predictions) => {
                        setOptions(
                          predictions.map((prediction) => ({
                            label: prediction.description,
                            value: prediction.place_id,
                          }))
                        );
                      }
                    );
                  }
                }}
                defaultValue={currentAddress}
                placeholder="Bạn muốn đến đâu?"
                ref={selectInputRef}
                className="form-control-solid placeholder-gray-500"
                styles={{
                  valueContainer: (base) => ({
                    ...base,
                    marginRight: "25px",
                  }),
                  input: (base) => ({
                    ...base,

                    fontSize: "1.1rem",
                    paddingLeft: "2.5rem",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    paddingLeft: "2.5rem",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#a9a5bd",
                    fontWeight: 500,
                    fontSize: "1.2rem",
                    paddingLeft: "2.5rem",
                  }),
                  indicatorsContainer: () => ({
                    display: "none",
                  }),
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                  control: (base, state) => ({
                    ...base,

                    boxShadow: "0 !important",
                    borderWidth: "3px !important",
                    backgroundColor: "#f5f8fa !important",
                    borderColor: "#f5f8fa !important",
                    color: "#5e6278 !important",
                    "&:hover": {
                      color: "#5e6278 !important",
                      borderWidth: "3px !important",
                      backgroundColor: "#f5f8fa !important",
                      borderColor: "#f5f8fa !important",
                    },
                  }),
                }}
                onChange={(e) => {
                  //console.log(e, 460);
                  setCurrentAddress(e);
                  if (e?.value) {
                    GoongGeocode.fromPlaceId(e.value).then((place) => {
                      const { lat, lng } = place.geometry.location;
                      const tempLocation = { lat: lat(), lon: lng() };
                      setLocation(tempLocation);
                    });
                  }
                }}
                onInputChange={(e) => {
                  GoongGeocode.getPlacePredictions(e).then((predictions) => {
                    setOptions(
                      predictions.map((prediction) => ({
                        label: prediction.description,
                        value: prediction.place_id,
                      }))
                    );
                  });
                }}
                options={options}
              />
              <span
                onClick={() => {
                  selectInputRef.current.clearValue();
                  setCurrentAddress();
                  setLocation("");
                }}
                style={{ right: 0 }}
                className="svg-icon svg-icon-1 position-absolute me-4 cursor-pointer"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="7.05025"
                    y="15.5356"
                    width={12}
                    height={2}
                    rx={1}
                    transform="rotate(-45 7.05025 15.5356)"
                    fill="currentColor"
                  />
                  <rect
                    x="8.46447"
                    y="7.05029"
                    width={12}
                    height={2}
                    rx={1}
                    transform="rotate(45 8.46447 7.05029)"
                    fill="currentColor"
                  />
                </svg>
              </span>
              {/*end::Select2*/}
            </div>
            <div className="d-flex flex-wrap align-items-center my-1 me-lg-5 flex-grow-1 position-relative">
              <span className="svg-icon position-absolute ms-4 svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                    fill="currentColor"
                  />
                  <path
                    d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                    fill="currentColor"
                  />
                  <path
                    d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <DateRangePicker
                key={datePickerKey}
                initialSettings={initialSettings}
                onApply={(event, picker) => {
                  setPicker(picker);
                  picker.element.val(
                    picker.startDate.format("DD/MM/YYYY") +
                      " - " +
                      picker.endDate.format("DD/MM/YYYY")
                  );
                  const formattedStartDate = moment(
                    picker.startDate,
                    "YYYY-MM-DD"
                  );
                  const formattedEndDate = moment(picker.endDate, "YYYY-MM-DD");
                  setDateParams({
                    from: formattedStartDate,
                    to: formattedEndDate,
                  });
                }}
              >
                <input
                  placeholder={`${useIntl().formatMessage({
                    id: "Check-in date",
                  })} - ${useIntl().formatMessage({
                    id: "Check-out date",
                  })}`}
                  className="form-control form-control-solid ps-14"
                />
              </DateRangePicker>
              <span
                onClick={() => {
                  setInitialSettings({
                    autoUpdateInput: false,
                  });

                  removeDateParams();
                }}
                style={{ right: 0 }}
                className="svg-icon svg-icon-1 position-absolute me-4 cursor-pointer"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="7.05025"
                    y="15.5356"
                    width={12}
                    height={2}
                    rx={1}
                    transform="rotate(-45 7.05025 15.5356)"
                    fill="currentColor"
                  />
                  <rect
                    x="8.46447"
                    y="7.05029"
                    width={12}
                    height={2}
                    rx={1}
                    transform="rotate(45 8.46447 7.05029)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="d-flex flex-wrap align-items-center my-1 me-lg-5 position-relative w-100 w-lg-300px">
              <i class="fas fa-user position-absolute ms-4"></i>
              <input
                placeholder={
                  peopleParams
                    ? `${peopleParams.adult} người lớn  -  ${peopleParams.child} trẻ em `
                    : "Chọn số người lớn - trẻ em"
                }
                className="form-control form-control-solid ps-14"
                readOnly
                onClick={() => {
                  if (!peopleParams) {
                    setPeopleParams({
                      child: 0,
                      adult: 1,
                    });
                  }
                  setIsComponentVisible(true);
                }}
              />

              <div
                ref={ref}
                className={`card w-100 card-xxl-stretch position-absolute search-people ${
                  isComponentVisible ? "visible" : "d-none"
                }`}
              >
                <div className="card-body fs-3 p-6">
                  <div className="me-19 justify-content-between d-flex w-100 mb-3">
                    <span>{t("Adult")}:</span>

                    <div className="position-relative min-w-125px">
                      <i
                        onClick={() =>
                          handleUpdatepeopleParams("adult", "minus")
                        }
                        className="fas cursor-pointer fa-minus-circle position-absolute minus-circle-postion fs-3"
                      ></i>
                      <span className="position-absolute value-position fs-3">
                        {peopleParams.adult}
                      </span>
                      <i
                        onClick={() =>
                          handleUpdatepeopleParams("adult", "plus")
                        }
                        className="fas cursor-pointer fa-plus-circle position-absolute plus-circle-postion fs-3"
                      ></i>
                    </div>
                  </div>
                  <div className="me-19 justify-content-between d-flex w-100 mb-3">
                    <span>{t("Children")}:</span>
                    <div className="position-relative min-w-125px">
                      <i
                        onClick={() =>
                          handleUpdatepeopleParams("child", "minus")
                        }
                        className="fas cursor-pointer fa-minus-circle position-absolute minus-circle-postion fs-3"
                      ></i>
                      <span className="position-absolute value-position fs-3">
                        {peopleParams.child}
                      </span>
                      <i
                        onClick={() =>
                          handleUpdatepeopleParams("child", "plus")
                        }
                        className="fas cursor-pointer fa-plus-circle position-absolute plus-circle-postion fs-3"
                      ></i>
                    </div>
                  </div>
                  {peopleParams.child > 0 && (
                    <>
                      <div className="mb-3">Tuổi trẻ em</div>
                      <div className="row mb-5 gy-3">
                        {childAges.map((v, index) => {
                          return (
                            <div className="col-4">
                              <select
                                value={childAges[index]}
                                onChange={(e) => {
                                  const tempChildAges = [...childAges];
                                  tempChildAges[index] = +e.target.value;
                                  setChildAges(tempChildAges);
                                }}
                                className="form-select text-center form-select-solid"
                              >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (num) => {
                                    return <option>{num}</option>;
                                  }
                                )}
                              </select>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 mt-lg-0">
              <span
                onClick={() => {
                  if (!currentAddress) {
                    removeCurrentAddress();
                  } else {
                    setCurrentAddress(currentAddress);
                  }
                  if (!location) {
                    removeLocation();
                  } else {
                    setLocation(location);
                  }
                  handleGetProductVacation({
                    peopleParams,
                    dateParams,
                    filterParams,
                    location,
                    keyword,
                    sortParam,
                    productType,
                  });
                }}
                className="btn btn-primary"
              >
                {t("Search")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card position-relative">
        {loading && (
          <div className="spin d-flex justify-content-center align-items-center z-index-3">
            <span
              className="spinner-border text-primary spinner-border-sm align-middle ms-2"
              style={{ width: "3rem", height: "3rem" }}
            ></span>
          </div>
        )}
        {/*begin::Card header*/}
        <div className="card-header border-0 pt-6">
          {/*begin::Card title*/}
          <div className="card-title w-100 w-lg-auto">
            {/*begin::Search*/}
            <div className="w-100">
              <button
                onClick={() => handleSetProductType("0")}
                className={`btn ${
                  productType === "0" ? "btn-primary" : "btn-light"
                } w-lg-200px w-100 me-lg-3 mb-3 mb-lg-0`}
              >
                {t("Extra Vacation")}
              </button>
              <button
                onClick={() => handleSetProductType("1")}
                className={`btn ${
                  productType === "1" ? "btn-primary" : "btn-light"
                } w-lg-200px w-100 me-lg-3 mb-3 mb-lg-0`}
              >
                {t("Timeshare")}
              </button>

              <button
                onClick={() => handleSetProductType("2")}
                className={`btn ${
                  productType === "2" ? "btn-primary" : "btn-light"
                }  w-lg-200px w-100 `}
              >
                {t("Bonus Week")}
              </button>
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card title*/}
          {/*begin::Card toolbar*/}

          <div className="card-toolbar w-100 w-lg-auto justify-content-center">
            {/*begin::Toolbar*/}
            <div className="me-3">
              <select
                name="productId"
                onChange={(e) => {
                  setSortParam({
                    order: e.target.value,
                  });
                  handleGetProductVacation({
                    peopleParams,
                    location,
                    dateParams,
                    filterParams,
                    keyword,
                    productType,
                    sortParam: { order: e.target.value },
                  });
                }}
                placeholder="Chọn hạng phòng"
                class="form-select form-select-solid"
              >
                <option value="asc" disabled="">
                  Giá giảm dần
                </option>
                <option value="desc" disabled="">
                  Giá tăng dần
                </option>
              </select>
            </div>
            <div
              className="d-flex justify-content-end position-relative"
              data-kt-user-table-toolbar="base"
            >
              {/*begin::Filter*/}
              <button
                type="button"
                onClick={() => setIsFilterVisible(true)}
                className="btn btn-light-primary me-3"
                data-kt-menu-placement="bottom-end"
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {t("Filter")}
              </button>
              {/*begin::Menu 1*/}
              <div
                ref={filterRef}
                className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px ${
                  isFilterVisible ? "filter-list--show" : ""
                }`}
                data-kt-menu="true"
              >
                {/*begin::Header*/}
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bold">
                    {t("Filter Options")}
                  </div>
                </div>
                {/*end::Header*/}
                {/*begin::Separator*/}
                <div className="separator border-gray-200" />
                {/*end::Separator*/}
                {/*begin::Content*/}
                <div className="px-7 py-5" data-kt-user-table-filter="form">
                  {/*begin::Input group*/}
                  {filterList.map((item) => {
                    return (
                      <div key={item.id} className="mb-10">
                        <label className="form-label fs-6 fw-semibold">
                          {item.label}:
                        </label>
                        <Select
                          isMulti
                          placeholder={`Chọn ${item.label}...`}
                          value={
                            filterParams && filterParams[item.id]
                              ? filterParams[item.id]
                              : ""
                          }
                          onChange={(e) => {
                            if (e.length > 0) {
                              setFilterParams((oldFilterParams) => ({
                                ...oldFilterParams,
                                [item.id]: e,
                              }));
                            } else {
                              setFilterParams((oldFilterParams) => {
                                const tempFilterParams = { ...oldFilterParams };
                                delete tempFilterParams[item.id];
                                return tempFilterParams;
                              });
                            }
                          }}
                          options={item.values.map((value) => ({
                            label: item.label === "Hạng sao"? <Stars value={value.value} />: value.value,
                            value: value.keyId,
                          }))}
                        />

                      
                      </div>
                    );
                  })}

            
                  <div className="d-flex justify-content-end">
                    <button
                      type="reset"
                      onClick={() => setFilterParams("")}
                      className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                    >
                      {t("Reset Settings")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary fw-semibold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                      onClick={() => {
                        handleGetProductVacation({
                          peopleParams,
                          location,
                          dateParams,
                          filterParams,
                          keyword,
                          sortParam,
                          productType,
                        });
                        //console.log(JSON.stringify(dateParams, "726"));
                      }}
                    >
                      {t("Search")}
                    </button>
                  </div>
                  {/*end::Actions*/}
                </div>
                {/*end::Content*/}
              </div>
             
            </div>
            
          </div>
          {/*end::Card toolbar*/}
        </div>
       
        <div className="card-body pt-0 ">
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-4">
            {/*begin::Col*/}
            {products.length > 0 ? (
              products.map((product) => {
                const { _source } = product;
                return (
                  <div className="col-lg-6" key={product._id}>
                    {/*begin::Card widget 15*/}
                    <div className="card card-flush h-xl-100">
                      {/*begin::Body*/}
                      <div className="card-body p-0">
                        {/*begin::Row*/}
                        <div className="row gx-9 h-100">
                          {/*begin::Col*/}
                          <div className="col-sm-6 mb-10 mb-sm-0">
                            {/*begin::Overlay*/}
                            <span
                              className="d-block overlay cursor-pointer h-100"
                              data-fslightbox="lightbox-hot-sales"
                              onClick={() =>
                                push(
                                  `/product-vacation/detail/list-vacation/${encodeURIComponent(
                                    _source.friendlyUrl
                                  )}`
                                )
                              }
                            >
                              {/*begin::Image*/}
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px h-100"
                                style={{
                                  backgroundImage: `url(${
                                    _source?.images?.length > 0
                                      ? Configuration.staticDomain +
                                        _source.images[0].path +
                                        "?mode=crop&width=300&height=300"
                                      : ""
                                  })`,
                                }}
                              />
                              
                              {/*end::Image*/}
                              {/*begin::Action*/}

                              {/*end::Action*/}
                            </span>
                            {/*end::Overlay*/}
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col-sm-6">
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-column h-100">
                              {/*begin::Header*/}

                              {/*begin::Title*/}

                              <h3
                                className="text-gray-800 cursor-pointer text-hover-primary fs-2 fw-bold"
                                onClick={() =>
                                  push(
                                    `/product-vacation/detail/list-vacation/${encodeURIComponent(
                                      _source.friendlyUrl
                                    )}`
                                  )
                                }
                              >
                                {_source.name}
                              </h3>

                              <div className="mb-4">
                                <Stars
                                  value={getStarName(_source.attributes)}
                                />
                              </div>
                              {/*end::Title*/}
                              {/*begin::Items*/}

                              {/*begin::Item*/}
                              <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}

                                <p className="fw-semibold text-gray-600 d-block fs-5">
                                  <i class="fonticon-location fs-4 me-2"></i>
                                  {_source.detailAddress || "N / A"}
                                </p>

                                {/*end::Info*/}
                              </div>
                              <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}

                                <p className="fw-semibold text-gray-600 d-block fs-5">
                                  {t("Price From")}
                                  <b className="text-primary ms-2">
                                    {_source.fromPointPrice}
                                  </b>{" "}{t("point")}
                                </p>

                                {/*end::Info*/}
                              </div>
                              <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}

                                <p className="fw-semibold text-gray-600 d-block fs-6">
                                  <i
                                    class="fa fa-calendar me-3"
                                    aria-hidden="true"
                                  ></i>
                                  {moment(_source.fromDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(_source.toDate).format("DD/MM/YYYY")}
                                </p>

                                {/*end::Info*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}

                              {/*end::Item*/}

                              {/*end::Items*/}

                              {/*end::Header*/}
                              {/*begin::Body*/}

                              {/*end::Body*/}
                              {/*begin::Footer*/}

                              {/*end::Footer*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                      </div>
                      {/*end::Body*/}
                    </div>
                    {/*end::Card widget 15*/}
                  </div>
                );
              })
            ) : (
              <div className="card">
                {/*begin::Card body*/}
                <div className="card-body p-0">
                  {/*begin::Wrapper*/}
                  <div className="card-px text-center py-20 my-10">
                    {/*begin::Title*/}

                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <p className="text-gray-400 fs-4 fw-bold mb-10">
                      {t("No data")}.
                    </p>
                    {/*end::Description*/}
                    {/*begin::Action*/}

                    {/*end::Action*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Illustration*/}
                  <div className="text-center px-4">
                    <img
                      className="mw-100 mh-300px"
                      alt=""
                      src="assets/media/illustrations/sigma-1/5.png"
                    />
                  </div>
                  {/*end::Illustration*/}
                </div>
                {/*end::Card body*/}
              </div>
            )}

            {/*end::Col*/}
            {/*begin::Col*/}

            {/*end::Col*/}
          </div>
        </div>
        <div className="row pb-10">
          <div className="col-12 d-flex align-items-center justify-content-center">
            {products.length < totalCount && (
              <button
                onClick={() => {
                  getProductsVacation({
                    peopleParams,
                    dateParams,
                    filterParams,
                    keyword,
                    sortParam,
                    productType: productType.toString(),
                    location,
                    sizeParams: {
                      from: sizeParams.from + 10,
                      size: 10,
                    },
                  }).then((res) => {
                    setProducts([...products, ...res.data.hits.hits]);
                    setTotalCount(res.data.hits.total);
                  });
                  setSizeParams({
                    from: sizeParams.from + 10,
                  });
                }}
                className="btn btn-primary"
              >
                {t("Load more")}
              </button>
            )}
          </div>
        </div>
       
      </div>
    </>
  );
};

export default ProductVacation;
