
import { createClient,defaultExchanges } from "urql";
import Configuration from "../configs/Config";


export const client = createClient({
  url: Configuration.urlGraphQl,
  exchanges: [...defaultExchanges],

});
