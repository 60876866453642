import moment from "moment";
import React from "react";
import Configuration from "../../configs/Config";
import Common from "../../utils/Common";
import { t, toAbsoluteUrl } from "../../utils/utils";

const GridTable = (props) => {
  const { items } = props;
  //console.log(props.items, 4);
  return (
    <div className="row g-5 mb-10 mt-1">
    
      {items?.length > 0 ? items.map((item) => {
        return (
          <div key={item.id} className="col-md-4">
            {/*begin::Feature post*/}
            <div
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
              className="card-xl-stretch card pb-5"
            >
              {/*begin::Image*/}
              <span
                className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-2"
                style={{
                  backgroundImage: `url(${item.images?.length ? Configuration.staticDomain +
                     item.images[0]?.path : Configuration.imageDefault})`,
                }}
              >
                {/* <img
                  src="assets/media/svg/misc/video-play.svg"
                  className="position-absolute top-50 start-50 translate-middle"
                  alt=""
                /> */}
              </span>

              {/*end::Image*/}
              {/*begin::Body*/}
              <div className="m-0 p-4">
                {/*begin::Title*/}

                <p className="fs-4 text-dark fw-bolder text-dark lh-base">
                  {item.name}
                </p>
                <div className="fs-6 fw-bolder">
                  {/*begin::Author*/}

                  {/*end::Author*/}
                  {/*begin::Date*/}

                  {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen018.svg*/}
                  <span className="svg-icon svg-icon-primary svg-icon-2x me-1 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        fill="black"
                      />
                      <path
                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {item.addresses?.length > 0 && item.addresses[0]?.name}

                  {/*end::Date*/}
                </div>
                {/*end::Title*/}
                {/*begin::Text*/}
                <div className="fw-bold fs-5 text-gray-600 text-dark my-4">
                  {moment(item.fromDate)
                    .format("DD/MM/YYYY")
                    .toString()}{" "}
                  - {moment(item.toDate).format("DD/MM/YYYY")}
                </div>
                {/*end::Text*/}
                {/*begin::Content*/}
                <div className="fs-6 fw-bolder">
                  {/*begin::Author*/}

                  {/*end::Author*/}
                  {/*begin::Date*/}
                  {Common.formatMoney(item.price)}
                  {/*end::Date*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Feature post*/}
          </div>
        )}) : <div className="card">
        {/*begin::Card body*/}
        <div className="card-body p-0">
          {/*begin::Wrapper*/}
          <div className="card-px text-center py-20 my-10">
            {/*begin::Title*/}

            {/*end::Title*/}
            {/*begin::Description*/}
            <p className="text-gray-400 fs-4 fw-bold mb-10">
              {t("No data")}.
            </p>
            {/*end::Description*/}
            {/*begin::Action*/}

            {/*end::Action*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Illustration*/}
          <div className="text-center px-4">
            <img
              className="mw-100 mh-300px"
              alt=""
              src={toAbsoluteUrl(
                "theme/media/illustrations/sigma-1/5.png"
              )}
            />
          </div>
          {/*end::Illustration*/}
        </div>
        {/*end::Card body*/}
      </div>
}
      
    </div>
  );
};

export default GridTable;
