import Configuration from "../configs/Config";
import axios from "axios";
import Common from "../utils/Common";
import { gql, useQuery } from "urql";

export const getAddresses = () => {
  const languageId = localStorage.getItem(Configuration.languageId);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  const categoryId = window.config.categoryId;

  let _param = `merchantId:"${merchantId}", limit: 10000, offset: 0 , languageId: "${languageId}",sort:"name" , order:"asc"`;
  if (categoryId) _param += `,categoryIds:["${categoryId}"]`;
  return Common.getDataGraphQl(`
    {
        addresses(param:{${_param}}){
          items {
            id
            name
            latitude
            longitude
          },
          message,
          success,
          totalCount
        }
      }
    `);
};
export const queryAddresses = gql`
  query Addresses(
    $keyword: String = ""
    $limit: Int = 10
    $offset: Int = 0
    $order: String = "desc"
    $sort: String = "createdDate"
    $from: DateTime
    $to: DateTime
   
    $tokenUser: String = ""
    $languageId: String = ""
    $active: Boolean
    $merchantId: String = ""
  ) {
    addresses(
      param: {
        keyword: $keyword
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        tokenUser: $tokenUser
        from: $from
        active: $active
        to: $to
      }
    ) {
      totalCount
      message
      success
      items {
        id
        name
       
      }
      message
      success
      totalCount
    }
  }
`;

export function useQueryAddresses({
  keyword = "",
  limit = 10,
  offset = 0,
  order = "desc",
  sort = "createdDate",
  from = undefined,
  to = undefined,
  languageId = "",
  merchantId = "",
  tokenUser = "",
  active = false,
}) {
  const [list, refetch] = useQuery({
    query: queryAddresses,
    variables: (() => {
      return {
        active,
        keyword,
        limit,
        offset,
        order,
        sort,
        from,
        to,
        languageId: languageId || localStorage.getItem(Configuration.languageId),
        merchantId: merchantId || localStorage.getItem(Configuration.merchantId),
        tokenUser: tokenUser || localStorage.getItem(Configuration.tokenId),
      };
    })(),
    requestPolicy: "network-only",
  });

  return { list, refetch };
}
