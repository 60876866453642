import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { ComponentPropsWithRef, useState } from "react";

export type BoxImageProps = {
  isSelected?: boolean;
  name?: string;
  src: "add" | string;
  onRemove?: () => void;
  showCheck?: boolean;
  isUploading?: boolean;
} & ComponentPropsWithRef<"div">;   

const BoxImage = ({
  isSelected,
  name,
  src,
  showCheck,
  onRemove,
  isUploading,
  ...props
}: BoxImageProps) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <motion.div
      transition={{}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={classNames(
        "tw-inline-block hover:tw-shadow tw-w-full min-h-100px tw-relative hover:tw-cursor-pointer tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-overflow-hidden",
        {
          "border-primary border-2": isSelected && !isUploading,
          "border-2 tw-animate-pulse": isUploading,
        },
        "border-dashed"
      )}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={src === "add" ? props.onClick : undefined}
      style={{
        background: "var(--kt-app-bg-color)",
        minWidth: 32,
      }}
    >
      {(isShown || isSelected) && showCheck && !isUploading && (
        <div className="tw-absolute tw-top-1 tw-left-1">
          <input
            type="checkbox"
            className="tw-bg-white"
            checked={isSelected}
            onClick={props.onClick}
          />
        </div>
      )}
      {typeof onRemove === "function" && isShown && (
        <div className="tw-absolute tw-top-1 tw-right-1" onClick={onRemove}>
          <div className="tw-px-2 tw-py-1 tw-bg-white tw-shadow-lg hover:tw-shadow tw-rounded-2xl">
            <i className="bi bi-x text-danger"></i>
          </div>
        </div>
      )}
      <div
        {...props}
        className={classNames(
          "tw-inline-flex tw-w-full tw-h-full tw-flex-col tw-justify-start tw-items-start tw-cursor-pointer",
          props.className
        )}
      >
        {(() => {
          if (isUploading) {
            return (
              <div className="tw-relative tw-w-full tw-aspect-square">
                <img
                  src={src}
                  alt={name}
                  className="tw-w-full tw-h-full tw-aspect-square"
                  style={{ objectFit: "cover", filter: "blur(1.5px)" }}
                />
                <div className="tw-absolute tw-top-0 tw-left-0 tw-inline-flex tw-w-full tw-items-center tw-justify-center tw-flex-col tw-gap-3 tw-aspect-square tw-bg-white/[.5]">
                  <div className="spinner-border" role="status" />
                  <div>Uploading...</div>
                </div>
              </div>
            );
          }
          if (src === "add") {
            return (
              <div className="tw-inline-flex tw-w-full tw-h-full tw-cursor-pointer tw-aspect-square tw-items-center tw-justify-center">
                <i
                  className="bi bi-plus"
                  style={{
                    fontSize: "2rem",
                  }}
                ></i>
              </div>
            );
          }
          return (
            <img
              src={src}
              alt={name}
              className="tw-w-full tw-h-full tw-aspect-square"
              style={{ objectFit: "cover" }}
            />
          );
        })()}
      
      </div>
    </motion.div>
  );
};

export default BoxImage;
