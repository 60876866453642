import React from "react";

import axios from "axios";
import classNames from "classnames";
import { cloneDeep, filter, forEach, map, uniqueId } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Slide, toast } from "react-toastify";
import { useDebounce, useLocalStorage, useSetState } from "react-use";
import { v4 } from "uuid";
import { getCommonValues } from "../../utils/typeUltils";

import BoxImage from "./BoxImage";
import { languageMapping } from "../../utils/utils";
import { useSelector } from "react-redux";
import Configuration from "../../configs/Config";
import LightBoxWrapper from "../../layout/lightbox/LighboxWrapper";

const MAX_UPLOAD = 100;
const ITEMS_PER_PAGE = 8;

export type ImageItemType = {
  id: string;
  name: string;
  path: string;
  status: "done" | "uploading" | "error";
  url: string;
  index?: number;
};

const SearchInput = ({ onChange }: { onChange: (searchText: string) => void }) => {
  const [value, setValue] = useState("");

  useDebounce(
    () => {
      onChange(value);
    },
    300,
    [value]
  );

  return (
    <div className="border tw-rounded-3xl tw-mb-5 tw-max-w-xs tw-flex tw-gap-2 tw-items-center tw-px-3">
      <i className="bi bi-search"></i>
      <input
        placeholder="Search..."
        type="text"
        className="form-control form-control-flush !tw-p-2"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {value.length > 0 && (
        <div className="tw-relative tw-cursor-pointer" onClick={() => setValue("")}>
          <div className="tw-absolute -tw-top-3 -tw-right-2 tw-h-5 tw-w-5">
            <i className="bi bi-x"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const WidgetImage = ({ value, onChange, ...props }: any) => {
  /*  const { currentUser } = useAuth();
  const maxFiles = useMemo(() => {
    if (schema.maxItems && schema.maxItems > 0) {
      return schema.maxItems;
    }
    return MAX_UPLOAD;
  }, [schema]); */
  const value00 = useMemo<ImageItemType[]>(() => {
    let value01 = cloneDeep(value);
    if (typeof value === "string") {
      try {
        value01 = JSON.parse(value);
        if (typeof value01 === "string") {
          // For the single value
          value01 = [value01];
        }
      } catch (e) {}
    }

    return map(value01, (item: any, index: number) => {
      const id = item.id || v4();
      let status: string;
      if (item.id === Configuration.guidEmpty || !item.path) {
        status = "error";
      } else if (!item.id) {
        status = "uploading";
      } else {
        status = "done";
      }

      return {
        id,
        index,
        url: `${Configuration.staticDomain}/${item.path}`.replace(/\/\//g, "/"),
        status,
        ...item,
      } as ImageItemType;
    });
  }, [value]);
  const lang = useSelector((state: any) => state.i18n.lang);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const dataSource = (() => {
    const schema00 = props as any;

    if (schema00.dataSource) {
      return schema00.dataSource({
        keyword,
        order: "desc",
        sort: "createdDate",
        languageId: languageMapping[lang],
        limit: ITEMS_PER_PAGE,
        offset: page * ITEMS_PER_PAGE,
        merchantId: getCommonValues(["MerchantId"]).MerchantId,
        type: 256,
        ...(schema00.dataSourceParams || {}),
      });
    }

    return () => ({ isError: true, error: "no dataSource" });
  })();
  const [selected, setSelected] = useSetState<any>({});
  //console.log({selected,finalSelected:map(selected, (v) => v)},"127777")
  const arraySelected = useMemo(() => {
    return map(selected, (v) => v).filter((v) => !!v);
  }, [selected]);
  const modalId = useMemo<string>(() => uniqueId("modal"), []);
  const fileId = useMemo<string>(() => uniqueId("file"), []);

  const [uploading, setUploading] = useSetState({});

  const uploadingCount = useMemo(() => {
    return filter(uploading, (v) => !!v).length;
  }, [uploading]);
  const [items, setItems] = useState<ImageItemType[]>([]);
  const { list } = dataSource;
  const [totalCount, setTotalCount] = useState(0);

  const upload = () => {
    const input = document.getElementById(fileId) as any;
    const files: any[] = input?.files;
    for (const i in files) {
      const file = files[i];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", getCommonValues(["TokenUser"]).TokenUser);
      const tempFilename = nanoid();
      /* //console.log( URL.createObjectURL(file),"1511") */
      setUploading({
        [tempFilename]: {
          name: file.name,
          src: URL.createObjectURL(file),
        },
      });
      toast
        .promise(
          axios
            .post(Configuration.urlApiCommand + "/Image/UploadFile", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              if (typeof data?.Data === "string") {
                data.Data = JSON.parse(data.Data);
              }
              const [uploadedFile] = data.Data || [];
              if (!uploadedFile || !uploadedFile.Path) {
                throw new Error("upload failed");
              }
              return uploadedFile;
            }),
          {
            pending: `Uploading ${file.name}...`,
            success: `${file.name} upload successfully`,
            error: `Upload error: ${file.name}. Please try other file.`,
          },
          {
            theme: "colored",
            position: "bottom-right",
            transition: Slide,
          }
        )
        .then((uploadedFile) => {
          setItems(
            [
              {
                id: uploadedFile.Id,
                name: uploadedFile.Name,
                path: uploadedFile.Path,
                status: "done",
                index: 0,
                url: `${Configuration.staticDomain}/${uploadedFile.Path}`.replace(/\/\//g, "/"),
              } as ImageItemType,
              ...items,
            ]
              .map((v, index) =>
                index < ITEMS_PER_PAGE
                  ? {
                      ...v,
                      index,
                    }
                  : undefined
              )
              .filter((v) => !!v) as any
          );
          setTotalCount(totalCount + 1);
        })
        .finally(() => {
          setUploading({
            [tempFilename]: false,
          });
        });
    }
    input.value = null;
  };

  useEffect(() => {
    if (Array.isArray(list?.data?.images?.items)) {
      setItems(
        map(list?.data?.images?.items, (item: any, index) => ({
          url: `${Configuration.staticDomain}/${item.path}`.replace(/\/\//g, "/"),
          index,
          status: "done",
          ...item,
        })) as any
      );
    }
    if (typeof list?.data?.images?.totalCount === "number") {
      setTotalCount(list?.data?.images?.totalCount);
    }
  }, [list]);
  useEffect(() => {
    const tmpSelected: { [key: string]: ImageItemType } = {};
    forEach(value, (item: ImageItemType) => {
      tmpSelected[item.id] = item;
    });
    if (Object.keys(tmpSelected).length > 0) {
      setSelected(tmpSelected);
    }
  }, [value]);

  const renderModal = () => (
    <div className="modal fade" tabIndex={-1} id={modalId}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5>Choose {props.title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              multiple
              type="file"
              id={fileId}
              style={{ display: "none" }}
              onChange={() => upload()}
              accept="image/*"
            />
            <div
              onClick={() => {
                // @ts-ignore
                document.getElementById(fileId).click();
              }}
              className="tw-mb-8 tw-w-full tw-flex tw-items-center tw-flex-col tw-gap-1 tw-h-[100px] hover:tw-cursor-pointer tw-rounded-lg border-dashed border-2 tw-border-cta tw-flex tw-items-center tw-justify-center"
              style={{
                background: "var(--kt-input-solid-bg)",
              }}
            >
              <i
                className="bi bi-card-image"
                style={{
                  fontSize: "2rem",
                }}
              ></i>
              <div>Choose / Drop images here</div>
            </div>
            <SearchInput
              onChange={(keyword) => {
                setKeyword(keyword);
                setPage(0);
              }}
            />

            <div className="tw-grid tw-grid-cols-4 tw-gap-3">
              {map(uploading, (v: any, k) => {
                if (!v) return null;
                return <BoxImage src={v.src} name={v.name} isUploading={true} key={k} />;
              })}
              {map(items, (image: ImageItemType, index) => {
                const isHidden = index >= items.length - uploadingCount;

                return (
                  <BoxImage
                    className={classNames({
                      "tw-hidden": isHidden,
                    })}
                    showCheck
                    key={image.id}
                    isSelected={selected[image.id]}
                    src={image.url}
                    name={image.name}
                    onClick={() => {
                      if (selected[image.id]) {
                        setSelected({
                          [image.id]: false,
                        });
                      } else {
                        setSelected({
                          [image.id]: image,
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
            <div className="tw-mt-5">
              <ReactPaginate
                previousLabel={<i className="previous"></i>}
                nextLabel={<i className="next"></i>}
                pageCount={Math.ceil(totalCount / ITEMS_PER_PAGE) || 1}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination justify-content-center !tw-flex-nowrap"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                pageRangeDisplayed={2}
                marginPagesDisplayed={4}
                forcePage={page}
                onPageChange={({ selected: page }) => {
                  setPage(page);
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary flex items-center"
              data-bs-dismiss="modal"
              onClick={() => {
                onChange(arraySelected);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {renderModal()}
      <div style={props.style} className="tw-grid tw-grid-cols-3 tw-gap-3 mt-3">
        <BoxImage src="add" data-bs-toggle="modal" data-bs-target={`#${modalId}`} />
        {map(value00, (image, index) => {
          return (
            <BoxImage
              key={image.id}
              src={image.url}
              name={image.name}
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
              onRemove={() => {
                setSelected({
                  [image.id]: false,
                });
                onChange(
                  map(
                    {
                      ...selected,
                      [image.id]: false,
                    },
                    (v) => v
                  ).filter((v) => !!v)
                );
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default WidgetImage;

export const getImages = (formFieldData: any): ImageItemType[] => {
  if (typeof formFieldData === "string") {
    return JSON.parse(formFieldData);
  }
  if (Array.isArray(formFieldData)) {
    return formFieldData;
  }
  return [];
};
