import React from "react";
import { Spinner, Button, Form, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Common from "../../utils/Common";
import { toast } from "react-toastify";

import {
  changePasswordAction,
  clearUserInfoMessageAction
} from "../../actions/User";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        currentPassword: "",
        newPassword: "",
        retypePassword: ""
      },
      validationMessage: "",
      validated: false,
      changePasswordResult: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     changePasswordResult: nextProps.changePasswordResult ? nextProps.changePasswordResult : ''
  //   }
  // }

  onChangeHandler = (value, field) => {
    switch (field) {
      case "currentPassword":
        this.handlerCurrentPassword(value);
        break;
      case "newPassword":
        this.handleNewPassword(value);
        break;
      case "retypePassword":
        this.handleRetypePassword(value);
        break;
      default:
        break;
    }
  };

  handlerCurrentPassword(value) {
    //todo: process upload file
    let { data, validationMessage } = this.state;
    this.setState({
      data: {
        ...data,
        currentPassword: value ? value : ""
      },
      validationMessage: {
        ...validationMessage,
        currentPassword:
          value && value.trim() !== "" ? "" : "Mật khẩu cũ không được để trống"
      }
    });
  }

  handleNewPassword(value) {
    //todo: process upload file
    let { data, validationMessage } = this.state;
    let newPassword =
      value && value.trim() !== "" ? "" : "Mật khẩu mới không được để trống";

    this.setState({
      data: {
        ...data,
        newPassword: value ? value.trim() : ""
      },
      validationMessage: {
        ...validationMessage,
        newPassword: newPassword
      }
    });
  }

  validate;

  handleRetypePassword(value) {
    //todo: process upload file
    let { data, validationMessage } = this.state;
    let currentValidateMesage =
      value && value.trim() !== "" ? "" : "Mật khẩu mới không được để trống";
    if (currentValidateMesage === "") {
      currentValidateMesage =
        value.trim() === data.newPassword
          ? ""
          : "Mật khẩu nhập lại không trùng với mật khẩu mới";
    }
    this.setState({
      data: {
        ...data,
        retypePassword: value ? value.trim() : ""
      },
      validationMessage: {
        ...validationMessage,
        retypePassword: currentValidateMesage
      }
    });
  }

  handleSubmit(event) {
    let { data, validationMessage } = this.state;
    const currentPasswordCheck =
      data.currentPassword && data.currentPassword.trim() !== ""
        ? ""
        : "Mật khẩu cũ không được để trống";
    const newPasswordCheck =
      data.newPassword && data.newPassword.trim() !== ""
        ? ""
        : "Mật khẩu mới không được để trống";
    let retypePasswordCheck =
      data.retypePassword && data.retypePassword.trim() !== ""
        ? ""
        : "Mật khẩu mới không được để trống";
    if (retypePasswordCheck === "") {
      retypePasswordCheck =
        data.retypePassword.trim() === data.newPassword.trim()
          ? ""
          : "Mật khẩu nhập lại không trùng với mật khẩu mới";
    }
    if (!currentPasswordCheck && !newPasswordCheck && !retypePasswordCheck) {
      //console.log("ChangePassword.....");

      let changePasswordDTO = {
        Id: this.props.userInfo.id,
        OldPassword: data.currentPassword,
        Password: data.newPassword,
        ModifiedBy: this.props.userInfo.id,
        ModifiedDate: Common.formatDateTime(new Date())
      };
      this.props.changePasswordAction(changePasswordDTO);
    } else {
      this.setState({
        validationMessage: {
          ...validationMessage,
          currentPassword: currentPasswordCheck,
          newPassword: newPasswordCheck,
          retypePassword: retypePasswordCheck
        }
      });
    }
  }

  renderValidationMessage(message) {
    if (message && message !== "") {
      return (
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      );
    }
  }

  notify(result) {
    result === "Cập nhật thành công"
      ? toast.success(result)
      : toast.error(result);
    this.props.clearUserInfoMessageAction();
  }

  render() {
    let { data, validationMessage } = this.state;
    let { isLoading, changePasswordResult } = this.props;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet kt-portlet--height-fluid">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Đổi mật khẩu</h3>
                </div>
              </div>
              <Form className="kt-form kt-form--label-right">
                <div className="kt-portlet__body">
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                      <Form.Group as={Row} controlId="currentPasword">
                        <Form.Label column xl={3} lg={3}>
                          Mật khẩu hiện tại
                        </Form.Label>
                        <Col lg={9} xl={6}>
                          <Form.Control
                            type="password"
                            placeholder="Mật khẩu hiện tại"
                            value={
                              data.currentPassword ? data.currentPassword : ""
                            }
                            className={
                              validationMessage.currentPassword && "is-invalid"
                            }
                            onChange={event =>
                              this.onChangeHandler(
                                event.target.value,
                                "currentPassword"
                              )
                            }
                          />
                          {this.renderValidationMessage(
                            validationMessage.currentPassword
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="newPassword">
                        <Form.Label column xl={3} lg={3}>
                          Mật khẩu mới
                        </Form.Label>
                        <Col lg={9} xl={6}>
                          <Form.Control
                            type="password"
                            placeholder="Mật khẩu mới"
                            value={data.newPassword ? data.newPassword : ""}
                            className={
                              validationMessage.newPassword && "is-invalid"
                            }
                            onChange={event =>
                              this.onChangeHandler(
                                event.target.value,
                                "newPassword"
                              )
                            }
                          />
                          {this.renderValidationMessage(
                            validationMessage.newPassword
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="retypePassword">
                        <Form.Label column xl={3} lg={3}>
                          Nhập lại mật khẩu mới
                        </Form.Label>
                        <Col lg={9} xl={6}>
                          <Form.Control
                            type="password"
                            placeholder="Nhập lại mật khẩu mới"
                            value={
                              data.retypePassword ? data.retypePassword : ""
                            }
                            className={
                              validationMessage.retypePassword && "is-invalid"
                            }
                            onChange={event =>
                              this.onChangeHandler(
                                event.target.value,
                                "retypePassword"
                              )
                            }
                          />
                          {this.renderValidationMessage(
                            validationMessage.retypePassword
                          )}
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-lg-3 col-xl-3"></div>
                      <div className="col-lg-9 col-xl-9">
                        <Button
                          variant="primary"
                          onClick={this.handleSubmit}
                          disabled={isLoading ? true : false}
                          type="button"
                        >
                          {isLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Đổi mật khẩu
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {changePasswordResult && this.notify(changePasswordResult)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.User.userInfo,
    changePasswordResult: state.User.changePasswordResult,
    isLoading: state.User.isLoading
  };
};
const mapDispatchToProps = {
  changePasswordAction,
  clearUserInfoMessageAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
