import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store, { persistor } from "./app/stores/ConfigureStore";

import "react-datepicker/dist/react-datepicker.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./app/layout/assets/plugins/line-awesome/css/line-awesome.css";
import "./app/layout/assets/plugins/flaticon/flaticon.css";
import "./app/layout/assets/plugins/flaticon2/flaticon.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { registerServiceWorker } from "./register-sw";

const { PUBLIC_URL } = process.env;

registerServiceWorker();

//@ts-ignore
ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
