/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import HomePage from "../pages/home/HomePage";
import { LayoutContextProvider } from "../layout/LayoutContext";
import Layout from "../layout/Layout";
import * as routerHelpers from "./RouterHelpers";

import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import AuthPage from "../pages/auth/AuthPage";

import { Helmet } from "react-helmet";

import Configuration from "../configs/Config";

import "moment/locale/vi"

export const Routes = withRouter(({ history }) => {
 
  const { isAuthorized, menuConfig, userLastLocation, Merchant } = useSelector(
    ({ builder: { menuConfig }, User, Merchant }) => ({
      Merchant,
      menuConfig,
      isAuthorized: User?.userInfo?.id != null,
      userLastLocation: routerHelpers.getLastLocation(),
      auth2: User,
    }),
    shallowEqual
  );
  const merchant = Merchant.merchant;
  // console.log(merchant, "merchant")
  const title = JSON.parse(window.merchant?.data?.metaTag || "")["Title"];
  const metaDesc = JSON.parse(window.merchant?.data?.metaTag || "")["Description"];
  const metaImg = JSON.parse(window.merchant?.data?.metaTag || "")["Image"];
  const metaKeyword = JSON.parse(window.merchant?.data?.metaTag || "")["Keyword"];
  const favicon = JSON.parse(window.merchant?.data?.metaTag || "")["FaviconUrl"];
  const isAuth = useSelector((state) => state.auth.isAuth);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Helmet>
        <title>{title}</title>
        <meta id="page-title" property="og:title" name="title" content={title}/>
        <meta id="page-description" property="og:description" name="description" content={metaDesc}/>
        <meta id="page-keyword" name="keywords" content={metaKeyword}/>
        <meta 
          id="page-image" 
          property="og:image" 
          name="image" 
          content={metaImg}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href={favicon}
        />
      </Helmet>
      <Switch>
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />
        {/* <Layout>
                    <HomePage userLastLocation={userLastLocation} />
                </Layout> */}
        {/* <Route component={ErrorPage6} /> */}
        {!isAuthorized ? (
          <Route component={AuthPage} />  
        ) : (
          <Layout>
            
              <HomePage userLastLocation={userLastLocation} />
          
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
