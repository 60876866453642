import Common from '../utils/Common';
import Config from '../configs/Config';


export const registerDeviceTokenUser = (tokenDTO) => {

  const stringCommandName = 'AddDeviceTokenUser';
  const stringDomain = "User";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(tokenDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  };

  return Common.sendSyncCommand2(dataSend);
};


export const removeDeviceTokenUser = (tokenDTO) => {

  const stringCommandName = 'RemoveDeviceTokenUser';
  const stringDomain = "User";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(tokenDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  };

  return Common.sendSyncCommand2(dataSend);
};

