import React, { useState } from 'react'
import Order from '../order/Order'
import NavbarProfile from './components/NavbarProfile'
import Overview from './overview'
import PointTransaction from './pointTransaction'
import Setting from './setting'
import MyVacation from './vacation'


const Profile = () => {
  const [currentTab, setCurrentTab] = useState("overview")
  return (
    <>
      <NavbarProfile currentTab={currentTab} setCurrentTab={setCurrentTab}/>
      {
        currentTab === "overview" && <Overview setCurrentTab={setCurrentTab} />
      }
      {
        currentTab === "setting" && <Setting />
      }
      {
        currentTab === "pointTransaction" && <PointTransaction />
      }
       {
        currentTab === "order" && <Order />
      }
      {
        currentTab === "vacation" && <MyVacation />
      }
    </>
  )
}

export default Profile