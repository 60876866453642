import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { GetProductVacationDetailAction } from "../../../../actions/Product";
import Configuration from "../../../../configs/Config";
import { Map } from "../../../../partials/Map";
import { t } from "../../../../utils/utils";
import FsLightBox from "fslightbox-react";
import CustomModal from "../../../../partials/modal/Modal";
import Stars from "../../../../partials/Stars";
import { getStarName } from "../list";
import { getProductsVacation } from "../../../../services/ProductService";
import LightBoxWrapper from "../../../../layout/lightbox/LighboxWrapper";

const NavbarDetailAddress = () => {
  const dispatch = useDispatch();
  const { friendlyUrl } = useParams();
  const [toggler, setToggler] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const productVacation = useSelector((state) => state.Product.productVacation);
  // console.log(productVacation, "155");
  useEffect(() => {
    // getProductsVacation({ sizeParams: { from: 0, size: 100 } });
    dispatch(
      GetProductVacationDetailAction({
        friendlyUrl,
        productType: JSON.parse(localStorage.getItem("productType")) || "0",
        sizeParams: {
          from: 0,
          size: 1,
        },
      })
    );
  }, []);
  return (
    <div className="card mb-8">
      {/*       <FsLightBox
        toggler={toggler}
        sources={productVacation?.images?.map(
          (img) => Configuration.staticDomain + img.path
        )}
      /> */}
      <CustomModal
        viewOnly
        title={productVacation?.detailAddress}
        onOk={() => console.log("submit")}
        onHide={() => setVisible(false)}
        visible={visible}
      >
        <Map
          styles={{ width: "100%", height: "400px" }}
          lat={productVacation?.latitude}
          lng={productVacation?.longitude}
          onChange={(latLng) => {
            return (
              new Promise() <
              boolean >
              ((resolve) => {
                resolve(true);
              })
            );
          }}
        />
      </CustomModal>
      <div className="card-body pt-9 pb-0">
        {/*begin::Details*/}
        <div className="d-flex flex-wrap mb-6">
          <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100 h-100 w-lg-150px h-lg-150px me-7 mb-4">
            <LightBoxWrapper
              isStandAlone
              sources={productVacation?.images?.map(
                (img) => Configuration.staticDomain + img.path
              )}
            />
          </div>
          <div className="flex-lg-grow-1 w-100 w-lg-auto">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column w-100 w-lg-auto">
                <div className="d-flex align-items-center mb-1">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                  >
                    {productVacation?.name}
                  </a>
                </div>
                <div
                  onClick={() => setVisible(true)}
                  className="fw-semibold text-hover-primary cursor-pointer mb-4 fs-5 text-gray-400 w-300px w-lg-500px"
                >
                  <i className="fonticon-location fs-3 me-2"></i>
                  <span className=""> {productVacation?.detailAddress}</span>
                </div>
              </div>
              {/* <div className="d-flex align-items-center my-4 me-10">
                <span className="fs-2 me-3">{t("Room Price")} từ :</span>
                <span className="fs-2">
                  <span className="text-primary me-2">
                    {productVacation?.fromPointPrice}
                  </span>
                  {t("Point")}
                </span>
                
              </div> */}
            </div>
            <div className="d-flex flex-wrap ">
              <div className="d-flex flex-wrap w-100 w-lg-auto">
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-0 me-xl-6 w-lg-auto w-100 mb-3">
                  <div className="d-flex mb-2 align-items-center">
                    {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/communication/com011.svg*/}
                    <span className="svg-icon svg-icon-muted svg-icon-1 me-2">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                          fill="currentColor"
                        />
                        <path
                          d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}

                    <div className="fw-semibold fs-6 text-gray-400">Email</div>
                  </div>

                  <div
                    className="fs-4 fw-bold counted"
                    data-kt-countup="true"
                    data-kt-countup-value={75}
                    data-kt-initialized={1}
                  >
                    {productVacation?.email || "N / A"}
                  </div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-0 me-xl-6 w-lg-auto w-100 mb-3">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-muted svg-icon-4 mb-2 me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="#a1a5b7"
                        className="bi bi-telephone-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                        />
                      </svg>
                    </span>
                    <div className="fw-semibold mb-2 fs-6 text-gray-400">
                      {t("Mobile")}
                    </div>
                  </div>

                  <div
                    className="fs-4 fw-bold counted"
                    data-kt-countup="true"
                    data-kt-countup-value={75}
                    data-kt-initialized={1}
                  >
                    {productVacation?.mobile || "N / A"}
                  </div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-0 me-xl-6 w-lg-auto w-100 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="fw-semibold fs-6 text-gray-400">
                      {t("Star Rating")}
                    </div>
                  </div>
                  <Stars value={getStarName(productVacation?.attributes)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*end::Details*/}
        <div className="separator" />
        {/*begin::Nav*/}
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          {/*begin::Nav item*/}
          <li className="nav-item">
            <Link
              className={`nav-link text-active-primary py-5 me-6 ${
                location.pathname ===
                `/product-vacation/detail/list-vacation/${friendlyUrl}`
                  ? "active"
                  : null
              }`}
              to={`/product-vacation/detail/list-vacation/${friendlyUrl}`}
            >
              {t("Vacation")}
            </Link>
          </li>
          {/*end::Nav item*/}
          {/*begin::Nav item*/}
          <li className="nav-item">
            <Link
              className={`nav-link text-active-primary py-5 me-6 ${
                location.pathname ===
                `/product-vacation/detail/information/${friendlyUrl}`
                  ? "active"
                  : null
              }`}
              to={`/product-vacation/detail/information/${friendlyUrl}`}
            >
              {t("Information")}
            </Link>
          </li>
          <li className="nav-item" style={{ marginLeft: "auto" }}>
            <div className="d-flex align-items-center"></div>
          </li>
          {/*end::Nav item*/}
          {/*begin::Nav item*/}

          {/*end::Nav item*/}
          {/*begin::Nav item*/}

          {/*end::Nav item*/}
        </ul>
        {/*end::Nav*/}
      </div>
    </div>
  );
};

export default NavbarDetailAddress;
