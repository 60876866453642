/* eslint-disable react-hooks/rules-of-hooks */

import axios from "axios";
import { toast } from "react-toastify";

import Configuration from "../configs/Config";
import { intl } from "../i18n/I18nProvider";

import { getToastOptions } from "./toast";
import { getCommonValues } from "./typeUltils";

type Props = {};

export type TCommandOptions = {
  domain: string;
  name: string;
  values: object;
  timeout?: number;
};

const sendCommand = async (options: TCommandOptions): Promise<any> => {
  const { domain, name: commandName, values, timeout } = options;
  const body = {
    CommandName: commandName,
    Domain: domain,
    Content: JSON.stringify(values),
    TimeOutSecond: timeout || Configuration.TIMEOUT_COMMAND || 20,
  };

  return await axios.post(Configuration.urlApiCommand + "/Command/SendSync", body);
};

const sendCommandAndAffect = async (options: TCommandOptions, message?: string) => {
  const res = await sendCommand(options);
  //console.log(res, "355");
  if (res.data.Success) {
    toast.success(res.Message || intl("Success"), getToastOptions());
  } else {
    toast.error(res.Message || intl("Some thing went wrong!"), getToastOptions());
  }

  return res;
};

export { sendCommand, sendCommandAndAffect };
