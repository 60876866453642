import React, { Component } from "react";
import { Provider } from "react-redux";
import {Provider as UrqlProvider} from "urql"
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { connect } from "react-redux";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/routers/Routes";
import { LayoutSplashScreen } from "./app/layout/LayoutContext";
import ThemeProvider from "./app/layout/materialUIThemeProvider/ThemeProvider";
import I18nProvider from "./app/i18n/I18nProvider";
import Configuration from "./app/configs/Config";
import { messaging } from "./init-fcm";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import Common from "./app/utils/Common";
import Config from "./app/configs/Config";
import { setAuthSuccessAction } from "./app/actions/Auth";

import {
  getUserInfoAction,
  getTotalPointAction,
  getInformationMemberAction,
} from "./app/actions/User";

import { getMerchantDetailAction } from "./app/actions/Merchant";

import { registerDeviceTokenUserAction } from "./app/actions/FcmMessageToken";
import { actions } from "./app/reducers/i18n";
import { client } from "./app/utils/client";
class App extends Component {
  constructor() {
    super();
    this.state = {
      isValid: "",
      userInfo: "",
    };
    //console.log("App - constructor");
    //console.log("App - constructor: ", window.location.href);
  }

  componentDidUpdate(prevProps) {
    //console.log(this.props);
    // change verifile code call api send email
    if (!prevProps.userInfo?.id && this.props.userInfo?.id) {
      let objData = {
        Id: this.props.userInfo.id,
        DeviceToken: localStorage.getItem(Config.deviceToken),
        UserToken: localStorage.getItem(Config.tokenId),
        MerchantId: localStorage.getItem(Config.merchantId),
        Type: 1,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: this.props.userInfo.id,
        TotalPoint: this.props.totalPoint,
      };
      this.props.registerDeviceTokenUserAction(objData);
      //console.log("App - componentDidUpdate3", prevProps.userInfo);
      // //console.log("App - componentWillReceiveProps", objData);
    }
  }

  async componentDidMount() {    
    const languageId = localStorage.getItem(Config.languageId) || window.config.data.languageId
    localStorage.setItem(Config.languageId,languageId)
    if(!languageId){
      this.props.setLang("vi-VN")
    }
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("tokenId");
    var strReturnUrl = url.searchParams.get("returnUrl");
    //console.log({token,strReturnUrl},"699")
    if (token && strReturnUrl != null) {
      localStorage.setItem(Configuration.tokenId, token);
      localStorage.setItem(Configuration.returnUrl, strReturnUrl);
      localStorage.setItem(Configuration.merchantId, token.substring(0, 36));
      localStorage.setItem(Configuration.deviceToken, this.props.token);
    } else {
      token = localStorage.getItem(Configuration.tokenId);
    }
    if (token) {
      //console.log(window.config.data,"run 74");
      this.props.setLang("vn")
      await this.props.getUserInfoAction(token);
      await this.props.getTotalPointAction(token);
      await this.props.getInformationMemberAction(token, languageId);
      this.props.setAuthSuccessAction();
    }
    await this.props.getMerchantDetailAction();
    //console.log("App - componentDidMount");
  }
  render() {
    const { store, persistor, basename } = { ...this.props };
    //console.log("App- render");
    return (
      /* Provide Redux store */
      <Provider store={store} loading={<LayoutSplashScreen />}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
                {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    <UrqlProvider value={client}>

                    <Routes />
                    </UrqlProvider>

                    {/* Render routes with provided `Layout`. */}
                  </I18nProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    );
  }
}

const mapStateToProp = (state) => ({
  userInfo: state.User.userInfo,
  totalPoint: state.User.totalPoint,
});
const mapDispatchToProps = {
  getUserInfoAction,
  getTotalPointAction,
  getMerchantDetailAction,
  getInformationMemberAction,
  registerDeviceTokenUserAction,
  setAuthSuccessAction,
  setLang:actions.setLanguage
};

const registerPushListener = (pushNotification) =>
  navigator.serviceWorker.addEventListener("message", ({ data }) =>
    pushNotification(
      data.data
        ? data.data.message
        : data["firebase-messaging-msg-data"].data.message
    )
  );

export default compose(
  withState("token", "setToken", ""),
  withState("notifications", "setNotifications", []),
  connect(mapStateToProp, mapDispatchToProps),
  withHandlers({
    pushNotification: ({ setNotifications, notifications }) => (
      newNotification
    ) => setNotifications(notifications.concat(newNotification)),
  }),
  lifecycle({
    async componentDidMount() {
      const { pushNotification, setToken } = this.props;

      messaging
        .requestPermission()
        .then(async function() {
          const token = await messaging.getToken();
          //console.log("token:", token);
          setToken(token);
        })
        .catch(function(err) {
          //console.log("Unable to get permission to notify.", err);
        });

      registerPushListener(pushNotification);
    },
  })
)(App);

// export default connect(mapStateToProp, mapDispatchToProps)(App);
