import Configuration from "../configs/Config";
import axios from "axios";
import Common from "../utils/Common";

import { gql, useQuery } from "urql";

export const queryPaidProduct = gql`
  query PaidProducts(
    $keyword: String = ""
    $limit: Int = 10
    $offset: Int = 0
    $order: String = "desc"
    $sort: String = "createdDate"
    $from: DateTime
    $to: DateTime
    $type: Int = 1
    $tokenUser: String = ""
    $languageId: String = ""
    $merchantId: String = ""
  ) {
    paidproducts(
      param: {
        keyword: $keyword
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        languageId: $languageId
        type: $type
        merchantId: $merchantId
        tokenUser: $tokenUser
        from: $from
        to: $to
      }
    ) {
      totalCount
      message
      success
      items {
        id
        name
        price
        createdDate
        toDate
        fromDate
        addresses {
          name
        }
        images {
          path
        }
      }
      message
      success
      totalCount
    }
  }
`;

export function useQueryPaidProduct({
  keyword = "",
  limit = 10,
  offset = 0,
  order = "desc",
  sort = "createdDate",
  from = undefined,
  to = undefined,
  type = 1,
  languageId = "",
  merchantId = "",
  tokenUser = "",
}) {
  const [list, refetch] = useQuery({
    query: queryPaidProduct,
    variables: (() => {
      return {
        keyword,
        type,
        limit,
        offset,
        order,
        sort,
        from,
        to,
        languageId: languageId || localStorage.getItem(Configuration.languageId),
        merchantId: merchantId || localStorage.getItem(Configuration.merchantId),
        tokenUser: tokenUser || localStorage.getItem(Configuration.tokenId),
      };
    })(),
    requestPolicy: "network-only",
  });

  return [list, refetch];
}

export const getProductsExchangeVacation = (targetId, type) => {
  const languageId = localStorage.getItem(Configuration.languageId);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  let _param = `merchantId:"${merchantId}", published: true, limit: 100, offset: 0 , languageId: "${languageId}",sort:"createdDate" , order:"desc",type:131072,targetId:"${targetId}"`;
  if (type) _param += `,type: ${type}`;
  return Common.getDataGraphQl(`
    {
        listproducts(param:{${_param}}){
          items {
            id
            name
            createdDate
            modifiedDate
          },
          message,
          success,
          totalCount
        }
      }
    `);
};
export const getRentVacationProduct = ({ type, status, to, from, keyword }) => {
  const languageId = localStorage.getItem(Configuration.languageId);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  let _param = `merchantId:"${merchantId}", limit: 10, offset: 0 , languageId: "${languageId}",sort:"createdDate" , order:"desc",keyword:"${keyword}"`;

  if (status) _param += `,status: ${status}`;
  if (from) _param += `,from: ${from}`;
  if (to) _param += `,to: ${to}`;
  return Common.getDataGraphQl(`
    {
      vacationrentproducts(param:{${_param}}){
          items {
            id
        addressName
        name
        price
        status
        productVacationRent {
          fromDate
          toDate
          verifyStatus
        }
      }
      totalCount
      success
      message
        }
      }
    `);
};
export const getPaidProduct = (options) => {
  const merchantId = localStorage.getItem(Configuration.merchantId);
  const tokenId = localStorage.getItem(Configuration.tokenId);
  if (options === undefined) options = {};
  if (options.languageId === undefined)
    options.languageId = localStorage.getItem(Configuration.languageId);

  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.from === undefined) options.from = null;
  if (options.to === undefined) options.to = null;
  let _param = `merchantId:"${merchantId}", limit: 9, offset: ${options.offset} , languageId: "${options.languageId}",sort:"${options.sort}" , order:"${options.order}",keyword:"${options.keyword}",tokenUser:"${tokenId}",type:${options.type}`;

  if (options.from) {
    _param += `,from: "${options.from}"`;
  }
  if (options.to) {
    _param += `,to:"${options.to}"`;
  }
  return Common.getDataGraphQl(`
    {
        paidproducts(param:{${_param}}){
          items {
            id
            name
            price
            createdDate
            toDate
            fromDate
            addresses{
              name
            }
            images{
              path
            }
          },
          message,
          success,
          totalCount
        }
      }
    `);
};

export const getProductExchangeVacation = (id) => {
  const languageId = localStorage.getItem(Configuration.languageId);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  let _param = `id:"${id}",merchantId:"${merchantId}",languageId: "${languageId}"`;

  return Common.getDataGraphQl(`
      {
        product(param:{${_param}}){
          id
          name
          sku
          price
          images {
            id
            path
            isFeatured
          }
          addresses {
            id
            name            
          }
          adultsStandard
          childStandard
          adultsExtra
          childExtra
          childAgeFrom
          childAgeTo
          type
          }
        }
      `);
};
export const getProductsVacation = async ({
  sizeParams = { from: 0, size: 10 },
  filterParams = "",
  dateParams = "",
  keyword = "",
  peopleParams = "",
  sortParam = { order: "asc" },
  friendlyUrl = "",
  productType = "",
  location = "",
}) => {
  // console.log(productType, "191");
  return await axios.post(
    `https://es.izzi.asia/addressresorts/addressresort/_search?from=${sizeParams.from}&size=${sizeParams.size}`,
    {
      _source: { includes: [] },
      query: {
        bool: {
          ...(location && {
            filter: {
              geo_distance: {
                distance: "100km",
                position: { lat: location.lat, lon: location.lon },
              },
            },
          }),
          must: [
            {
              bool: {
                should: [
                  {
                    match_phrase: {
                      merchantId: localStorage.getItem(Configuration.merchantId),
                    },
                  },
                ],
              },
            },
            ...(filterParams && [
              {
                match: {
                  "attributes.attributeValues.keyId": Object.values(filterParams)
                    .flat()
                    .map((filterParam) => filterParam.value)
                    .join(" "),
                },
              },
            ]),

            {
              match: {
                languageId: JSON.parse(localStorage.getItem("persist:i18n")).langId,
              },
            },

            ...(productType && [
              {
                match: {
                  productType,
                },
              },
            ]),            
            ...(keyword && [
              {
                query_string: {
                  default_field: "name",
                  fields: ["friendlyUrl", "name"],
                  query: keyword,
                },
              },
            ]),
            ...(dateParams &&
              dateParams.from && [
                {
                  range: {
                    "products.variants.fromDate": { gte: dateParams.from },
                  },
                },
              ]),
            ...(dateParams &&
              dateParams.to && [
                {
                  range: {
                    "products.variants.toDate": { lt: dateParams.to },
                  },
                },
              ]),
            ...(friendlyUrl && [{ match_phrase: { friendlyUrl } }]),
            ...(peopleParams &&
              peopleParams.adult && [
                {
                  range: {
                    adultsMax: {
                      gte: peopleParams.adult,
                    },
                  },
                },
              ]),
            ...(peopleParams &&
              (peopleParams.child || peopleParams.child === 0) && [
                {
                  range: {
                    childMax: {
                      gte: peopleParams.child,
                    },
                  },
                },
              ]),
          ],
        },
      },
      sort: [{}, { fromPrice: sortParam.order }],
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${window.btoa("amara:dSPKMcdQkG5X97b")}`,
      },
    }
  );
};

export const getPaymentMethods = () => {
  const merchantId = localStorage.getItem(Configuration.merchantId);
  var query = `
  {

    paymentmethods(param: { merchantId: "${merchantId}" }) {
      totalCount
      items {
        type
        id
        name
        displayOrder
        timeout
        sku
        createdBy
        createdDate
        published
        configuration
        imageUrl
      }
    }
  }
    `;
  return Common.getDataGraphQl(query);
};
