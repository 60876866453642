export function getOrderStatusById(allStatus) {
  let status = "";
  let className = "badge-light"
  if ((1 & allStatus) === 1) {
    status = "Chờ xác nhận";
    className = "badge-warning"
  }
  if ((32 & allStatus) === 32) {
    status = "Đang xử lý";
    className = "badge-primary"
  }
  if ((256 & allStatus) === 256) {
    status = "Qúa hạn xử lý";
    className = "badge-dark"
  }
  if ((32768 & allStatus) === 32768) {
    status = "Đã huỷ";
    className = "badge-danger"
  }
  if ((1048576 & allStatus) === 1048576) {
    status = "Đã hoàn thành";
    className = "badge-success"
  }
  return {status,className};
}
