import { ADDRESS } from '../configs/constants/AddressConstant';
import { getAddresses } from '../services/AddressService';

export const getAddressesAction = () => ({
  type: ADDRESS.GET_ADDRESSES,
  payload: getAddresses()
});




