import * as React from "react";
import Configuration from "../../../configs/Config";
import { t, toAbsoluteUrl } from "../../../utils/utils";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavbarProfile({setCurrentTab,currentTab}) {
  const userInfo = useSelector((state: any) => state.User.userInfo);
  const totalPoint = useSelector((state: any) => state.User.totalPoint);
  const userInfoMember = useSelector((state: any) => state.User.userInfoMember);
  const {
    userName,
    name,
    email,
    avatar,
  } = userInfo;
  const location = useLocation();
  //console.log({ userInfo, totalPoint, userInfoMember }, "18ss");
  return (
    <div>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            {/*begin: Pic*/}
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={
                    avatar
                      ? Configuration.staticDomain +
                        avatar +
                        "?mode=crop&width=160&height=160"
                      : toAbsoluteUrl("/media/users/default.jpg")
                  }
                  alt="image"
                />
                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
              </div>
            </div>
            {/*end::Pic*/}
            {/*begin::Info*/}
            <div className="flex-grow-1">
              {/*begin::Title*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::User*/}
                <div className="d-flex flex-column">
                  {/*begin::Name*/}
                  <div className="d-flex align-items-center mb-2">
                    <span className="text-gray-900 fs-2 fw-bold me-1">
                      {name}
                    </span>
                    <span>
                      {/*begin::Svg Icon | path: icons/duotune/general/gen026.svg*/}
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </span>
                  </div>
                  {/*end::Name*/}
                  {/*begin::Info*/}
                  <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                    <span className="d-flex align-items-center text-gray-400 mb-2">
                      {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                      <span className="svg-icon svg-icon-4 me-1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                            fill="currentColor"
                          />
                          <path
                            d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      {email}
                    </span>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::User*/}
                {/*begin::Actions*/}

                {/*end::Actions*/}
              </div>
              <div className="d-flex flex-wrap flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  {/*begin::Stats*/}
                  <div className="d-flex flex-wrap">
                    {/*begin::Stat*/}
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      {/*begin::Number*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                        {totalPoint < 0 ? (
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x={11}
                                y={18}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 18)"
                                fill="black"
                              />
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x={13}
                                y={6}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(90 13 6)"
                                fill="black"
                              />
                              <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        )}

                        <div
                          className="fs-2 fw-bolder counted"
                          data-kt-countup="true"
                          data-kt-countup-value={4500}
                          data-kt-countup-prefix="$"
                        >
                          {totalPoint > 0 ? totalPoint: 0}
                        </div>
                      </div>
                      {/*end::Number*/}
                      {/*begin::Label*/}
                      <div className="fw-bold fs-6 text-gray-400">
                        {t("Available Point")}
                      </div>
                      {/*end::Label*/}
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      {/*begin::Number*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}

                        {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen029.svg*/}
                        <span className="svg-icon svg-icon-warning svg-icon-2hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}

                        {/*end::Svg Icon*/}
                        <div className="fs-2 fw-bolder counted">
                          {userInfoMember}
                        </div>
                      </div>
                      {/*end::Number*/}
                      {/*begin::Label*/}
                      <div className="fw-bold fs-6 text-gray-400">
                        {t("Membership")}
                      </div>
                      {/*end::Label*/}
                    </div>

                    {/*end::Stat*/}
                    {/*begin::Stat*/}

                    {/*end::Stat*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Progress*/}

                {/*end::Progress*/}
              </div>

              {/*end::Title*/}
              {/*begin::Stats*/}

              {/*end::Stats*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Details*/}
          {/*begin::Navs*/}
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {/*begin::Nav item*/}
            <li className="nav-item mt-2">
              <span
                onClick={()=>setCurrentTab("overview")}
                className={`nav-link cursor-pointer text-active-primary ms-0 me-10 py-5 ${
                  currentTab === "overview" ? "active" : null
                }`}
              >
                {t("User information")}
              </span>
            </li>
            {/*end::Nav item*/}

            {/*begin::Nav item*/}
            <li className="nav-item mt-2">
              <span
                onClick={()=>setCurrentTab("vacation")}
                className={`nav-link cursor-pointer text-active-primary ms-0 me-10 py-5 ${
                  currentTab === "vacation"
                    ? "active"
                    : null
                }`}
              >
                {t("My Vacation")}
              </span>
            </li>
            {/*end::Nav item*/}

            {/*begin::Nav item*/}            
            <li className="nav-item mt-2">
              <span 
                onClick={()=>setCurrentTab("pointTransaction")}
                className={`nav-link cursor-pointer text-active-primary ms-0 me-10 py-5 ${
                  currentTab === "pointTransaction"
                    ? "active"
                    : null
                }`}
              >
                {t("Point Transaction History")}
              </span>
            </li>
            {/*end::Nav item*/}            

            {/*begin::Nav item*/}
            <li className="nav-item mt-2">
              <span
                onClick={()=>setCurrentTab("order")}
                className={`nav-link cursor-pointer text-active-primary ms-0 me-10 py-5 ${
                  currentTab === "order"
                    ? "active"
                    : null
                }`}
              >
                {t("Order")}
              </span>
            </li>
            {/*end::Nav item*/}

            {/*begin::Nav item*/}
            <li className="nav-item mt-2">
              <span
                onClick={()=>setCurrentTab("setting")}
                className={`nav-link cursor-pointer text-active-primary ms-0 me-10 py-5 ${
                  currentTab === "setting" ? "active" : null
                }`}
              >
                {t("Settings")}
              </span>
            </li>
            {/*end::Nav item*/}
            
          </ul>
          {/*begin::Navs*/}
        </div>
      </div>
    </div>
  );
}
