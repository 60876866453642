import Common from '../utils/Common';
import Config from '../configs/Config';


export const getNotification = (limit, offset) => {

  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem(Config.tokenId)
  var query = `
  {
    notificationtransactions(param: {limit: ${limit}, offset: ${offset}, order: "desc", sort: "createdDate"
    , tokenUser: "${tokenId}"
    , merchantId: "${merchantId}"}) {
      items {
        id
        message
        urlReturn
        allStatus
        createdDate
      }
      totalCount
      message
    }
  }
    `;
  return Common.getDataGraphQl(query);
};


export const readNotification = (notificationDTO) => {
  const stringCommandName = 'UserReadNotification';
  const stringDomain = "NotificationTransaction";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(notificationDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  };

  return Common.sendSyncCommand3(dataSend);
}

