import React from "react";
import { useSelector } from "react-redux";
import Stars from "../../../../../../partials/Stars";
import { t } from "../../../../../../utils/utils";
import NavbarDetailAddress from "../../../component/NavbarDetailAddress";

const Information = () => {
  const productVacation = useSelector((state) => state.Product.productVacation);
  return (
    <>
      <NavbarDetailAddress />
      <div className="card mb-8">
        <div className="card-body">
          <div
            className="fs-4 text-gray-700"
            dangerouslySetInnerHTML={{ __html: productVacation.description }}
          ></div>
        </div>
      </div>

      <div className="card card-xl-stretch">
        <div className="card-header align-items-center fs-1 fw-bold">
          {t("Resort Information")}
        </div>
        <div className="card-body">
          {productVacation.attributes.map((attribute) => {
            return (
              <div className="row mb-8 gy-4">
                <div className="fs-2 col-md-4 fw-bold">{attribute.name}</div>
                <div className="col-md-8">
                  <div className="row g-10">
                    {attribute.attributeValues.map((attributeValue) => {
                      if (attribute.name === "Hạng sao") {
                        return <Stars value={attributeValue.value} />;
                      }
                      if (attribute.id == "4653c9ac-3fc1-45dd-407b-1506c5f30394") {
                        return <p className="fs-5 col-12" key={attributeValue.attributeId} dangerouslySetInnerHTML={{__html: attributeValue.value}}></p>;
                      }
                      return (
                        <span
                          className="fs-5 col-3"
                          key={attributeValue.attributeId}
                        >
                          {attributeValue.value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Information;
