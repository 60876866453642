import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocalStorage } from "react-use";
import Configuration from "../../../../../../configs/Config";
import Table from "../../../../../../partials/table/Table";
import Common from "../../../../../../utils/Common";
import { t } from "../../../../../../utils/utils";
import { caculationDiscounts } from "../../../component/Functions";
import { getExchangeRage } from "../../../../../../services/MerchantService";

const Product = ({ product, dateParams, loading }) => {
  const [isOpenListVacation, setIsOpenListVacation] = useState(false);
  const [localStoragePeopleParams] = useLocalStorage("peopleParams", {
    adult: 1,
    child: 0,
  });  
  const [productType] = useLocalStorage("productType", "0");
  // console.log(dateParams, "1888");
  // console.log(moment("2022-12-21").isBefore(moment("2022-12-23")), "177");
  const [localStorageChildAges] = useLocalStorage("childAges", []);
  const [exchangeRate, setExchangeRate] = useState(0);
  const history = useHistory();

  const handleCreateOrder = ({ ProductId }) => {
    const orderId = Common.guid();
    Common.sendCommand("Sale", "CreateVacationOrder", {
      Id: orderId,
      MerchantId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      ActionBy: localStorage.getItem(Configuration.userId),
      ProductId,
      AdultTotal: localStoragePeopleParams.adult,
      ChildrenAges: localStorageChildAges,
    }).then((res) => {
      if (res.data.Success) {
        toast.success(t("Create Order Successfully"));
        history.push(`/checkout/${orderId}`);
      } else {
        toast.error(res.data.Message);
      }
    });
  };
  useEffect(() => {
    getExchangeRage().then((res) => {
      const tempExchangeRate = JSON.parse(res.merchant.exchangeRate)
        .ExchangeRatePoint;
      setExchangeRate(tempExchangeRate);
    });
  }, []);
  return (
    <div key={product.id} className="col-xxl-12">
      {/*begin::Card widget 15*/}
      <div className="card card-flush h-xl-100">
        {loading && (
          <div className="spin d-flex justify-content-center align-items-center z-index-3">
            <span
              className="spinner-border text-primary spinner-border-sm align-middle ms-2"
              style={{ width: "3rem", height: "3rem" }}
            ></span>
          </div>
        )}
        {/*begin::Body*/}
        <div className="card-body py-9">
          {/*begin::Row*/}
          <div className="row gx-9 h-100">
            {/*begin::Col*/}
            <div className="col-sm-4 mb-10 mb-sm-0">
              {/*begin::Overlay*/}
              <span className="d-block h-100">
                {/*begin::Image*/}
                <div
                  className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-200px h-100"
                  style={{
                    backgroundImage: `url(${
                      product.images.length > 0
                        ? Configuration.staticDomain + product?.images[0].path
                        : ""
                    })`,
                  }}
                />
                {/*end::Image*/}
                {/*begin::Action*/}

                {/*end::Action*/}
              </span>
              {/*end::Overlay*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="col-sm-8">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column h-100">
                {/*begin::Header*/}
                <div className="mb-7">
                  {/*begin::Title*/}
                  <div className="mb-6">
                    <span className="text-gray-800 fs-1 fw-bold">
                      {product.name}
                    </span>
                  </div>
                  <div className="mb-4">
                    <p className="text-primary fs-3 fw-bold">
                    {t("Price From")}{" "}
                      <span className="fs-1">
                        {Common.formatNumber(product.fromPointPrice)}
                      </span>{" "}{t("point")}
                      / {t("vacation")}
                    </p>
                  </div>
                  {/*end::Title*/}
                  {/*begin::Items*/}
                  <div>
                    <i class="fas fa-user-friends w-30px"></i>
                    {t("Adult")} :{" "}
                    <span className="fw-bolder ms-3">
                      {product.adultsStandard || "N / A"}
                    </span>
                    {"   "}-{"   "}
                    {t("Maximum")} :{" "}
                    <span className="fw-bolder ms-3">
                      {product.adultsMax || "N / A"}
                    </span>
                  </div>
                  <div>
                    <i class="fas fa-child w-30px"></i>
                    {t("Children")} :{" "}
                    <span className="fw-bolder ms-3">
                      {product.childStandard || "N / A"}
                    </span>
                    {"   "}-{"   "}
                    {t("Maximum")} :{" "}
                    <span className="fw-bolder ms-3">
                      {product.childMax || "N / A"}
                    </span>
                  </div>

                  {/*end::Items*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}

                {/*end::Body*/}
                {/*begin::Footer*/}
                <div className="d-flex justify-content-end flex-stack mt-auto bd-highlight">
                  {/*begin::Actions*/}

                  <span
                    onClick={() => setIsOpenListVacation(!isOpenListVacation)}
                    className="btn btn-primary btn-sm flex-shrink-0"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_users_search"
                  >
                    {t("View Detail")}
                  </span>
                  {/*end::Actions*/}
                </div>

                {/*end::Footer*/}
              </div>

              {/*end::Wrapper*/}
            </div>
            {/*end::Col*/}
            {isOpenListVacation && (
              <>
                <div className="fs-2 text-primary mb-5 mt-5">
                  {t("List Vacation")}
                </div>
                <Table
                  disablePagination
                  staticItems={
                    dateParams.from && !dateParams.to
                      ? product.variants?.filter((variant) =>
                          moment(variant.fromDate).isAfter(
                            moment(dateParams.from)
                          )
                        ).filter((variant) =>
                          productType
                            ? variant.productType == productType      
                            : true
                        )
                      : dateParams.from && dateParams.to
                      ? product.variants?.filter(
                          (variant) =>
                            moment(variant.fromDate).isBetween(
                              moment(dateParams.from),moment(dateParams.to)
                            )
                        ).filter((variant) =>
                          productType
                            ? variant.productType == productType      
                            : true
                        )
                      : product.variants
                      .filter((variant) =>
                        productType
                          ? variant.productType == productType      
                          : true
                      )
                  }
                  disableHeader
                  columns={[
                    {
                      dataIndex: "fromDate",
                      title: "Check-in date",

                      render(v) {
                        return moment(v).format("DD-MM-YYYY");
                      },
                    },
                    {
                      dataIndex: "toDate",
                      title: "Check-out date",

                      render(v) {
                        return moment(v).format("DD-MM-YYYY");
                      },
                    },
                    {
                      title: "Number of nights",
                      className: "text-center",
                      render(v, row) {
                        return (
                          <div>
                            {moment(row.toDate).diff(
                              moment(row.fromDate),
                              "days"
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataIndex: "pointPrice",
                      title: "Giá (điểm)",
                      className: "text-center",
                      render(v, row) {
                        if (row.discounts != null && row.discounts.length > 0) {
                          const discountResults = caculationDiscounts(row.discounts, row.price);
                          return (
                            <>
                              <span className="text-primary">{Common.formatNumber(exchangeRate? discountResults.price/exchangeRate : discountResults.price)}<sup>{t("pt")}</sup> </span>
                              <span className=""><sub><s> {Common.formatNumber(exchangeRate? discountResults.oldPrice/exchangeRate : discountResults.oldPrice)}</s></sub> </span>
                            </>
                          )
                        }
                        return <span className="text-primary">{Common.formatNumber(v)} <sup>{t("pt")}</sup> </span>;
                      },
                    },
                    {
                      title: "Actions",
                      className: "text-center",
                      render(v, row) {
                        return (
                          <span
                            onClick={() => {
                              handleCreateOrder({ ProductId: row.id });
                            }}
                            className="text-primary cursor-pointer cursor-p"
                          >
                            {t("Hold Room")}
                          </span>
                        );
                      },
                    },
                  ]}
                />
              </>
            )}
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Card widget 15*/}
    </div>
  );
};

export default Product;
