import { USER } from '../configs/constants/UserConstant';
import {
  getUserInfo,
  getTotalPoint,
  getInformationMember,
  logout,
  updateUserInfo,
  changePassword,
  uploadAvatar
} from '../services/UserService';

export const getUserInfoAction = (token) => ({
  type: USER.GET_USER_INFO,
  payload: getUserInfo(token)
});

export const getTotalPointAction = (token) => ({
  type: USER.GET_POINT,
  payload: getTotalPoint(token)
});

export const getInformationMemberAction = (token, languageId) => ({
  type: USER.GET_INFO_MEMBER,
  payload: getInformationMember(token,languageId)
});

export const updateUserInfoAction = (userInfoUpdate) => ({
  type: USER.UPDATE_USER_INFO,
  payload: updateUserInfo(userInfoUpdate)
});

export const clearUserInfoMessageAction = () => ({
  type: USER.CLEAR_USER_INFO_MESS,
  payload: {}
});


export const changePasswordAction = (changePasswordDTO) => ({
  type: USER.CHANGE_PASSWORD,
  payload: changePassword(changePasswordDTO)
});


export const logoutAction = () => ({
  type: USER.LOGOUT,
  payload: logout()
});


export const uploadAvatarAction = (file) => ({
  type: USER.UPLOAD_AVATAR,
  payload: uploadAvatar(file)
});


