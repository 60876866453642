import * as firebase from "firebase/app";
import "firebase/messaging";




let messaging = null
// tesing 
// if (firebase.messaging.isSupported()) {
//     const initializedFirebaseApp = firebase.initializeApp({
//         apiKey: "AIzaSyDkbYJ9GX7Hceus4zgSHXSWzPUH3cTcW-I",
//         authDomain: "izzi-fnb-test.firebaseapp.com",
//         databaseURL: "https://izzi-fnb-test.firebaseio.com",
//         projectId: "izzi-fnb-test",
//         storageBucket: "izzi-fnb-test.appspot.com",
//         messagingSenderId: "196861513368",
//         appId: "1:196861513368:web:6a37f5ab92c602c344caed",
//         measurementId: "G-6H4QD8B2M6"
//     });

//     messaging = initializedFirebaseApp.messaging();
//     messaging.usePublicVapidKey(
//         "BGl7Mc0_zpZMZLg_MZ3uc7G-Rne6vUp3vCvggHboMOHbilIUCf6qTm5z8EESL87Qgl8RMO0nWxHieiMoFLvZZJI"
//     );
// }


if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyCSp8i6ScnYLy2VZUrQgoPf7ZRdnVwHsb0",
        authDomain: "webnotify-92093.firebaseapp.com",
        databaseURL: "https://webnotify-92093.firebaseio.com",
        projectId: "webnotify-92093",
        storageBucket: "webnotify-92093.appspot.com",
        messagingSenderId: "152240228783",
        appId: "1:152240228783:web:0f4279315a6d79b86a075a",
        measurementId: "G-J78DG35NDR"
    });

    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        "BAjTszEMSlXu41A7A_TtGUpjXeAgUeJ-66EXah6VvyurpP1ZYm3_k7huLjDkAQf6vm3MiV9q5ibAaybYhn_dgZg"
    );
}



export { messaging };
