import classNames from "classnames";
import React from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { t } from "../../utils/utils";

import "./style.scss";

type TProps = {
  loading?: boolean;
  children: any;
  footerProps?: any;
  headerProps?: any;
  visible: boolean;
  onHide?: () => void;
  mainModalProps?: any;
  bodyModalProps?: any;
  title?: string;
  form?: string;
  viewOnly?: boolean;
  onOk?: () => void;
};

const CustomModal = (props: TProps) => {
  const {
    loading = false,
    children,
    form,
    footerProps,
    headerProps,
    visible,
    onHide,
    bodyModalProps,
    mainModalProps,
    title,
    viewOnly,
    onOk,
  } = props;

  return (
    <Modal
      {...mainModalProps}
      dialogClassName={mainModalProps?.size? "modal-dialog-centered":"modal-dialog-centered mw-650px"}
      show={visible}
      onHide={onHide}
    >
      <Modal.Header className="modal-header pb-0 border-0 justify-content-end" {...headerProps}>
        <div onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
      </Modal.Header>
      <Modal.Body
        className={classNames("modal-body scroll-y px-10 px-lg-15 pt-0 pb-15", {
          [`${bodyModalProps?.className}`]: bodyModalProps?.className,
        })}
      >
        {" "}
        {title && (
          <div className="mb-13 text-center">
            {/*begin::Title*/}
            <h1 className="mb-3">{t(title)}</h1>
            {/*end::Title*/}
            {/*begin::Description*/}

            {/*end::Description*/}
          </div>
        )}
        <div className="scroll-y pe-4 ps-4" style={{ maxHeight: "553px" }}>
          {children}
        </div>
        {!viewOnly && (
          <div className="text-center pt-10">
            <button
              onClick={onHide}
              type="reset"
              id="kt_modal_new_target_cancel"
              className="btn btn-light me-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={onOk}
              type="submit"
              id="kt_modal_new_target_submit"
              form={form}
              className="btn btn-primary"
            >
              {!loading && <span className="indicator-label">{t("Save Changes")}</span>}

              <span className={`${!loading && "indicator-progress"}`}>
                {t("Please wait")} ...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        )}
      </Modal.Body>

      {/*   <Modal.Footer {...footerProps}>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={onOk}>
          {t("Save Changes")}
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default CustomModal;
