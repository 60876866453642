import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Common from "../../utils/Common";
import { languageMapping, t, toAbsoluteUrl } from "../../utils/utils";
import ActionTable, { Actions } from "./ActionTable";
import Pagination from "./Pagination";
import { useSetState } from "react-use";

import { Box, TextField } from "@mui/material";
import MyDateRangePicker from "../DatePicker/MyDateRangePicker";
import DateRangePicker from "react-bootstrap-daterangepicker";

import { useIntl } from "react-intl";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";

type Column = {
  title: string;

  className?: string;
  dataIndex?: string;
  render?: (value: any, row: any, index: number) => ReactNode;
};
type ToolbarType = "modal" | "filter";
type ToolbarItem = {
  name?: string;
  type?: ToolbarType;
  component?: ReactNode;
};
type dataSourceParams = {
  keyword?: string;
  order?: string;
  sort?: string;
  limit?: number;
  offset?: number;
  id?: string;
  targetId?: string;
  type?: number;
  languageId?: string;
};

interface IProps {
  dataSource?: any;
  dataSourceParams?: dataSourceParams;
  columns: Column[];
  actions?: Actions[];
  refetch?: boolean;
  disableSearch?: boolean;
  toolbarRender?: ToolbarItem[];
  customCoreTable?: any;
  disableSelectTableLength?: boolean;
  disableHeader?: boolean;
  disablePagination?: boolean;
  staticItems?: any[];
}
const Table = (props: IProps) => {
  const lang = useSelector((state: any) => state.i18n.lang);
  const { formatMessage } = useIntl();
  const [filterParams, setFilterParams] = useSetState<{
    sort: string;
    order: string;
    keyword: string;
    from: any;
    to: any;
  }>({
    sort: "createdDate",
    order: "desc",
    keyword: "",
    from: undefined,
    to: undefined,
  });

  const [keyword, setKeyword] = useState("");
  const [paginateParams, setPaginateParams] = useSetState({
    limit: 10,
    offset: 0,
  });
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const {
    dataSource,
    columns,
    actions,
    disableSearch,
    toolbarRender,
    refetch,
    dataSourceParams,
    customCoreTable,
    disableSelectTableLength,
    disableHeader,
    disablePagination,
    staticItems,
  } = props;
  useEffect(() => {
   

    setLoading(true);
    if (dataSource) {
      dataSource({
        ...filterParams,
        ...paginateParams,
        ...dataSourceParams,
        languageId: languageMapping[lang],
      }).then((res: any) => {
        setLoading(false);
        if (res && (Object.values(res)[0] as any)?.items) {
          setItems((Object.values(res)[0] as any).items);
          setTotalCount((Object.values(res)[0] as any).totalCount);
        } else {
          setItems([]);
        }
      });
    }
    if (staticItems) {
      setItems([...staticItems]);
      setLoading(false)
    }
  }, [filterParams, paginateParams, refetch, lang, staticItems]);
  useEffect(() => {
    //console.log(loading, "1277");
  }, [loading]);
  return (
    <div className="card card-flush position-relative">
      {loading && (
        <div className="spin d-flex justify-content-center align-items-center z-index-3">
          <span
            className="spinner-border text-primary spinner-border-sm align-middle ms-2"
            style={{ width: "3rem", height: "3rem" }}
          ></span>
        </div>
      )}
      {!disableHeader && (
        <div className="card-header z-index-1 align-items-center py-5 gap-2 gap-md-5">
          {!disableSearch && (
            <div className="card-title w-100 w-md-unset">
              <div className="d-flex align-items-center position-relative w-100 w-md-unset my-1">
                <span
                  className="svg-icon svg-icon-1 position-absolute ms-4"
                  onClick={() => setFilterParams({ keyword })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height={2}
                      rx={1}
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <input
                  type="text"
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") setFilterParams({ keyword });
                  }}
                  className="form-control form-control-solid w-md-250px ps-14 w-100"
                  placeholder={formatMessage({ id: "Search" })}
                />
              </div>
            </div>
          )}

          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="input-group w-250px">
              <DateRangePicker
                onCancel={(e, picker) => {
                  picker.element.val("");
                  setFilterParams({
                    from: undefined,
                    to: undefined,
                  });
                }}
                initialSettings={{
                  showDropdowns: true,
                  autoUpdateInput: false,
                  locale: {
                    cancelLabel: true,
                  },
                }}
                onApply={(event, picker) => {
                  picker.element.val(
                    picker.startDate.format("DD/MM/YYYY") +
                      " - " +
                      picker.endDate.format("DD/MM/YYYY")
                  );
                  setFilterParams({
                    from: moment(picker.startDate).format("YYYY-MM-DD"),
                    to: moment(picker.endDate).format("YYYY-MM-DD"),
                  });
                }}
              >
                <input
                  placeholder={formatMessage({ id: "Pick date range" })}
                  className="form-control form-control-solid"
                />
              </DateRangePicker>
            </div>

            {toolbarRender && toolbarRender.map((toolbar) => toolbar.component)}
          </div>
        </div>
      )}

      <div className="card-body z-index-1 pt-0">
        {customCoreTable ? (
          React.cloneElement(customCoreTable, {
            ...props,
            items,
          })
        ) : (
          <div className="table-responsive">
            <table
              className="table align-middle dataTable table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                  {columns.map((column, index) => {
                    return (
                      <th
                        onClick={() => {
                          setFilterParams({
                            order:
                              filterParams.order === "asc" ? "desc" : "asc",
                            sort: column.dataIndex,
                          });
                        }}
                        key={column.title}
                        className={`${
                          column.className
                        } cursor-pointer ${column.dataIndex ===
                          filterParams.sort &&
                          `sorting sorting_${filterParams.order}`}`}
                      >
                        <span>
                          {t(column.title)}
                          {/*   <span className="position-relative">
                          <span
                            onClick={() =>
                              setFilterParams({
                                order: "asc",
                                sort: column.dataIndex,
                              })
                            }
                            className="svg-icon svg-icon-6 position-absolute"
                            style={{marginBottom:"15%"}}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-caret-up"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                            </svg>
                          </span>
                          <span className="svg-icon svg-icon-6 position-absolute" style={{marginTop:"15%"}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-caret-down"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                            </svg>
                          </span>
                        </span> */}
                        </span>
                      </th>
                    );
                  })}
                  {actions && (
                    <th className="min-w-150px text-center">{t("Actions")}</th>
                  )}
                </tr>
              </thead>

              <tbody className="fw-bold text-gray-600">
                {items.length > 0 ? (
                  items.map((item: any, index: number) => {
                    return (
                      <tr key={item.id}>
                        {columns.map((column) => {
                          return (
                            <td key={Common.guid()} className={column.className}>
                              {(column.render &&
                                column.render(
                                  item[column.dataIndex || ""],
                                  item,
                                  index
                                )) ||
                                (column.dataIndex && item[column.dataIndex]) ||
                                "-"}
                            </td>
                          );
                        })}
                        {actions && actions.length > 0 && (
                          <td className="min-w-150px text-center">
                            <ActionTable actions={actions} item={item} />
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length + 1}>
                      <div className="card">
                        {/*begin::Card body*/}
                        <div className="card-body p-0">
                          {/*begin::Wrapper*/}
                          <div className="card-px text-center py-20 my-10">
                            {/*begin::Title*/}

                            {/*end::Title*/}
                            {/*begin::Description*/}
                            <p className="text-gray-400 fs-4 fw-bold mb-10">
                              {t("No data")}.
                            </p>
                            {/*end::Description*/}
                            {/*begin::Action*/}

                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                          {/*begin::Illustration*/}
                          <div className="text-center px-4">
                            <img
                              className="mw-100 mh-300px"
                              alt=""
                              src={toAbsoluteUrl(
                                "theme/media/illustrations/sigma-1/5.png"
                              )}
                            />
                          </div>
                          {/*end::Illustration*/}
                        </div>
                        {/*end::Card body*/}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {!disablePagination && (
          <Pagination
            disableSelectTableLength={disableSelectTableLength}
            paginateParams={paginateParams}
            setPaginateParams={setPaginateParams}
            totalCount={totalCount}
          />
        )}
      </div>
    </div>
  );
};
export default Table;
