import * as React from "react";
import {
  getUserInfoAction,
  uploadAvatarAction,
  updateUserInfoAction,
} from "../../../actions/User";
import { connect } from "react-redux";
import { IUser } from "../../../types/interface";
import NavbarProfile from "../components/NavbarProfile";
import Configuration from "../../../configs/Config";
import { t, toAbsoluteUrl } from "../../../utils/utils";
import { FieldValues, useForm } from "react-hook-form";

import Common from "../../../utils/Common";
import { changePasswordAction } from "../../../actions/User";
import {
  changePassword,
  getOauthUri,
  updateUserInfo,
} from "../../../services/UserService";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { Form } from "react-bootstrap";
import { useSetState } from "react-use";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Axios from "axios";

const formatDateTimeCell = (cellContent) => {
  //console.log(cellContent, "29");
  return cellContent ? new Date(cellContent) : "";
};

function Setting({
  changePasswordAction,
  userInfo,
  updateUserInfoAction,
  uploadAvatarAction,
  getUserInfoAction,
  isUserAvatarLoading,
  updateUserAvatarResult,
  updateUserInfoResult,
  changePasswordResult,
}) {
  //console.log();
  const { register, handleSubmit, errors } = useForm({ mode: "onChange" });
  const {
    id,
    identifyNo,
    images,
    avatar,
    userName,
    dateOfBirth,
    firstName,
    lastName,
    email,
    mobile,
    companyName,
    companySite,
  } = userInfo;
  const [dob, setDob] = React.useState(
    dateOfBirth ? formatDateTimeCell(dateOfBirth) : ""
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    isChangePasswordBtnLoading,
    setIsChangePasswordBtnLoading,
  ] = React.useState(false);
  const [isConfirming, setConfirming] = React.useState(false);
  const [imageList, setImageList] = useSetState({
    avatar: {
      path: "",
      id: "",
      name: "",
    },
    frontImage: {
      path: "",
      id: "",
      name: "",
    },
    backImage: {
      path: "",
      id: "",
      name: "",
    },
  });
  const [isEditingPassword, setIsEditingPassword] = React.useState(false);
  const [state, setState] = useSetState({
    data: {},
    validationMessage: {},
  });
  const avatarFile = React.createRef();

  const handleAvatarChange = (file, type) => {
    if (file) {
      /*  if (file) {
        var reader = new FileReader();
        reader.onload = function(e) {
          setImageList({
            image1: {
              Path: e.target.result,
            },
          });
        };
        reader.readAsDataURL(file);
      } */
      var reader = new FileReader();
      reader.onload = function(e) {
        var img = document.createElement("img");
        img.onload = function(e) {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0);

          var MAX_WIDTH = 800;
          var MAX_HEIGHT = 600;
          var width = img.width;
          var height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          var dataUrl = canvas.toDataURL(file.type);
          if (type === "avatar") {
            setImageList({
              avatar: {
                path: dataUrl,
                id: Common.guid(),
                name: file.name,
              },
            });
          }
          if (type === "frontImage") {
            setImageList({
              frontImage: {
                path: dataUrl,
                id: Common.guid(),
                name: file.name,
              },
            });
          }
          if (type === "backImage") {
            setImageList({
              backImage: {
                path: dataUrl,
                id: Common.guid(),

                name: file.name,
              },
            });
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    /*  //console.log(dataURLtoFile(dataUrl, "test"), "hehee"); */
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    //console.log(arr, "138");
    return new File([u8arr], filename);
  }
  const handleUploadAvatar = async (file) => {
    const tokenId = localStorage.getItem(Configuration.tokenId);
    var bodyFormData = new FormData();
    bodyFormData.append("token", tokenId);
    bodyFormData.append("file", file);
    return await Axios.post(
      Configuration.urlApiCommand + "/Image/UploadFile",
      bodyFormData
    );
  };
  const handleConfirm = () => {
    if (isConfirming) return;
    setConfirming(true);
    getOauthUri(email)
      .then((res) => {
        //console.log(res);
        setConfirming(false);
        if (res.data) {
          window.open(res.data, "_blank");
        }
      })
      .catch((error) => {
        setConfirming(false);
        console.error(error);
      });
  };
  const onSubmit = async ({ firstName, lastName, mobile, identifyNo }) => {
    setIsLoading(true);

    const uploadList = [];
    if (images && images.length > 0) {
      images
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((img) => {
          uploadList.push(img.id);
        });
    }
    //console.log(uploadList, "208");
    if (imageList.avatar.path) {
      const resAvatar = await handleUploadAvatar(
        dataURLtoFile(imageList.avatar.path, imageList.avatar.name)
      );
      uploadList[0] = resAvatar.data.Data[0].Id;
    }
    if (imageList.frontImage.path) {
      const resAvatar = await handleUploadAvatar(
        dataURLtoFile(imageList.frontImage.path, imageList.frontImage.name)
      );
      uploadList[1] = resAvatar.data.Data[0].Id;
    }
    if (imageList.backImage.path) {
      const resAvatar = await handleUploadAvatar(
        dataURLtoFile(imageList.backImage.path, imageList.backImage.name)
      );
      uploadList[2] = resAvatar.data.Data[0].Id;
    }
    //console.log(moment(dob).format("DD-MM-YYYY"), "2399");
    let userInfoUpdate = {
      Id: id,
      FirstName: firstName,
      LastName: lastName,
      CompanyName: companyName,
      CompanySite: companySite,
      Mobile: mobile,
      Email: email,
      DateOfBirth: moment(dob).format("YYYY-MM-DD"),
      ImageId: null,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: id,
      IdentifyNo: identifyNo,
      Images: Common.createRelationObjectArray(
        uploadList
      ).map((item, index) => ({ ...item, IsFeatured: index === 0 })),
    };
    const res = await updateUserInfo(userInfoUpdate);
    if (res.Success) {
      toast.success("Cập nhật thành công");
    } else {
      toast.error(res.Message);
    }
    const tokenId = localStorage.getItem(Configuration.tokenId);
    await getUserInfoAction(tokenId);
    setIsLoading(false);
  };
  const handleUpdatePassword = () => {
    let { data, validationMessage } = state;
    const currentPasswordCheck =
      data.currentPassword && data.currentPassword.trim() !== ""
        ? ""
        : "Mật khẩu cũ không được để trống";
    const newPasswordCheck =
      data.newPassword && data.newPassword.trim() !== ""
        ? ""
        : "Mật khẩu mới không được để trống";
    let retypePasswordCheck =
      data.retypePassword && data.retypePassword.trim() !== ""
        ? ""
        : "Mật khẩu mới không được để trống";
    if (retypePasswordCheck === "") {
      retypePasswordCheck =
        data.retypePassword.trim() === data.newPassword.trim()
          ? ""
          : "Mật khẩu nhập lại không trùng với mật khẩu mới";
    }
    if (!currentPasswordCheck && !newPasswordCheck && !retypePasswordCheck) {
      setIsChangePasswordBtnLoading(true);

      let changePasswordDTO = {
        Id: id,
        OldPassword: data.currentPassword,
        Password: data.newPassword,
        ModifiedBy: id,
        ModifiedDate: Common.formatDateTime(new Date()),
      };
      changePassword(changePasswordDTO).then((res) => {
        if (res.Success) {
          toast.success("Cập nhật mật khẩu thành công");
        } else {
          setState({
            validationMessage: {
              currentPassword: res.Message,
            },
          });
        }
        setIsChangePasswordBtnLoading(false);
      });
    } else {
      setState({
        validationMessage: {
          ...validationMessage,

          currentPassword: currentPasswordCheck,
          newPassword: newPasswordCheck,
          retypePassword: retypePasswordCheck,
        },
      });
      return;
    }
  };
  const handlerCurrentPassword = (value) => {
    //todo: process upload file
    let { data, validationMessage } = state;
    setState({
      data: {
        ...data,
        currentPassword: value ? value : "",
      },
      validationMessage: {
        ...validationMessage,
        currentPassword:
          value && value.trim() !== "" ? "" : "Mật khẩu cũ không được để trống",
      },
    });
  };
  const handleRetypePassword = (value) => {
    //todo: process upload file
    let { data, validationMessage } = state;
    let currentValidateMesage =
      value && value.trim() !== "" ? "" : "Mật khẩu mới không được để trống";
    if (currentValidateMesage === "") {
      currentValidateMesage =
        value.trim() === data.newPassword
          ? ""
          : "Mật khẩu nhập lại không trùng với mật khẩu mới";
    }
    setState({
      data: {
        ...data,
        retypePassword: value ? value.trim() : "",
      },
      validationMessage: {
        ...validationMessage,
        retypePassword: currentValidateMesage,
      },
    });
  };
  const handleNewPassword = (value) => {
    //todo: process upload file
    let { data, validationMessage } = state;
    let newPassword =
      value && value.trim() !== "" ? "" : "Mật khẩu mới không được để trống";

    setState({
      data: {
        ...data,
        newPassword: value ? value.trim() : "",
      },
      validationMessage: {
        ...validationMessage,
        newPassword: newPassword,
      },
    });
  };
  const onChangeHandler = (value, field) => {
    switch (field) {
      case "currentPassword":
        handlerCurrentPassword(value);
        break;
      case "newPassword":
        handleNewPassword(value);
        break;
      case "retypePassword":
        handleRetypePassword(value);
        break;
      default:
        break;
    }
  };

  const notifyAvatar = React.useMemo(() => {
    if (!updateUserAvatarResult) return null;
    return updateUserAvatarResult === "Cập nhật avatar thành công"
      ? toast.success(updateUserAvatarResult)
      : toast.error(updateUserAvatarResult);
  }, [updateUserAvatarResult]);

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          {/*begin::Card title*/}
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t("Profile Details")}</h3>
          </div>
          {/*end::Card title*/}
        </div>
        {/*begin::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_profile_details" className="collapse show">
          {/*begin::Form*/}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form fv-plugins-bootstrap5 fv-plugins-framework"
          >
            {/*begin::Card body*/}
            <div className="card-body border-top p-9">
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  {t("Thumb image")}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  {/*begin::Image input*/}
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/avatars/blank.svg")',
                    }}
                  >
                    {/*begin::Preview existing avatar*/}

                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        backgroundImage: `url(${imageList.avatar.path ||
                          (avatar && Configuration.staticDomain + avatar) ||
                          toAbsoluteUrl("/media/users/default.jpg")})`,
                      }}
                    />
                    {/*end::Preview existing avatar*/}
                    {/*begin::Label*/}
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title=""
                      style={{
                        display: isUserAvatarLoading ? "none" : "flex",
                      }}
                      data-bs-original-title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      {/*begin::Inputs*/}
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        ref={avatarFile}
                        onChange={(event) =>
                          handleAvatarChange(event.target.files[0], "avatar")
                        }
                      />

                      {/*end::Inputs*/}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Cancel*/}

                    {/*end::Cancel*/}
                    {/*begin::Remove*/}

                    {/*end::Remove*/}
                  </div>
                  {/*end::Image input*/}
                  {/*begin::Hint*/}
                  <div className="form-text">
                    Allowed file types: png, jpg, jpeg.
                  </div>
                  {/*end::Hint*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t("Full name")}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  {/*begin::Row*/}
                  <div className="row">
                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        name="firstName"
                        defaultValue={firstName || ""}
                        className="form-control form-control-lg form-control-solid"
                        placeholder="First name"
                        ref={register({
                          required: "First name is required.",
                        })}
                      />
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div className="fv-plugins-message-container invalid-feedback">
                          {errors.firstName && (
                            <div>First name is required</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*begin::Col*/}
                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        name="lastName"
                        defaultValue={lastName || ""}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Last name"
                        ref={register({
                          required: "Last name is required.",
                        })}
                      />
                      <div className="fv-plugins-message-container invalid-feedback">
                        {errors.lastName && <div>Last name is required</div>}
                      </div>
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}

                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Email
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    disabled
                    readOnly
                    type="text"
                    name="email"
                    defaultValue={email || ""}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    ref={register({
                      required: "Email is required.",
                      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.email?.type === "required" && (
                      <div>Email is required</div>
                    )}
                    {errors.email?.type === "pattern" && (
                      <div>The email is not in the correct format </div>
                    )}
                  </div>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t("Customer Mobile")}</span>
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type="tel"
                    name="mobile"
                    defaultValue={mobile || ""}
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("Phone number")}
                    ref={register({
                      required: t("Phone number is required"),
                      pattern: {
                        value: /^([0-9]{10})/,
                      },
                    })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.mobile && <div>{t("Phone number is required")}</div>}
                  </div>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">CMND/CCCD</span>
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type="tel"
                    name="identifyNo"
                    defaultValue={identifyNo || ""}
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("Identify Number")}
                    ref={register({
                      required: t("is a required property"),
                      pattern: {
                        value: /^([0-9]{9})/,
                      },
                    })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.identifyNo && (
                      <div>{t("is a required property")}</div>
                    )}
                  </div>
                </div>
                {/*end::Col*/}
              </div>
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  {t("Date of birth")}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  <DateRangePicker
                    initialSettings={{
                      singleDatePicker: true,
                      startDate: dob
                        ? moment(dob).format("DD/MM/YYYY")
                        : new Date(),
                      showDropdowns: true,

                      locale: {
                        cancelLabel: true,
                      },
                    }}
                    onApply={(event, picker) => {
                      //console.log(
                      //   moment(picker.startDate).format("DD-MM-YYYY"),
                      //   "433"
                      // );

                      setDob(picker.startDate);
                    }}
                  >
                    <input
                      readOnly
                      className="form-control form-control-lg form-control-solid"
                      style={{ width: "40%" }}
                    />
                  </DateRangePicker>

                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                {/*end::Col*/}
              </div>
              
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  {t("Front image")}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  {/*begin::Image input*/}
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/avatars/blank.svg")',
                    }}
                  >
                    {/*begin::Preview existing avatar*/}

                    <div
                      className="image-input-wrapper w-200px h-125px"
                      style={{
                        backgroundImage: `url(${imageList.frontImage.path ||
                          (images?.length > 1 &&
                            images.find((img) => img.displayOrder === 1) &&
                            Configuration.staticDomain +
                              images.find((img) => img.displayOrder === 1)
                                .path) ||
                          toAbsoluteUrl("/media/users/default.jpg")})`,
                      }}
                    />
                    {/*end::Preview existing avatar*/}
                    {/*begin::Label*/}
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title=""
                      style={{
                        display: isUserAvatarLoading ? "none" : "flex",
                      }}
                      data-bs-original-title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      {/*begin::Inputs*/}
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        ref={avatarFile}
                        onChange={(event) =>
                          handleAvatarChange(
                            event.target.files[0],
                            "frontImage"
                          )
                        }
                      />

                      {/*end::Inputs*/}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Cancel*/}

                    {/*end::Cancel*/}
                    {/*begin::Remove*/}

                    {/*end::Remove*/}
                  </div>
                  {/*end::Image input*/}
                  {/*begin::Hint*/}
                  <div className="form-text">
                    Allowed file types: png, jpg, jpeg.
                  </div>
                  {/*end::Hint*/}
                </div>
                {/*end::Col*/}
              </div>
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  {t("Back image")}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  {/*begin::Image input*/}
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/avatars/blank.svg")',
                    }}
                  >
                    {/*begin::Preview existing avatar*/}

                    <div
                      className="image-input-wrapper w-200px h-125px"
                      style={{
                        backgroundImage: `url(${imageList.backImage.path ||
                          (images?.length > 2 &&
                            images.find((img) => img.displayOrder === 2) &&
                            Configuration.staticDomain +
                              images.find((img) => img.displayOrder === 2)
                                .path) ||
                          toAbsoluteUrl("/media/users/default.jpg")})`,
                      }}
                    />
                    {/*end::Preview existing avatar*/}
                    {/*begin::Label*/}
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title=""
                      style={{
                        display: isUserAvatarLoading ? "none" : "flex",
                      }}
                      data-bs-original-title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      {/*begin::Inputs*/}
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        ref={avatarFile}
                        onChange={(event) =>
                          handleAvatarChange(event.target.files[0], "backImage")
                        }
                      />

                      {/*end::Inputs*/}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Cancel*/}

                    {/*end::Cancel*/}
                    {/*begin::Remove*/}

                    {/*end::Remove*/}
                  </div>
                  {/*end::Image input*/}
                  {/*begin::Hint*/}
                  <div className="form-text">
                    Allowed file types: png, jpg, jpeg.
                  </div>
                  {/*end::Hint*/}
                </div>
                {/*end::Col*/}
              </div>
            </div>
            {/*end::Card body*/}
            {/*begin::Actions*/}
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                disabled={isLoading}
                /*   onClick={e=>e.preventDefault()} */
                type="submit"
                className="btn btn-primary d-flex"
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                {t("Save Changes")}
              </button>
            </div>
            {/*end::Actions*/}
            <input type="hidden" />
            <div />
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      <div className="card mb-5 mb-xl-10">
        {/*begin::Card header*/}
        <div className="card-header border-0 cursor-pointer" role="button">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t("Sign-in Method")}</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_signin_method" className="collapse show">
          {/*begin::Card body*/}
          <div className="card-body border-top p-9">
            {/*begin::Email Address*/}
            <div className="d-flex flex-wrap align-items-center">
              {/*begin::Label*/}
              <div id="kt_signin_email">
                <div className="fs-6 fw-bolder mb-1">Email</div>
                <div className="fw-bold text-gray-600">{userName}</div>
              </div>
              {/*end::Label*/}
              {/*begin::Edit*/}

              {/*end::Edit*/}
              {/*begin::Action*/}
              {/*  <div id="kt_signin_email_button" className="ms-auto">
                {!errors.email && (
                  <button
                    onClick={handleConfirm}
                    className="btn btn-light btn-active-light-primary"
                  >
                    {isConfirming && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                      ></span>
                    )}
                    {t("Confirm")} Gmail
                  </button>
                )}
              </div> */}
              {/*end::Action*/}
            </div>
            <div className="separator separator-dashed my-6"></div>
            <div className="d-flex flex-wrap align-items-center mb-10">
              {/*begin::Label*/}
              <div
                id="kt_signin_password"
                className={`${isEditingPassword ? "d-none" : ""}`}
              >
                <div className="fs-6 fw-bolder mb-1">{t("Password")}</div>
                <div className="fw-bold text-gray-600">************</div>
              </div>
              {/*end::Label*/}
              {/*begin::Edit*/}
              <div
                id="kt_signin_password_edit"
                className={`flex-row-fluid ${
                  !isEditingPassword ? "d-none" : ""
                }`}
              >
                {/*begin::Form*/}
                <Form
                  id="kt_signin_change_password"
                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <div className="row mb-1">
                    <div className="col-lg-4">
                      <div className="fv-row mb-0 fv-plugins-icon-container">
                        <Form.Group>
                          <Form.Label
                            htmlFor="currentpassword"
                            className="form-label fs-6 fw-bolder mb-3"
                          >
                            {t("Current password")}
                          </Form.Label>
                          <Form.Control
                            onChange={(event) =>
                              onChangeHandler(
                                event.target.value,
                                "currentPassword"
                              )
                            }
                            value={
                              state.data.currentPassword
                                ? state.data.currentPassword
                                : ""
                            }
                            type="password"
                            className="form-control form-control-lg form-control-solid"
                          />
                        </Form.Group>
                        <div className="fv-plugins-message-container invalid-feedback">
                          {state.validationMessage.currentPassword && (
                            <span>
                              {state.validationMessage.currentPassword}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="fv-row mb-0 fv-plugins-icon-container">
                        <Form.Group controlId="newPassword">
                          <Form.Label
                            htmlFor="newpassword"
                            className="form-label fs-6 fw-bolder mb-3"
                          >
                            {t("New password")}
                          </Form.Label>
                          <Form.Control
                            value={
                              state.data.newPassword
                                ? state.data.newPassword
                                : ""
                            }
                            onChange={(event) =>
                              onChangeHandler(event.target.value, "newPassword")
                            }
                            type="password"
                            className="form-control form-control-lg form-control-solid"
                          />
                        </Form.Group>

                        <div className="fv-plugins-message-container invalid-feedback">
                          {state.validationMessage.newPassword && (
                            <span>{state.validationMessage.newPassword}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="fv-row mb-0 fv-plugins-icon-container">
                        <Form.Group>
                          <Form.Label
                            htmlFor="confirmpassword"
                            className="form-label fs-6 fw-bolder mb-3"
                          >
                            {t("Confirm new password")}
                          </Form.Label>
                          <Form.Control
                            value={
                              state.data.retypePassword
                                ? state.data.retypePassword
                                : ""
                            }
                            type="password"
                            className="form-control form-control-lg form-control-solid"
                            onChange={(event) =>
                              onChangeHandler(
                                event.target.value,
                                "retypePassword"
                              )
                            }
                          />
                        </Form.Group>
                        <div className="fv-plugins-message-container invalid-feedback">
                          {state.validationMessage.retypePassword && (
                            <span>
                              {state.validationMessage.retypePassword}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-text mb-5">
                    Password must be at least 8 character and contain symbols
                  </div>

                  <div className="d-flex">
                    <button
                      disabled={isChangePasswordBtnLoading}
                      type="button"
                      className="btn btn-primary me-2 px-6"
                      onClick={handleUpdatePassword}
                    >
                      {isChangePasswordBtnLoading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                        ></span>
                      )}
                      {t("Update password")}
                    </button>
                    <button
                      onClick={() => setIsEditingPassword(false)}
                      id="kt_password_cancel"
                      type="button"
                      className="btn btn-color-gray-400 btn-active-light-primary px-6"
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                  <div />
                </Form>
                {/*end::Form*/}
              </div>
              {/*end::Edit*/}
              {/*begin::Action*/}
              <div
                id="kt_signin_password_button"
                className={`ms-auto ${isEditingPassword ? "d-none" : ""}`}
              >
                <button
                  onClick={() => setIsEditingPassword(true)}
                  className="btn btn-light btn-active-light-primary"
                >
                  {t("Change password")}
                </button>
              </div>
              {/*end::Action*/}
            </div>

            {/*end::Email Address*/}
            {/*begin::Separator*/}

            {/*end::Notice*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Content*/}
      </div>

      {notifyAvatar}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.User.userInfo,
    updateUserInfoResult: state.User.updateUserInfoResult,
    changePasswordResult: state.User.changePasswordResult,
    updateUserAvatarResult: state.User.updateUserAvatarResult,
    isLoading: state.User.isLoading,
    isUserAvatarLoading: state.User.isUserAvatarLoading,
  };
};

const mapDispatchToProps = {
  updateUserInfoAction,
  uploadAvatarAction,
  getUserInfoAction,
  changePasswordAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
