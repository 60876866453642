
import React, { useEffect, useState } from "react";
import { useDebounce, useSetState } from "react-use";

import { v4 } from "uuid";

declare const goongjs: any;

export type IControlMapProps = {
  lat: number | undefined;
  lng: number | undefined;
  onChange: (latLng: { lat: number; lng: number }) => Promise<boolean>;
  styles?: React.CSSProperties;
};

export function Map(props: IControlMapProps) {
  const [id] = useState(v4());
  const [mapElements, setMapElements] = useSetState<{
    map: any;
    marker: any;
  }>({
    map: null,
    marker: null,
  });

  function _isValidLatLng() {
    return props.lat && props.lng;
  }

  useEffect(() => {
    if (!mapElements.map && _isValidLatLng()) {
      const point = [props.lng, props.lat];
      goongjs.accessToken = "MZU4CfBXyYTC9Cg1h9w5HhHe324zYqCTFNwlni5w";
      const map = new goongjs.Map({
        container: id,
        style: "https://tiles.goong.io/assets/goong_map_web.json", // stylesheet location
        center: point, // starting position [lng, lat]
        zoom: 17, // starting zoom
      });
      const marker = new goongjs.Marker({
        draggable: true,
      });

      marker.setLngLat(point).addTo(map);
      
      setMapElements({
        map,
        marker,
      });
      map.addControl(new goongjs.FullscreenControl());
    }

    if (mapElements.marker) {
      const marker = mapElements.marker as any;
      const _event = () => {
        props
          .onChange({
            ...marker.getLngLat(),
          })
          .then((status) => {
            if (!status) {
              marker.setLngLat([props.lng, props.lat]);
            }
          });
      };

      marker.on("dragend", _event);

      return () => {
        marker.off("dragend", _event);
      };
    }
  }, [props.lat, props.lng, mapElements]);

  return <div id={id} style={{ ...props.styles }} />;
}
