import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useComponentVisible } from "../../utils/customHook";
import { t } from "../../utils/utils";


class User {
  public id?: string
}
const userInstance = new User()
// console.log(Object.getOwnPropertyNames(userInstance),"111")



type ActionType = "navigation";

type routeName = string;

export type Actions = {
  type: ActionType;
  name: string;
  
  render: (item: any) => routeName;
};

interface IProps {
  item: any;
  actions: Actions[];
}

const ActionTable = (props: IProps) => {
  const { item, actions } = props;
  // console.log(item.id, "11");
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible();
  return (
    <>
      <div
        onClick={() => setIsComponentVisible(true)}
        className="btn btn-sm btn-light btn-active-light-primary"
      data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        {t("Actions")}
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
        <span className="svg-icon svg-icon-5 m-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </div>
      <div
        ref={ref}
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 ${isComponentVisible &&
          "show position-absolute"}`}
        data-kt-menu="true"
        data-popper-placement="bottom-end"
      >
        {/*begin::Menu item*/}
        {actions.map((action) => {
          if (action.type === "navigation") {
            return (
              <div className="menu-item px-3" key={action.type}>
                <Link
                  to={{ pathname: action.render(item),state:item }}
                  className="menu-link px-3 text-center"                  
                >
                  {t(action.name)}
                </Link>                
              </div>
            );
          }
          return <div className="menu-item px-3">{action.render(item)}</div>;
        })}
       {/*  <div className="menu-item px-3">
          <Link
            to={{ pathname: `/order/${item.id}` }}
            className="menu-link px-3"
          >
            View
          </Link>
        </div> */}
      
       {/*  <div className="menu-item px-3">
          <Link
            to={{ pathname: `/order/${item.id}` }}
            className="menu-link px-3"
          >
            Edit
          </Link>
        </div>
       
        <div className="menu-item px-3">
          <a
            href="#"
            className="menu-link px-3"
            data-kt-ecommerce-order-filter="delete_row"
          >
            Delete
          </a>
        </div> */}
       
      </div>
    </>
  );
};

export default ActionTable;
