import React from "react";
import Configuration from "../../../configs/Config";
import GridTable from "../../../partials/gridTable";
import Table from "../../../partials/table/Table";
import { getPaidProduct } from "../../../services/ProductService";
import Common from "../../../utils/Common";
import NavbarProfile from "../components/NavbarProfile";

const MyVacation = () => {
  return (
    <Table
      disableSelectTableLength
      dataSource={getPaidProduct}
      dataSourceParams={{ type: Configuration.ProductTypes.VacationExchange}}
      columns={[
        { title: "Name", dataIndex: "name" },
        {
          title: "Price",
          dataIndex: "price",
          render(v) {
            return Common.formatMoney(v);
          },
        },

        {
          title: "Purchase date",
          dataIndex: "createdDate",
          className: "text-center",
          render(v) {
            return Common.formatDateTime(v, "DD-MM-YYYY");
          },
        },
      ]}
      customCoreTable={<GridTable />}
    />
  );
};

export default MyVacation;
