import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Spinner, Button } from "react-bootstrap";
import { getListFileService, getDriveFiles } from "../../services/DriveService";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useSelector } from "react-redux";

import "./style.scss";
import Configuration from "../../configs/Config";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const DriveList = props => {
  const userInfo = useSelector(state => state.User.userInfo);
  const [isLoading, setLoading] = useState(false);
  const merchantId = localStorage.getItem(Configuration.merchantId);

  const [state, setState] = useState({
    page: 1,
    sizePerPage: 10,
    hideSizePerPage: true,
    totalSize: 0,
    offset: 0,
    textSearch: "",
    field: "createdDate",
    sort: "desc",
    data: []
  });

  const refTable = useRef({
    textSearch: "",
    field: "createdDate",
    sort: "desc"
  });

  const handleTextSearch = value => {
    refTable.current.textSearch = value;
  };

  const onSearch = () => {
    const { page, sizePerPage } = state;
    const offset = (page - 1) * sizePerPage;
    loadData({
      limit: state.sizePerPage,
      offset,
      merchantId,
      keyword: refTable.current.textSearch
    });
  };

  const uploadFile = async () => {
    props.history.push("/drive/create");
  };

  const folderId = "1Xb7P22h3Ht9tXdbUOZno_EfdCGNA1KNg"

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await getDriveFiles(userInfo.email, folderId);
      if (response) {
        const name = Object.keys(response)[0];
        setState(() => ({
          data: response[name].DriveDetails
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
      loadData(userInfo.email, folderId);
  }, [userInfo.email]);

  const handleTableChange = (_, { page, sizePerPage }) => {
    const offset = (page - 1) * sizePerPage;
    setState(state => ({
      ...state,
      page: page,
      offset
    }));
    loadData({
      limit: state.sizePerPage,
      offset,
      merchantId,
      keyword: refTable.current.textSearch
    });
  };

  const columns = useMemo(() => {
    const columeStyle = { verticalAlign: "middle" };
    return [
      {
        text: "Tên File",
        dataField: "FileName",
        style: { ...columeStyle, maxWidth: 250, wordBreak: "break-word" },
        headerStyle: { maxWidth: 250 },
        headerAlign: (column, colIndex) => "center",
        align: "left"
      },
      {
        text: "Trạng thái",
        dataField: "published",
        style: { ...columeStyle, width: 150 },
        headerStyle: { width: 150 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
        attrs: {
          valign: "middle"
        },
        formatter: (_, row) => {
          return row.published ? (
            <Badge variant="success">Phát hành</Badge>
          ) : (
            <Badge variant="warning">Chưa phát hành</Badge>
          );
        }
      },
      {
        dataField: "WebViewLink",
        text: "Đường dẫn",
        align: "left",
        style: { ...columeStyle, wordBreak: "break-word" },
        headerAlign: (column, colIndex) => "center",
        formatter: (_, row) => {
          try {
            return (
              <a href={row.WebViewLink} target="_blank" rel="noopener noreferrer">
                {row.WebViewLink}
              </a>
            );
          } catch {
            return row.WebViewLink;
          }
        }
      },
      {
        text: "Thao tác",
        style: { ...columeStyle, width: 100 },
        headerStyle: { width: 100 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
        formatter: (_, row) => {
          return (
            <Link to={`/drive/edit/${row.id}`}>
              <Button variant="primary" size="sm">
                Sửa
              </Button>
            </Link>
          );
        }
      }
    ];
  }, []);

  const { data } = state;

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
      <div className="row">
        <div className="col-xl-12">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Danh sách File</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-sm-9 col-md-7 my-6 my-md-0 drive-filter">
                  <div className="input-icon">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      id="kt_datatable_search_query"
                      onChange={event => handleTextSearch(event.target.value)}
                    />
                  </div>
                  <div className="drive-filter-button">
                    <Button
                      variant="primary"
                      onClick={onSearch}
                      disabled={isLoading}
                      type="button"
                    >
                      {isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Tìm kiếm
                    </Button>
                  </div>
                </div>
                <div className="col-sm-3 col-md-5 drive-upload">
                  <button
                    className={`btn btn-success btn-upload `}
                    onClick={uploadFile}
                  >
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    &nbsp; Upload
                  </button>
                </div>
              </div>
              <div className="kt-separator kt-separator--dashed"></div>
              <div className="row">
                <div className="table-responsive-xl">
                  <BootstrapTable
                    remote={{ pagination: true, filter: false, sort: false }}
                    loading={isLoading}
                    wrapperClasses="uk-table uk-table-striped drive-table"
                    keyField="id"
                    data={data}
                    columns={columns}
                    pagination={paginationFactory({ ...state })}
                    onTableChange={handleTableChange}
                    striped
                    hover
                    condensed
                    noDataIndication="Không có dữ liệu."
                    overlay={overlayFactory({
                      spinner: true
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveList;
