import React, { useEffect, useState } from "react";
import { useSetState } from "react-use";

const Pagination = ({
  totalCount,
  setPaginateParams,
  paginateParams,
  disableSelectTableLength,
}) => {
  const [pages, setPages] = useState([]);
  const [valuePage, setValuePage] = useSetState({
    truncate: false,
    curPage: 1,
    numLinksTwoSide: 1,
  });

  const fetchDataOffset = () => {
    setPaginateParams({
      offset: paginateParams.limit * (valuePage.curPage - 1),
    });
  };
  const handleChangePage = (type) => {
    if (type === "prev" && valuePage.curPage !== 1) {
      setValuePage({ curPage: valuePage.curPage - 1 });
    }
    if (type === "next" && valuePage.curPage !== pages.length) {
      setValuePage({ curPage: valuePage.curPage + 1 });
    }
  };
  const calCulatePagination = () => {
    const truncateRange = 5;
    const numberOfPage = Math.ceil(totalCount / paginateParams.limit);

    const tempPages = Array.from({ length: numberOfPage }, (_, i) => i + 1);
    let truncatedPages = [];

    const isTruncateRight =
      valuePage.curPage < truncateRange && valuePage.curPage >= 1;
    const isTruncateLeft =
      valuePage.curPage > tempPages.length - truncateRange + 1;
    const isTruncateMiddle = !isTruncateLeft && !isTruncateRight;

    if (tempPages.length > 0 && tempPages.length >= 10) {
      tempPages.forEach((page) => {
        if (
          isTruncateRight &&
          (page <= truncateRange || page === tempPages.length)
        ) {
          truncatedPages.push(page);
        }

        if (
          isTruncateLeft &&
          (page > tempPages.length - truncateRange || page === 1)
        ) {
          truncatedPages.push(page);
        }
        if (
          isTruncateMiddle &&
          ((page >= valuePage.curPage - 1 && page <= valuePage.curPage + 1) ||
            page === 1 ||
            page === tempPages.length)
        ) {
          truncatedPages.push(page);
        }
      });
      if (truncatedPages.length > 0) {
        if (isTruncateRight) {
          truncatedPages.splice(truncateRange, 0, "...");
        }
        if (isTruncateLeft) {
          truncatedPages.splice(
            truncatedPages.length - truncateRange,
            0,
            "..."
          );
        }
        if (isTruncateMiddle) {
          truncatedPages.splice(1, 0, "...");
          truncatedPages.splice(truncatedPages.length - 1, 0, "...");
        }
      }
    }

    setPages(tempPages.length >= 10 ? truncatedPages : tempPages);
  };
  useEffect(() => {
    fetchDataOffset();
  }, [valuePage]);
  useEffect(() => {
    calCulatePagination();
  }, [totalCount, paginateParams]);
  return (
    <div className="row">
      {!disableSelectTableLength && (
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          <div
            className="dataTables_length"
            id="kt_ecommerce_sales_table_length"
          >
            <label>
              <select
                onChange={(e) => setPaginateParams({ limit: e.target.value })}
                name="kt_ecommerce_sales_table_length"
                aria-controls="kt_ecommerce_sales_table"
                className="form-select form-select-sm form-select-solid"
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </label>
          </div>
        </div>
      )}
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="kt_ecommerce_sales_table_paginate"
        >
          <ul className="pagination">
            <li
              onClick={() => handleChangePage("prev")}
              className={`paginate_button page-item previous cursor-pointer ${
                valuePage.curPage === 1 ? "disabled" : ""
              }`}
              id="kt_ecommerce_sales_table_previous"
            >
              <span className="page-link">
                <i className="previous" />
              </span>
            </li>
            {pages.map((page, index) => {
              if (page === "...") {
                return (
                  <li key={index} className="page-item">
                    <span className="page-link">...</span>
                  </li>
                );
              }

              return (
                <li
                  key={index}
                  onClick={() => {
                    setValuePage({ curPage: page });
                  }}
                  className={`paginate_button page-item cursor-pointer ${
                    page === valuePage.curPage ? "active" : ""
                  }`}
                >
                  <span className="page-link">{page}</span>
                </li>
              );
            })}

            <li
              onClick={() => handleChangePage("next")}
              className={`paginate_button page-item next cursor-pointer ${
                valuePage.curPage === pages.length ? "disabled" : ""
              }`}
              id="kt_ecommerce_sales_table_next"
            >
              <span className="page-link">
                <i className="next" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
