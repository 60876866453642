import Configuration from "../configs/Config";
import Common from "../utils/Common";

export const getNavigation = (id) => {
    const languageId = localStorage.getItem(Configuration.languageId);
    const merchantId = localStorage.getItem(Configuration.merchantId);
    const tokenId = localStorage.getItem(Configuration.tokenId);
    var query = `
    {
        navigation(param:{id:"${id}",tokenUser:"${tokenId}",languageId:"${ languageId}",merchantId:"${merchantId}"})
       {
            id,
            name,
            description
            navigation
        } 
       }
     
    `
    return Common.getDataGraphQl(query);
  };

  export const getMembershipMenuNavigations = ({languageId}) => {

    const merchantId = localStorage.getItem(Configuration.merchantId);
    const tokenId = localStorage.getItem(Configuration.tokenId);
    var query = `
    {
      membershipmenunavigations(param:{        
        tokenUser:"${tokenId}"
        ,merchantId:"${merchantId}"
        ,languageId:"${languageId}"
       })
       {
        items {
          id
          name
          navigation
        }
      }
    }
    `;
    return Common.getDataGraphQl(query);
};
