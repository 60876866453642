import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Configuration from "../../configs/Config";
import { getMerchantLogo } from "../../utils/MerchantUtils";
import { toAbsoluteUrl } from "../../utils/utils";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import OldLogin from "./OldLogin";
import Registration from "./Registration";

const AuthPage = ({ merchant }) => {
  const logoDark = JSON.parse(window.merchant?.data?.configuration || "")["DarkLogoPath"];
  // const logoPath = merchant && merchant.images?.length > 0 ? merchant.images[0].path : "";
  //console.log(merchant,"13")
  return (
    <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
      {/*begin::Authentication - Sign-in */}
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: "url(assets/media/illustrations/sigma-1/14.png",
        }}
      >
        {/*begin::Content*/}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/*begin::Logo*/}
          <Link to="/" className="mb-12">
            <img
              alt="Logo"
              src={
                (logoDark!= undefined && logoDark != "")? logoDark + "?mode=crop&height=100"
                  : Configuration.logoDefault
              }              
            />
          </Link>
          {/*end::Logo*/}
          {/*begin::Wrapper*/}
          <Switch>
                  <Route path="/auth/login" component={Login} />
                  {/* <Route path="/auth/registration" component={Registration} /> */}
                  <Route
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
          {/*end::Wrapper*/}
        </div>
        {/*end::Content*/}
        {/*begin::Footer*/}
     
        {/*end::Footer*/}
      </div>
      {/*end::Authentication - Sign-in*/}
    </div>
  );
};
const mapStateToProps = store => ({
    merchant: store.Merchant.merchant
  });
  
  export default connect(mapStateToProps)(AuthPage);
