import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import Select from "react-select";
import CustomModal from "../../partials/modal/Modal";
import { t } from "../../utils/utils";
import { Box, Grid, Paper } from "@mui/material";
import Table from "../../partials/table/Table";
import Common from "../../utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { useSetState } from "react-use";
import Configuration from "../../configs/Config";
import Axios from "axios";
import { getAddressesAction } from "../../actions/Address";
import { getExchangeRage } from "../../services/MerchantService";
import {
  getProductExchangeVacation,
  getProductsExchangeVacation
} from "../../services/ProductService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { getDeposits } from "../../services/OrderService";
import { getCurrentLanguageId } from "../../utils/utils";
import moment from "moment";
import { getConversionRate, depositStatus, dataURLtoFile, handleUploadAvatar } from "./Functions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";


const Deposit = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const addressIdRef = useRef();
  const productIdRef = useRef();
  const checkInDateRef = useRef();
  const checkOutDateRef = useRef();
  const referenceCodeRef = useRef();
  const image1Ref = useRef();
  const image2Ref = useRef();
  const image3Ref = useRef();
  const { formatMessage } = useIntl();

  const addresses = useSelector(state => state.Address.addresses);

  const [key, setKey] = useSetState({
    key1: 1,
    key2: 1
  });
  const [errorList, setErrorList] = useState({});
  const [formState, setFormState] = useSetState({
    addressId: "",
    productId: "",
    referenceCode: "",
    note: "",
    price: 0
  });
  const [numberOfNights, setNumberOfNights] = useState(3);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [depositedInAdvance, setDepositedInAdvance] = useState(0);
  const [date, setDate] = useSetState({
    checkInDate: "", 
    checkOutDate: ""
  });

  const [productsExchange, setProductsExchange] = useState([]);
  const [imageList, setImageList] = useSetState({
    image1: {
      Id: Common.guid(),
      Path: "",
      Name: ""
    },
    image2: {
      Id: Common.guid(),
      Path: "",
      Name: ""
    },
    image3: {
      Id: Common.guid(),
      Path: "",
      Name: ""
    }
  });
  const [productExchange, setProductExchange] = useState({});
  
  const uploadAvatar = (file, type) => {
    if (file) {      
      var reader = new FileReader();
      reader.onload = function(e) {
        var img = document.createElement("img");
        img.onload = function(e) {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = 750;
          var MAX_HEIGHT = 1000;
          var width = img.width;
          var height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          var dataUrl = canvas.toDataURL(file.type);
          if (type === "image1") {
            setImageList({
              image1: {
                Id: Common.guid(),
                Path: dataUrl,
                Name: file.name
              }
            });
          }
          if (type === "image2") {
            setImageList({
              image2: {
                Id: Common.guid(),
                Path: dataUrl,
                Name: file.name
              }
            });
          }
          if (type === "image3") {
            setImageList({
              image3: {
                Id: Common.guid(),
                Path: dataUrl,
                Name: file.name
              }
            });
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClearForm = () => {
    setErrorList({});
    setImageList({
      image1: {
        Id: "",
        Path: ""
      },
      image2: {
        Id: "",
        Path: ""
      },
      image3: {
        Id: "",
        Path: ""
      }
    });
    setDate({
      checkInDate: "",
      checkOutDate: ""
    });
    setFormState({
      addressId: "",
      note: "",
      productId: "",
      referenceCode: ""
    });
    setProductsExchange([]);
    setProductExchange({});
  };
  const isValidate = listChecking => {
    let err = false;
    listChecking.forEach(item => {
      // console.log(item, "2250");
      if (item.error) {
        item.ref.current.scrollIntoView();
        setErrorList(oldErrorList => {
          return {
            ...oldErrorList,

            [item.id]: item.message || t("is a required property")
          };
        });
        err = false;
      } else {
        err = true;
      }
    });
    if (listChecking[0].error) {
      listChecking[0].ref.current.scrollIntoView();
    }
    return err;
  };
  const handleSubmit = async () => {
    if (
      !isValidate([
        {
          error: !formState.addressId,
          message: undefined,
          ref: addressIdRef,
          id: "addressId"
        },
        {
          error: !formState.productId,
          message: undefined,
          ref: productIdRef,
          id: "productId"
        },
        {
          error: !date.checkInDate,
          message: undefined,
          ref: checkInDateRef,
          id: "checkInDate"
        },
        {
          error: !date.checkOutDate,
          message: undefined,
          ref: checkOutDateRef,
          id: "checkOutDate"
        },
        {
          error: !formState.referenceCode,
          message: undefined,
          ref: referenceCodeRef,
          id: "referenceCode"
        },
        {
          error: !imageList.image1.Path,
          message: undefined,
          ref: image1Ref,
          id: "avatar1"
        },
        {
          error: !imageList.image2.Path,
          message: undefined,
          ref: image2Ref,
          id: "avatar2"
        },
        {
          error: !imageList.image3.Path,
          message: undefined,
          ref: image3Ref,
          id: "avatar3"
        }
      ])
    )
      return;
    const uploadList = [];
    if (imageList.image1.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(imageList.image1.Path, imageList.image1.Name))
      );
    }
    if (imageList.image2.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(imageList.image2.Path, imageList.image2.Name))
      );
    }
    if (imageList.image3.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(imageList.image3.Path, imageList.image3.Name))
      );
    }
    Promise.all(uploadList).then(async uploadRes => {
      const values = {
        Id: Common.guid(),
        OwnerId: localStorage.getItem("userId"),
        DepositProductId: formState.productId,
        Images: Common.createRelationObjectArray(
          uploadRes.map(resItem => resItem.data.Data[0]?.Id)
        ),
        FeeCategories: Common.createRelationObjectArray(['7fadd9da-0586-ac3a-a41c-0f15c29d84c9']),
        CutOfDate: 15,
        CutOfDateFeeCategoryIds: ['735afa27-5c95-b54e-c1f6-054870cc7877'],
        PromotionId: "74364bea-07d9-4f6e-927a-c64d7b0bf0a5",
        Price: conversionRate && productExchange.price && numberOfNights && exchangeRate? 
                parseInt((((conversionRate + 0.1)*productExchange.price*numberOfNights)/exchangeRate).toFixed(0)) * exchangeRate : 0,
        OriginalPrice: conversionRate && productExchange.price && numberOfNights? conversionRate*productExchange.price*numberOfNights: 0,
        FromDate: date.checkInDate,
        ToDate: date.checkOutDate,
        ReferenceCode: formState.referenceCode,
        Note: formState.note,
        CreatedDate: Common.formatDateTime(new Date()),
        CreatedBy: localStorage.getItem("userId")
      };
      setLoading(true);
      const res = await Common.sendCommand("Sale", "CreateVacationExchangeDepositProduct", values);
      if (res.data?.Success) {
        toast.success(t("Success"));
        setVisible(false);
        handleClearForm();
      } else {
        toast.error("Something is wrong");
        setVisible(false);
        handleClearForm();
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    dispatch(getAddressesAction());

    getExchangeRage().then((res) => {
      const tempExchangeRate = JSON.parse(res.merchant.exchangeRate)
        .ExchangeRatePoint;
      setExchangeRate(tempExchangeRate);
    });
  }, []);
  

  useEffect(() => {
    if (formState.addressId) {      
      getProductsExchangeVacation(formState.addressId).then(res => {
        if (res.listproducts?.items.length > 0) {
          setProductsExchange(res.listproducts.items)
        } else {          
          setProductsExchange([]);
        }
      });
    }
  }, [formState.addressId]);

  useEffect(() => {
    if (formState.productId) {
      getProductExchangeVacation(formState.productId).then(res => {
        if (res.product) {
          setProductExchange({ ...res.product });
        }
      });
    }
  }, [formState.productId, formState.addressId]);

  return (
    <>
      <CustomModal
        loading={loading}
        onOk={handleSubmit}
        title="Add New Deposit"
        visible={visible}
        onHide={() => {
          setVisible(false);
          handleClearForm();
        }}
        mainModalProps={{size: "lg"}}
      >        
        {/*begin::Input group*/}
        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
          {/*begin::Label*/}
          <label ref={addressIdRef} className="d-flex align-items-center fs-5 fw-bolder mb-2">
            <span className="required fs-5 fw-bolder mb-2">{t("Resort")}</span>
          </label>
          {/*end::Label*/}
          {/*begin::Select*/}
          <Select    
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '1.1rem',
                fontWeight: '500',
                backgroundColor: state.isFocused ? '#eef3f7' : '#f5f8fa',
                transition: 'color .2s ease,background-color .2s ease',
                borderColor: state.isFocused ? '#eef3f7' : '#f5f8fa',
              }), 
              valueContainer:  (baseStyles) => ({
                ...baseStyles,
                padding: '6.75px 11px',
              }), 
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: '#5e6278',
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                color: '#5e6278',
              }),
            }}   
            // className="form-select form-select-solid"
            placeholder={formatMessage({ id: "Select resort" })}
            //value={formState.addressId}
            onChange={e => {
              //console.log(e)
              setProductExchange({});
              setFormState({ addressId: e.value, productId: "" });
            }}
            options={addresses.map((value) => ({
              label: value.name,
              value: value.id,
            }))}
          />

          {/*end::Select*/}
          <div className="fv-plugins-message-container invalid-feedback">
            {!formState.addressId && <span>{errorList.addressId}</span>}
          </div>
        </div>        
        
        <div className="row g-6 mb-5">
          <div className="col-lg-9 col-md-8 fv-row fv-plugins-icon-container">
            <label ref={productIdRef} className="d-flex align-items-center fs-5 fw-bolder mb-2">
              <span className="required">{t("Room Type")}</span>
            </label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '1.1rem',
                  fontWeight: '500',
                  backgroundColor: state.isFocused ? '#eef3f7' : '#f5f8fa',
                  transition: 'color .2s ease,background-color .2s ease',
                  borderColor: state.isFocused ? '#eef3f7' : '#f5f8fa',
                }), 
                valueContainer:  (baseStyles) => ({
                  ...baseStyles,
                  padding: '6.75px 11px',
                }), 
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: '#5e6278',
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: '#5e6278',
                }),
              }}  
              // value={formState.productId}
              onChange={e => setFormState({ productId: e.value })}
              name="productId"
              placeholder={formatMessage({ id: "Select room class" })}
              // className="form-select form-select-solid"
              options={productsExchange &&
                productsExchange?.length > 0 ?
                productsExchange.map(product => ({
                label: product.name,
                value: product.id,
              })) : []}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {!formState.productId && <span>{errorList.productId}</span>}
            </div>
          </div>
          <div className="col-lg-3 col-md-4 fv-row fv-plugins-icon-container">
            <label className="d-flex align-items-center fs-5 fw-bolder mb-2">
              <span>{t("Number of nights")}</span>
            </label>
            <select
              value={numberOfNights}
              onChange={e => {
                setDate({
                  checkInDate: "",
                  checkOutDate: ""
                });
                setKey({
                  key1: Common.guid(),
                  key2: Common.guid()
                });
                setNumberOfNights(e.target.value);
                setDepositedInAdvance(0);
                setConversionRate(0);
              }}
              className="form-select form-select-solid"
            >
              {[3, 4, 7].map(num => (
                <option value={num} key={num}>
                  {num} đêm
                </option>
              ))}
            </select>
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
        </div>
          
        {productExchange.id? (
          <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
            {/*begin::Label*/}
            <label className="d-flex align-items-center fs-5 fw-bolder mb-2">
              {t("Resort info")}
            </label>
            {/*end::Label*/}
            {/*begin::Select*/}            
            <div className="notice bg-light-primary rounded border-primary border border-dashed p-6">            
              {/*begin::Wrapper*/}
              <div className="row g-6">
                <div className="col-md-3">
                  {productExchange?.images?.length > 0? (
                    <img
                      className="w-100 card-rounded"
                      alt="Resort images"
                      src={Configuration.staticDomain + productExchange.images[0].path + "?mode=crop&width=600&height=400" }
                    />
                  ):(
                    <img
                      className="w-100 card-rounded"
                      alt="Resort images"
                      src={Configuration.imageNotAvailable +"?mode=crop&width=450&height=300" }
                    />
                  )}
                  
                </div>
                <div className="col-md-9">
                  <h4>{productExchange.name || ""}</h4>
                  <div className="">
                    {t("Số khách lưu trú")}:
                    {productExchange.adultsStandard? (
                      <>
                        <i className="fas fa-male ms-3"></i> x <span className="fw-bolder">{productExchange.adultsStandard || "0"}</span>            
                        <i className="fas fa-child ms-3"></i> x <span className="fw-bolder">{productExchange.childStandard || "0"}</span>
                      </>
                    ) : (
                      <span className="ms-3">-</span>
                    )}              
                    {productExchange.childStandard? (
                      <span className="ms-3">({productExchange.childAgeFrom || "0"} - {productExchange.childAgeTo || ""})</span>
                    ) :""}
                  </div>
                  <div>
                    {t("Deposited in advance")}:
                    {depositedInAdvance? (
                      <span className="ms-3 fw-bolder">{depositedInAdvance.toFixed(1)} {t("Month")}</span>
                    ) :(
                      <span className="ms-3">-</span>
                    )}
                  </div>
                  <div className="">
                    {t("Estimated deposit price")}:
                    {productExchange.price&&numberOfNights&&exchangeRate&&conversionRate? (
                      <span className="ms-3 fw-bolder">{Common.formatNumber((productExchange.price*numberOfNights*conversionRate)/exchangeRate)} {t("Point")}</span>
                    ) :(
                      <span className="ms-3">-</span>
                    )}              
                  </div>
                </div>
              </div>              
            </div>
            {/*end::Select*/}
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>  
        ): ""}
        
        <div className="row g-6 mb-5">
          <div className="col-md-6 fv-row fv-plugins-icon-container">
            <label ref={checkInDateRef} className="fs-5 fw-bolder required mb-2">
              {" "}
              {t("Check-in date")}
            </label>
            {/*end::Label*/}
            {/*begin::Input*/}
            <DateRangePicker
              key={key.key1}
              onApply={(event, picker) => {
                setDate({
                  checkInDate: moment(picker.startDate).format("YYYY-MM-DD"),
                  checkOutDate: moment(picker.startDate)
                    .add(numberOfNights, "days")
                    .format("YYYY-MM-DD")
                });
                const conversionRates = getConversionRate(picker.startDate);
                setDepositedInAdvance(conversionRates.before);
                setConversionRate(conversionRates.rate);
              }}
              initialSettings={{
                singleDatePicker: true,
                autoUpdateInput: false,
                autoApply: true,
                minDate: moment(new Date()).add(2, "months"),
                locale: {
                  cancelLabel: true
                }
              }}
            >
              <input
                className="form-control form-control-solid"
                readOnly
                value={date.checkInDate}
                placeholder={formatMessage({ id: "Pick date range" })}
              />
            </DateRangePicker>
            <div className="fv-plugins-message-container invalid-feedback">
              {!date.checkInDate && <span>{errorList.checkInDate}</span>}
            </div>
          </div>
          <div className="col-md-6 fv-row fv-plugins-icon-container">
            <label ref={checkOutDateRef} className="fs-5 fw-bolder required mb-2">
              {t("Check-out date")}
            </label>
            <DateRangePicker
              key={key.key2}
              onApply={(event, picker) => {
                setDate({
                  checkInDate: moment(picker.startDate)
                    .subtract(numberOfNights, "days")
                    .format("YYYY-MM-DD"),
                  checkOutDate: moment(picker.startDate).format("YYYY-MM-DD")
                });
                const conversionRates = getConversionRate(moment(picker.startDate).subtract(numberOfNights, "days"));
                setDepositedInAdvance(conversionRates.before);
                setConversionRate(conversionRates.rate);
              }}
              initialSettings={{
                singleDatePicker: true,
                autoUpdateInput: false,
                minDate: moment(new Date())
                  .add(2, "months")
                  .add(numberOfNights, "days"),
                // showDropdowns: true,
                autoApply: true,
                locale: {
                  cancelLabel: true
                }
              }}
            >
              <input
                className="form-control form-control-solid"
                readOnly
                value={date.checkOutDate}
                placeholder={formatMessage({ id: "Pick date range" })}
              />
            </DateRangePicker>

            <div className="fv-plugins-message-container invalid-feedback">
              {!date.checkOutDate && <span>{errorList.checkOutDate}</span>}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
          {/*begin::Label*/}
          <label ref={referenceCodeRef} className="fs-5 fw-bolder mb-2">
            <span className="required">{t("Contract Code")}</span>
            <i className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7" title="Hợp đồng sở hữu kỳ nghỉ"/>
          </label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <input
            placeholder={formatMessage({ id: "Input Contract Code" })}
            onChange={e => setFormState({ referenceCode: e.target.value })}
            className="form-control form-control-solid"
            name="referenceCode"
          />
          {/*end::Input*/}
          <div className="fv-plugins-message-container invalid-feedback">
            {!formState.referenceCode && <span>{errorList.referenceCode}</span>}
          </div>
        </div>

        <div className="row g-2 mb-5">
          <div className="col-md-4 fv-row fv-plugins-icon-container">
            <label ref={image1Ref} className="fs-5 fw-bolder mb-5 d-block">
              <span className="required">{t("Resort info")}</span>
              <i className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7" title="Ảnh chụp trang có thông tin khu nghỉ trên hợp đồng"/>
            </label>
            {/*end::Label*/}
            {/*begin::Input*/}
            <div className="image-input image-input-outline">
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${imageList.image1.Path})`
                }}
              />

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7" />
                {/*begin::Inputs*/}
                <input
                  type="file"
                  name="avatar1"
                  accept=".png, .jpg, .jpeg"
                  onChange={event => uploadAvatar(event.target.files[0], "image1")}
                />

                {/*end::Inputs*/}
              </label>
            </div>
            <div className="fv-plugins-message-container invalid-feedback">
              {!imageList.image1.Path && <span>{errorList.avatar1}</span>}
            </div>
          </div>
          <div className="col-md-4 fv-row fv-plugins-icon-container">
            <label ref={image2Ref} className="fs-5 fw-bolder mb-5 d-block">
              <span className="required">{t("Owner info")}</span>
              <i className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7" title="Ảnh chụp trang có thông tin thông tin chủ sở hữu trên hợp đồng"/>
            </label>
            {/*end::Label*/}
            {/*begin::Input*/}

            <div className="image-input image-input-outline">
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${imageList.image2.Path})`
                }}
              />

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7" />
                {/*begin::Inputs*/}
                <input
                  type="file"
                  name="avatar2"
                  accept=".png, .jpg, .jpeg"
                  onChange={event => uploadAvatar(event.target.files[0], "image2")}
                />

                {/*end::Inputs*/}
              </label>
            </div>

            <div className="fv-plugins-message-container invalid-feedback">
              {!imageList.image2.Path && <span>{errorList.avatar2}</span>}
            </div>
          </div>
          <div className="col-md-4 fv-row fv-plugins-icon-container">            
            <label ref={image3Ref} className="fs-5 fw-bolder mb-5 d-block">
              <span className="required">{t("Valid confirmation")}</span>
              <i className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7" title="Ảnh chụp trang có Chữ ký & Dấu mộc của chủ sở hữu và khu nghỉ trên hợp đồng hoặc
              thẻ sở hữu cùng các văn bản khác nếu có"/>
            </label>
            {/*end::Label*/}
            {/*begin::Input*/}

            <div className="image-input image-input-outline">
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${imageList.image3.Path})`
                }}
              />

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7" />
                {/*begin::Inputs*/}
                <input
                  type="file"
                  name="avatar3"
                  accept=".png, .jpg, .jpeg"
                  onChange={event => uploadAvatar(event.target.files[0], "image3")}
                />

                {/*end::Inputs*/}
              </label>
            </div>

            <div className="fv-plugins-message-container invalid-feedback">
              {!imageList.image3.Path && <span>{errorList.avatar3}</span>}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
          {/*begin::Label*/}
          <label className="fs-5 fw-bolder mb-2">{t("Note")}</label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <textarea
            onChange={e => setFormState({ note: e.target.value })}
            className="form-control form-control-solid"
            rows="3"
            placeholder={formatMessage({ id: "Deposit Note" })}
            name="note"
          />
          {/*end::Input*/}
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}

        {/*end::Input group*/}
        {/*begin::Input group*/}

        {/*end::Input group*/}
        {/*begin::Input group*/}

        {/*end::Input group*/}
      </CustomModal>

      <Table
        refetch={loading}
        toolbarRender={[
          {
            component: (
              <span onClick={() => setVisible(true)} className="btn btn-primary">
                {t("Add New Deposit")}
              </span>
            )
          }
        ]}
        dataSource={getDeposits}
        columns={[
          {
            dataIndex: "addressName",
            title: "Resort"
          },
          {
            dataIndex: "productName",
            title: "Product Vacation Exchange",
            className: "w-300px"
          },
          {
            title: "Checkin - Checkout",

            render: (cell, row) => (
              <span>
                {Common.formatDateTime(new Date(row.fromDate), "dd/mm/yyyy")} -{" "}
                {Common.formatDateTime(new Date(row.toDate), "dd/mm/yyyy")}
              </span>
            )
          },

          {
            dataIndex: "point",
            title: "Point"
          },

          {
            dataIndex: "status",
            title: "Status",
            className: "text-center min-w-125px",

            render: v => (
              <div>
                <div className={`mb-2 mr-2 badge badge-${depositStatus[v].color}`}>
                  {depositStatus[v].name} 
                </div>
              </div>
            )
          }
        ]}
        actions={[
          {
            name: "View",
            type: "navigation",
            render(item) {
              return `/deposit/detail/${item.id}`;
            }
          }
        ]}
      />
    </>
  );
};

export default Deposit;
