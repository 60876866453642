import React from "react";
import { Link } from "react-router-dom";
import { t, toAbsoluteUrl } from "../../utils/utils";

type Props = {};

const Error = (props: Props) => {
  return (
    <div className="card">
    {/*begin::Card body*/}
    <div className="card-body">
      {/*begin::Heading*/}
      <div className="card-px text-center pt-15 pb-15">
        {/*begin::Title*/}
        <h2 className="fs-2x fw-bold mb-0">Xin lỗi, đơn hàng thanh toán chưa thành công</h2>
        {/*end::Title*/}
        {/*begin::Description*/}
        <p className="text-gray-400 fs-4 fw-semibold py-7">
          Ấn vào nút bên dưới để quay về
          <br />trang sản phẩm
        </p>
        {/*end::Description*/}
        {/*begin::Action*/}
        <Link
          to="/product-vacation/list"
          className="btn btn-primary er fs-6 px-8 py-4"
         
        >
         {t("Back To Product Page")}
        </Link>
        {/*end::Action*/}
      </div>
      {/*end::Heading*/}
      {/*begin::Illustration*/}
      <div className="text-center pb-15 px-5">
        <img
          src={toAbsoluteUrl("/theme/media/illustrations/sigma-1/18-dark.png")}
          alt=""
          className="mw-100 h-200px h-sm-325px"
        />
      </div>
      {/*end::Illustration*/}
    </div>
    {/*end::Card body*/}
  </div>
  
  );
};

export default Error;
