import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Spinner = ({ width, height }) => {
  return (
    <div style={{ width: width, height: height }} className="izzi-spinner"></div>
  );
};

Spinner.propTypess = {
  width: PropTypes.number,
  height: PropTypes.number
};
Spinner.defaultProps = {
  width: 16,
  height: 16
};

export default Spinner;
