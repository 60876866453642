import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import ChangePassword from "../changePassword/ChangePassword";
import Email from "../email/Email";
import Order from "../order/Order";
import Deposit from "../deposit";

import OrderDetail from "../orderDetail/OrderDetail";

import Schedule from "../schedule/index";
import { LayoutSplashScreen } from "../../layout/LayoutContext";
import DriveList from "../drive/DriveList";
import DriveForm from "../drive/DriveForm";

import TestView from "../testview/Testview";

import PointTransaction from "../profile/pointTransaction";
import { useSelector } from "react-redux";
import ProductVacation from "../product/vacation/list";
import DepositDetail from "../deposit/Detail";

import ListProduct from "../product/vacation/detail/views/listProduct/List";
import Information from "../product/vacation/detail/views/information";
import Profile from "../profile/Profile";
import Checkout from "../checkout";

import Confirm from "../checkout/Confirm";
import Success from "../checkout/Success";
import Error from "../checkout/Error";
import Order404 from "../checkout/404";
import Order403 from "../checkout/403";
import RentVacation from "../rent-vacation";
import RentVacationForm from "../rent-vacation/Form";

export default function HomePage() {
  const history = useHistory();
  const isAuth = useSelector((state) => state.auth.isAuth);
  //console.log(history.location.pathname, isAuth, "30");

  useEffect(() => {
    if (history.location.pathname === "/auth/login" && isAuth) {
      window.location.href = "/";
    }
  }, [isAuth]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/profile" />
        {/*  <Route path="/profile" component={Profile} /> */}

        <Route exact path="/profile" component={Profile} />

        <Route exact path="/product-vacation/list" component={ProductVacation} />
        <Route exact path="/rent-vacation/list" component={RentVacation} />
        <Route exact path="/rent-vacation/create" component={RentVacationForm} />
        <Route exact path="/checkout/confirm/:orderId" component={Confirm} />
        <Route exact path="/checkout/success/:orderId" component={Success} />
        <Route exact path="/checkout/error" component={Error} />
        <Route exact path="/checkout/404" component={Order404} />
        <Route exact path="/checkout/403" component={Order403} />
        <Route
          exact
          path="/product-vacation/detail/list-vacation/:friendlyUrl"
          component={ListProduct}
        />
        <Route exact path="/checkout/:orderId" component={Checkout} />
        <Route
          exact
          path="/product-vacation/detail/information/:friendlyUrl"
          component={Information}
        />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/email" exact={true} component={Email} />
        <Redirect exact from="/drive" to="/drive/list" />
        <Route path="/drive/list" component={DriveList} />
        <Route exact path="/drive/create" component={DriveForm} />
        <Route exact path="/drive/edit/:id" component={DriveForm} />
        <Route path="/order/list" exact={true} component={Order} />
        <Route path="/deposit/list" exact={true} component={Deposit} />
        <Route path="/deposit/detail/:id" exact={true} component={DepositDetail} />
        <Route path="/order/detail/:id" exact={true} component={OrderDetail} />

        <Route path="/schedule" exact={true} component={Schedule} />
        <Route path="/testview" exact={true} component={TestView} />
      </Switch>
    </Suspense>
  );
}
