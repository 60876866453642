import { MERCHANT } from '../configs/constants/MerchantConstant';
import { REQUEST, SUCCESS, FAILURE } from './ActionType';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
  
}

export const reducer = persistReducer(
    {
        storage,
        key: "merchant"
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(MERCHANT.GET_MERCHANT_DETAIL):
                return {
                    ...state
                };
            case FAILURE(MERCHANT.GET_MERCHANT_DETAIL):
                return {
                    ...state
                };
            case SUCCESS(MERCHANT.GET_MERCHANT_DETAIL):
              
                return {
                    ...state,
                    merchant: action.payload.merchant
                };
          
            default:
                return state;
        }
    });

