import { FCM } from '../configs/constants/FcmMessageTokenConstant';
import { REQUEST, SUCCESS, FAILURE } from './ActionType';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
    notifications: [],
}

export const reducer = persistReducer(
    {
        storage,
        key: "fcmMessage"
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(FCM.REGISTER_DEVICE_TOKEN):
                return {
                    ...state
                };
            case FAILURE(FCM.REGISTER_DEVICE_TOKEN):
                return {
                    ...state
                };
            case SUCCESS(FCM.REGISTER_DEVICE_TOKEN):
                return {
                    ...state

                };
            case REQUEST(FCM.REMOVE_DEVICE_TOKEN):
                return {
                    ...state
                };
            case FAILURE(FCM.REMOVE_DEVICE_TOKEN):
                return {
                    ...state
                };
            case SUCCESS(FCM.REMOVE_DEVICE_TOKEN):
                return {
                    ...state

                };
            default:
                return state;
        }
    });

