import React from "react";
import { Link } from "react-router-dom";
import { t } from "../../utils/utils";

const Footer = () => {
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/*begin::Container*/}
      <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/*begin::Copyright*/}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-1">
            {new Date().getFullYear().toString()}©
          </span>
          <Link to="/" className="text-gray-800 text-hover-primary">
            Vpass
          </Link>
        </div>
        {/*end::Copyright*/}
        {/*begin::Menu*/}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a
              href="http://vpass.asia"
              target="_blank"
              className="menu-link px-2"
            >
              {t("About")}
            </a>
          </li>
        </ul>
        {/*end::Menu*/}
      </div>
      {/*end::Container*/}
    </div>
  );
};

export default Footer;
