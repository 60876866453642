import React, { useMemo, useState } from "react";
import { ModalBody } from "react-bootstrap";
import { Button, Modal, ModalHeader } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import FormFeedback from "reactstrap/lib/FormFeedback";
import moment from "moment";
import { ModalFooter } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./ModalCreateEvent.scss";
import Spinner from "reactstrap/lib/Spinner";
import { toast } from "react-toastify";
import { createCalendarEvent } from "../../services/ScheduleService";

function ModalCreateEvent({ dataModal, setData, email, onSuccess }) {
  const [isLoading, setLoading] = useState(false);
  const [location, setLocation] = useState();
  const { handleSubmit, register, errors, setError, clearError } = useForm();

  const onPlaceSelected = place => {
    const { formatted_address } = place;
    setLocation(formatted_address);
  };

  const handleClose = () => {
    setData({
      open: false
    });
  };

  const onCreate = values => {
    if (isLoading) return;
    if (!validate()) return;
    const { start, end } = dataModal;
    const from = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const to = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    const { description, minutes, summary } = values;
    const requestData = {
      method: "email",
      emailAddress: email,
      startTime: from,
      endTime: to,
      summary,
      location,
      description,
      mintues: minutes
    };
    setLoading(true);
    createCalendarEvent(requestData)
      .then(res => {
        if (res && res.data && res.data.HtmlLink) {
          setLoading(false);
          onSuccess();
          return toast["success"]("Đặt lịch thành công.");
        } else {
          return toast["error"]("Đã xảy ra lỗi khi đặt lịch.");
        }
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const minDate = useMemo(() => {
    const { start } = dataModal;
    // const diff = moment(start).diff(moment(new Date()), "days");
    return moment(start)
      .set("hour", 8)
      .set("minute", 0)
      .toDate();
  }, [dataModal]);

  const validate = () => {
    if (!location) {
      setError("address", "manual", "Trường bắt buộc");
      return false;
    } else {
      clearError("address");
      return true;
    }
  };

  return (
    <Modal isOpen={dataModal.open} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Tạo lịch</ModalHeader>
      <ModalBody className="schedule-modal-body">
        <FormGroup>
          <Label>
            Tiêu đề <span style={{ color: "red" }}>*</span>
          </Label>
          <input
            placeholder="Nhập tiêu đề ..."
            className="form-control"
            ref={register({
              required: "Trường bắt buộc."
            })}
            name="summary"
          />
          {errors.summary && (
            <FormFeedback className="d-block">
              {errors.summary.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Thời gian đặt <span style={{ color: "red" }}>*</span>
          </Label>
          <Row>
            <Col md="6" className="pr-md-1 mb-sm-1">
              <ReactDatePicker
                placeholderText="Chọn ngày ..."
                wrapperClassName="schedule-datepicker"
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
                selected={dataModal.start}
                className="form-control"
                onChangeRaw={e => {
                  e.preventDefault();
                }}
                onChange={date => {
                  setData({
                    ...dataModal,
                    start: date,
                    end: moment(date)
                      .add(30, "minutes")
                      .toDate()
                  });
                }}
              />
              {errors.startTime ? (
                <FormFeedback className="d-block">
                  {errors.startTime.message}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md="3" className="pr-md-1 pl-md-1 mb-sm-1">
              <ReactDatePicker
                showTimeSelect
                showTimeSelectOnly
                dateFormat="hh:mm aa"
                minTime={minDate}
                maxTime={moment(dataModal.start)
                  .endOf("day")
                  .toDate()}
                selected={dataModal.start}
                className="form-control"
                onChangeRaw={e => {
                  e.preventDefault();
                }}
                onChange={date => {
                  setData({
                    ...dataModal,
                    start: date,
                    end: moment(date)
                      .add(30, "minutes")
                      .toDate()
                  });
                }}
              />
              {errors.startTime ? (
                <FormFeedback className="d-block">
                  {errors.startTime.message}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md="3" className="pl-md-1 mb-sm-1">
              <ReactDatePicker
                showTimeSelect
                showTimeSelectOnly
                dateFormat="hh:mm aa"
                minTime={moment(dataModal.start)
                  .add(30, "minutes")
                  .toDate()}
                maxTime={moment(dataModal.start)
                  .endOf("day")
                  .toDate()}
                selected={dataModal.end}
                className="form-control"
                onChangeRaw={e => {
                  e.preventDefault();
                }}
                onChange={date => {
                  setData({
                    ...dataModal,
                    end: date
                  });
                }}
              />
              {errors.endTime ? (
                <FormFeedback className="d-block">
                  {errors.endTime.message}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>
            Địa chỉ <span style={{ color: "red" }}>*</span>
          </Label>
          <Autocomplete
            className="form-control"
            onPlaceSelected={onPlaceSelected}
            types={["address"]}
            componentRestrictions={{ country: "vn" }}
          />
          {errors.address ? (
            <FormFeedback className="d-block">
              {errors.address.message}
            </FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Mô tả</Label>
          <textarea
            rows={4}
            className="form-control"
            ref={register}
            placeholder="Mô tả ..."
            name="description"
          ></textarea>
        </FormGroup>
        <FormGroup>
          <Label>Thời gian thông báo trước</Label>
          <div className="minutes-wrapper">
            <input
              ref={register}
              type="number"
              min={1}
              placeholder="Số phú ..."
              className={`form-control minutes-wrapper-input`}
              defaultValue={5}
              name="minutes"
            />
            <span className="minutes-wrapper-unit">phút</span>
          </div>

          {errors.minutes && (
            <FormFeedback className="d-block">
              {errors.minutes.message}
            </FormFeedback>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          active={!isLoading}
          color="primary"
          onClick={handleSubmit(onCreate)}
        >
          {isLoading && (
            <Spinner size="sm" style={{ color: "white", marginRight: 8 }} />
          )}
          <span>Tạo</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalCreateEvent;
