import React from "react";
import "./Event.scss";

export function Event({ event }) {
  const { title } = event;

  return (
    <>
      <div className={`schedule-event-item`}>
        <span className="schedule-event-item-text">{title}</span>
      </div>
    </>
  );
}

export default Event;
