import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAsync, useSetState } from "react-use";
import Configuration from "../../configs/Config";
import CustomModal from "../../partials/modal/Modal";
import { getExchangeRage } from "../../services/MerchantService";
import {
  getOrderDetail,
  getVacationOrderDetail,
} from "../../services/OrderService";
import { getPaymentMethods } from "../../services/ProductService";
import Common from "../../utils/Common";
import { t } from "../../utils/utils";

const Checkout = (props) => {
  const { orderId } = useParams();
  const history = useHistory();
  const userInfo = useSelector((state) => state.User.userInfo);

  const userInfoMember = useSelector((state) => state.User.userInfoMember);

  const totalPoint = useSelector((state) => state.User.totalPoint);
  const [loadingState, setLoadingState] = useSetState({
    isApplyingGiftCode: false,
    isApplyingPoint: false,
    isConfirming: false,
  });
  const [visible, setVisible] = useState(false);

  const { id, name, email, avatar, mobile, dateOfBirth } = userInfo;
  const [formUserInfo, setFormUserInfo] = useState("");
  const [giftCode, setGiftCode] = useState("");
  const [formState, setFormState] = useSetState({
    note: "",
    tempGiftCode: "",
    grandTotal: 0,
    promotionAmount: 0,
    giftCodeAmount: 0,
    tempPoint: 0,
  });
  const [products, setProducts] = useSetState({
    productVacation: {},
    productFee: [],
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [paymentMethodIntroduction, setPaymentMethodIntroduction] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [pointUsed, setPointUsed] = useState(0);
  const [orderDetail, setOrderDetail] = useState();

  const handleUsePoint = async (e) => {
    e.preventDefault();
    if (formState.tempPoint === 0) {
      toast.error(t("Point used must bigger than 0"));
      return;
    }
    if (formState.tempPoint > totalPoint) {
      toast.error(t("Point can't bigger than user's point"));
      return;
    }
    if (formState.tempPoint > (products.productVacation?.total / exchangeRate)+1) {
      toast.error(t("Point used can't bigger than vacation price"));
      return;
    }
    
    const res = await Common.sendCommand("Sale", "CreatePointPaymentSale", {
      Id: Common.guid(),
      CreatedDate: new Date(),
      CreatedBy: userInfo.id,
      MerchantId: localStorage.getItem(Configuration.merchantId),
      Point: formState.tempPoint,
      ShippingAmount: 0,
      TargetId: orderId,
      GrandTotal: formState.grandTotal,
    });
    if (res.data.Success) {
      // setPointUsed(totalPointUsed); 
      setPointUsed(+formState.tempPoint)     
      toast.success(t("Apply point success"));
      setFormState({ tempPoint: 0 });      
    }
  };

  const CalCulateGrandTotal = () => {
    if (orderDetail?.grandTotal) {
      setFormState({
        grandTotal:
        (orderDetail.grandTotal - formState.giftCodeAmount - pointUsed * exchangeRate) > 0?
        orderDetail.grandTotal - formState.giftCodeAmount - pointUsed * exchangeRate : 0,
      });
    }
  };

  const calCulateGiftCode = async (e) => {
    e.preventDefault();
    if (!formState.tempGiftCode) {
      toast.error(t("Please Input GiftCode"));
      return;
    }
    setLoadingState({ isApplyingGiftCode: true });
    const formData = new FormData();
    formData.append("orderid", orderId);
    formData.append("codes", formState.tempGiftCode);
    formData.append("preResultAmount", formState.grandTotal);
    const res = await Axios.get(
      `https://apigc.izzi.asia/api/Promotion/CalculateGiftCode?orderid=${orderId}&codes=${formState.tempGiftCode}&preResultAmount=${orderDetail.grandTotal}`,
      formData
    );
    if (res.data.Success) {
      setFormState({
        giftCodeAmount: res.data.PromotionAmount,
        tempGiftCode: "",
      });
      setGiftCode(formState.tempGiftCode);
      toast.success("Apply Coupon Code Succeed");
    } else {
      toast.error(res.data.InvalidCodes[0]?.Message);
    }
    setLoadingState({ isApplyingGiftCode: false });
  };

  const handleSubmit = () => {
    if (!paymentMethodId && formState.grandTotal > 0) {
      toast.error(t("Please choose payment method"));
      return;
    }
    setLoadingState({
      isConfirming: true,
    });
    const values = {
      Id: orderId,
      GiftCode: giftCode,
      CustomerName: name,
      CustomerEmail: email,
      CustomerMobile: mobile,
      DateOfBirth: dateOfBirth || new Date(),
      DeliveryTime: new Date(),
      AddressDetail: "",
      ReceiverName: formUserInfo.name,
      ReceiverMobile: formUserInfo.mobile,
      ReceiverEmail: formUserInfo.email,
      Latitude: 0,
      Longitude: 0,
      Note: formState.note,
      IpAddress: "",
      ModifiedDate: Common.formatDateTime(new Date()),
      PaymentMethodId: paymentMethodId || Configuration.guidEmpty,
      ModifiedBy: id,
    };

    Common.sendCommand("Sale", "ConfirmOrder", values).then((res) => {
      if (res.data.Success) {
        toast.success(t("Checkout Success"));
        if (formState.grandTotal <= 0) {
          history.replace(`/checkout/success/${orderId}`);
          return;
        }
        if (
          paymentMethodName === "CHUYỂN KHOẢN NGÂN HÀNG" ||
          paymentMethodName === "COD"
        ) {
          history.replace(`/checkout/confirm/${orderId}`, {
            introduction: paymentMethodIntroduction,
          });
        } else {
          window.location.href = JSON.parse(res.data.Data).url;
        }
      } else {
        history.replace("/checkout/error");
      }
      setLoadingState({
        isConfirming: false,
      });
    });
  };

  useEffect(() => {
    getVacationOrderDetail(orderId).then((res) => {
      if (res.vacationorder != null && res.vacationorder) {
        setOrderDetail(res.vacationorder);
        setFormState({ grandTotal: res.vacationorder.grandTotal, promotionAmount: res.vacationorder.promotionAmount });
        if (res.vacationorder.id === Configuration.guidEmpty) {
          history.replace("/checkout/404");
          return;
        }
        if (res.vacationorder.allStatus != 1) {
          history.replace("/checkout/403");
          return;
        }
      } else {
        history.replace("/checkout/404");
        return;
      }
    });
    getPaymentMethods().then((res) =>
      setPaymentMethods(res.paymentmethods.items)
    );
    getExchangeRage().then((res) => {
      const tempExchangeRate = JSON.parse(res.merchant.exchangeRate)
        .ExchangeRatePoint;
      setExchangeRate(tempExchangeRate);
    });
  }, []);
  useEffect(() => {
    //console.log(orderDetail, "788");
    if (orderDetail) {
      const productVacation = orderDetail.orderLines?.find(
        (orderLine) =>
          orderLine.product.type === Configuration.ProductTypes.Vacation || orderLine.product.type === Configuration.ProductTypes.VacationExchange
      );
      const productFee = orderDetail.orderLines?.filter(
        (orderLine) => orderLine.product.type === Configuration.ProductTypes.Fee
      );
      //console.log(productVacation, "811");
      if (productVacation) {
        setProducts({ productVacation });
      }
      if (productFee) {
        setProducts({ productFee });
      }
    }
  }, [orderDetail]);
  useEffect(() => {
    CalCulateGrandTotal();
  }, [pointUsed, formState.giftCodeAmount]);
  return (
    <div className="d-flex flex-column flex-lg-row">
      {/*begin::Content*/}
      <div className="d-flex flex-grow-1 flex-row-fluid me-xl-9 mb-10 mb-xl-0">
        {/*begin::Form*/}
        <form
          className="form w-100"
          action="#"
          id="kt_subscriptions_create_new"
        >
          {/*begin::Customer*/}
          <div className="card card-flush pt-3 mb-5 mb-lg-10">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2 className="fw-bold">{t("Customer")}</h2>
              </div>
              {/*begin::Card title*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">              
              {/*begin::Selected customer*/}
              <div className="d-flex align-items-center p-3 mb-2">                
                {/*begin::Avatar*/}
                <div className="symbol symbol-75px symbol-circle me-3">
                  <img
                    alt="Pic"
                    src={Configuration.staticDomain + avatar + "?mode=crop&width=75&height=75" }
                  />
                </div>
                {/*end::Avatar*/}
                {/*begin::Info*/}
                <div className="d-flex flex-column">                  
                  <span className="fs-4 fw-bold mb-2 text-gray-900 text-hover-primary me-2">
                    {name}
                  </span>                  
                  <span className="fw-semibold mb-2 text-gray-600 text-hover-primary">
                    {email}
                  </span>
                  <span className="fw-semibold text-gray-600 text-hover-primary">
                    {mobile}
                  </span>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <span
                    className="btn btn-primary"
                    onClick={() => setVisible(true)}
                  >
                    {t("Change Info")}
                  </span>
                </div>
                {/*end::Info*/}
              </div>

              {/*begin::Customer change Modal*/}
              <div className="mb-7">
                <CustomModal
                  viewOnly
                  visible={visible}
                  onHide={() => setVisible(false)}
                  title="Change Info Customer"
                >
                  <div className="row g-6">
                    <div className="col-6 d-flex flex-column">
                      <label className="mb-3">
                        <span class="fs-5 fw-bolder">{t("Name")}</span>
                      </label>
                      <input
                        onChange={(e) =>
                          setFormUserInfo({
                            ...formUserInfo,
                            name: e.target.value,
                          })
                        }
                        value={formUserInfo?.name}
                        className="form-control form-control-solid"
                      />
                    </div>
                    <div className="col-6">
                      <label className="mb-3">
                        <span class="fs-5 fw-bolder">{t("Mobile")}</span>
                      </label>
                      <input
                        type="number"
                        onChange={(e) =>
                          setFormUserInfo({
                            ...formUserInfo,
                            mobile: e.target.value,
                          })
                        }
                        value={formUserInfo?.mobile}
                        className="form-control form-control-solid"
                      />
                    </div>
                    <div className="col-6">
                      <label className="mb-3">
                        <span class="fs-5 fw-bolder">{t("Email")}</span>
                      </label>
                      <input
                        onChange={(e) =>
                          setFormUserInfo({
                            ...formUserInfo,
                            email: e.target.value,
                          })
                        }
                        value={formUserInfo?.email}
                        className="form-control form-control-solid"
                      />
                    </div>
                  </div>
                </CustomModal>
              </div>
              {/*end::Customer change Modal*/}
              
              {/*begin:: Apply point*/}
              {products.productVacation?.product?.type === Configuration.ProductTypes.VacationExchange ? (
                <div className="notice row d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex col-auto flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-semibold">
                      <h4 className="text-gray-900 text-capitalize d-flex align-items-center fw-bold">
                        <span className="svg-icon svg-icon-warning svg-icon-1 me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {userInfoMember}
                      </h4>
                      <div className="fs-6 text-gray-700">
                        <span>{t("Available Point")}</span> :{" "}
                        <span
                          className={
                            totalPoint > 0 ? "text-primary" :"text-danger"
                          }
                        >                        
                          {totalPoint > 0 ? totalPoint : 0}
                        </span>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  <div className="col-12 mt-4 mt-lg-0 col-lg-8 d-flex">
                    <input
                      className="form-control me-6"
                      placeholder="Sử dụng điểm"
                      type="number"
                      min={0}
                      max={totalPoint}
                      onChange={(e) => {
                        if (
                          e.target.value < 0 ||
                          !e.target.value ||
                          totalPoint < 0
                        ) {
                          setFormState({ tempPoint: 0 });
                          return;
                        }       
                        if (e.target.value > totalPoint) {
                          setFormState({ tempPoint: totalPoint });
                          return;
                        }                 
                        setFormState({ tempPoint: e.target.value });
                      }}
                      value={formState.tempPoint}                      
                    />
                    <button
                      disabled={loadingState.isApplyingPoint}
                      style={{ lineHeight: 2, whiteSpace: "nowrap" }}
                      className="btn btn-primary"
                      onClick={handleUsePoint}
                    >
                      {!loadingState.isApplyingPoint && (
                        <span class="indicator-label"> {t("Apply")}</span>
                      )}

                      {loadingState.isApplyingPoint && (
                        <span
                          class={`${!loadingState.isApplyingPoint &&
                            "indicator-progress"}`}
                        >
                          {t("Please wait")}...{" "}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/*end::Wrapper*/}
                </div>              
              ): ""}
              {/*end:: Apply point*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Customer*/}
          
          {/*begin::Card*/}
          <div className="card card-flush pt-3 mb-5 mb-lg-10">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2 className="fw-bold">
                  {t("Coupon Code")}
                  <i
                    tabIndex={0}
                    className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover focus"
                    data-bs-html="true"
                    data-bs-content="Áp dụng mã giảm giá cho đơn hàng"
                    data-kt-initialized={1}
                  />
                </h2>
              </div>
              {/*begin::Card title*/}
            </div>
            {/*end::Card header*/}

            {/*begin::Apply gift code*/}             
            <div className="card-body pt-0">
              <div className="d-flex">                
                <input
                  value={formState.tempGiftCode}
                  onChange={(e) => {
                    setFormState({ tempGiftCode: e.target.value });
                  }}
                  className="form-control me-6 form-control-solid rounded-3"
                />
                <button
                  disabled={loadingState.isApplyingGiftCode}
                  style={{ whiteSpace: "nowrap" }}
                  className="btn btn-primary"
                  onClick={calCulateGiftCode}
                >
                  {!loadingState.isApplyingGiftCode && (
                    <span class="indicator-label"> {t("Apply")}</span>
                  )}

                  {loadingState.isApplyingGiftCode && (
                    <span
                      class={`${!loadingState.isApplyingGiftCode &&
                        "indicator-progress"}`}
                    >
                      {t("Please wait")}...{" "}
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            {/*end::Apply gift code*/}
          </div>

          <div className="card card-flush pt-3 mb-5 mb-lg-10">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2 className="fw-bold">
                  {t("Note")}{" "}
                  <i
                    tabIndex={0}
                    className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover focus"
                    data-bs-html="true"
                    data-bs-content="Ghi chú yêu cầu thêm về đơn hàng"
                    data-kt-initialized={1}
                  />
                </h2>
              </div>
              {/*begin::Card title*/}
            </div>
            {/*end::Card header*/}

            {/*begin::Card body*/}
            <div className="card-body pt-0">              
              {/*begin::Invoice footer*/}
              <div className="d-flex flex-column fv-row">                
                <textarea
                  onChange={(e) => {
                    setFormState({ note: e.target.value });
                  }}
                  className="form-control form-control-solid rounded-3"
                  rows={4}
                  value={formState.note}
                />
              </div>
              {/*end::Invoice footer*/}
              {/*begin::Option*/}

              {/*end::Option*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </form>
        {/*end::Form*/}
      </div>
      {/*end::Content*/}

      {/*begin::Sidebar*/}
      <div className="flex-row-auto w-xl-475px">
        {/*begin::Pos order*/}
        <div className="card card-flush bg-body" id="kt_pos_form">
          {/*begin::Header*/}
          <div className="card-header pt-5">
            <h3 className="card-title fw-bold text-gray-800 fs-2qx">
              {t("Order")} #{orderDetail?.code}
            </h3>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body pt-0">            
            {/*begin::Summary*/}
            <div className="mb-11">
              <img
                className="rounded-3 w-100 h-300px"
                alt="image vacation"
                src={
                  products.productVacation?.product?.images
                    ? Configuration.staticDomain +
                      products.productVacation?.product?.images[0]?.path +
                      "?mode=crop&width=475&height=300"
                    : Configuration.imageNotAvailable+"?mode=crop&width=475&height=300"
                }
              />
              {orderDetail?.order}
              <div className="mt-6 fw-bolder fs-1">
                {products.productVacation?.targetName}
              </div>
              <div className="h-1px my-3 w-100 bg-secondary"></div>
              <div className="d-flex fs-4 mb-3 align-items-center justify-content-between">
                <span>{t("Number of nights")}</span>
                <span className="fw-bolder">
                  {moment(products.productVacation?.product?.toDate).diff(
                    moment(products.productVacation?.product?.fromDate),
                    "days"
                  )}{" "}
                  {t("night")}
                </span>
              </div>
              <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                <span>{t("Adult")}</span>
                <span className="fw-bolder">
                  {orderDetail?.adultTotal}
                </span>
              </div>
              <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                <span>{t("Children")}</span>
                <span className="fw-bolder">
                  {orderDetail?.childrenAges?.length ||0}
                </span>
              </div>
              <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                <span>{t("Check-in date")}</span>
                <span className="fw-bolder">
                  {moment(products.productVacation?.product?.fromDate).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              </div>
              <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                <span>{t("Check-out date")}</span>
                <span className="fw-bolder">
                  {moment(products.productVacation?.product?.toDate).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              </div>
              <div className="h-1px my-3 w-100 bg-secondary"></div>

              <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                <span className="fw-bolder">
                  {t("Vacation price")} (
                  {moment(products.productVacation?.product?.toDate).diff(
                    moment(products.productVacation?.product?.fromDate),
                    "days"
                  )}{" "}
                  {t("night")})
                </span>
                <span className="fw-bolder">
                  {products.productVacation?.product?.type === Configuration.ProductTypes.VacationExchange ? (
                   <>
                    {Common.formatNumber((products.productVacation?.total/ exchangeRate))} {t("Point")}
                   </>                   
                  )
                     :
                    Common.formatMoney(products.productVacation?.total)
                  }
                </span>
              </div>
              {products.productFee.length > 0 &&
                products.productFee.map((product) => {
                  return (
                    <div className="d-flex mb-3 fs-4 align-items-center justify-content-between">
                      <span>
                        {product.targetName} (x{product.quantity})
                      </span>
                      <span>{Common.formatMoney(product.total)}</span>
                    </div>
                  );
                })}

              <div className="d-flex flex-stack bg-success rounded-3 p-6 mb-11">
                {/*begin::Content*/}
                <div className="fs-6 fw-bold text-white">
                  <span className="d-block lh-1 mb-2">{t("Sub total")}</span>
                  <span className="d-block mb-2">{t("Discount")}</span>
                  <span className="d-block mb-9">{t("Used Point")}</span>
                  <span className="d-block fs-2qx lh-1">{t("Total")}</span>
                </div>
                {/*end::Content*/}
                {/*begin::Content*/}
                <div className="fs-6 fw-bold text-white text-end">
                  <span className="d-block lh-1 mb-2">
                    {Common.formatMoney(orderDetail?.subTotal)}
                  </span>
                  <span className="d-block mb-2">
                    -{Common.formatMoney(formState.promotionAmount + formState.giftCodeAmount)}
                  </span>
                  <span className="d-block mb-9">{pointUsed}</span>
                  <span
                    className="d-block fs-2qx lh-1"
                    data-kt-pos-element="grant-total"
                  >
                    {Common.formatMoney(formState.grandTotal)}
                  </span>
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Summary*/}

              {/*begin::Payment Method*/}
              <div className="m-0">
                {/*begin::Title*/}
                {formState.grandTotal > 0 && (
                  <>
                    <h1 className="fw-bold text-gray-800 mb-5">
                      {t("Payment Method")}
                    </h1>
                    {/*end::Title*/}
                    {/*begin::Radio group*/}
                    <div className="row g-5 px-0 mb-12">
                      {/*begin::Radio*/}
                      {paymentMethods.length > 0 &&
                        paymentMethods
                          .filter((paymentMethod) => paymentMethod.type === 1)
                          .map((paymentMethod) => {
                            return (
                              <div key={paymentMethod.id} className="col-4">
                                <label
                                  className={`btn h-100 bg-light btn-color-gray-600 btn-active-text-gray-800 border border-3 border-gray-100 border-active-primary btn-active-light-primary w-100 px-4 ${paymentMethod.id ===
                                    paymentMethodId && "active"}`}
                                  data-kt-button="true"
                                >
                                  {/*begin::Input*/}
                                  <input
                                    className="btn-check"
                                    type="radio"
                                    name="method"
                                    defaultValue={0}
                                    onChange={(e) => {
                                      setPaymentMethodName(paymentMethod.name);
                                      setPaymentMethodId(paymentMethod.id);
                                      setPaymentMethodIntroduction(
                                        JSON.parse(paymentMethod.configuration)
                                          ?.message
                                      );
                                    }}
                                  />
                                  <img
                                    className="w-100 h-50px mb-3"
                                    src={
                                      Configuration.staticDomain +
                                      paymentMethod.imageUrl +
                                      "?mode=crop&width=200&height=150"
                                    }
                                    alt="img"
                                  />
                                  {/*end::Input*/}
                                  {/*begin::Title*/}
                                  <span className="fs-7 fw-bold d-block">
                                    {paymentMethod.name}
                                  </span>
                                  {/*end::Title*/}
                                </label>
                              </div>
                            );
                          })}
                      {/*end::Radio*/}
                    </div>
                  </>
                )}
                {/*end::Radio group*/}

                {/*begin::Actions*/}
                <button
                  disabled={loadingState.isConfirming}
                  onClick={handleSubmit}
                  className="btn btn-primary fs-1 w-100 py-4"
                >
                  {!loadingState.isConfirming && (
                    <span class="indicator-label">{t("Check Out")}</span>
                  )}

                  {loadingState.isConfirming && (
                    <span
                      class={`${!loadingState.isConfirming &&
                        "indicator-progress"}`}
                    >
                      {t("Please wait")}...{" "}
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                {/*end::Actions*/}
              </div>
              {/*end::Payment Method*/}
            </div>
            {/*end: Card Body*/}
          </div>
          {/*end::Pos order*/}
        </div>
        {/*end::Sidebar*/}
      </div>
    </div>
  );
};

export default Checkout;
