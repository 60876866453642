import moment from "moment";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { getSchedules } from "../../services/ScheduleService";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./UserSchedule.scss";
import { getListCalendarDetails } from "../../services/ScheduleService";
import Event from "./Event";
import ModalCreateEvent from "./ModalCreateEvent";
import { toast } from "react-toastify";

// import EventColorDescription from "./EventColorDescription";
const localizer = momentLocalizer(moment);
const minHourCalendar = 6;
const maxHourCalendar = 22;
const today = new Date();

function UserSchedule({ userInfo }) {
  const { id, email } = userInfo;
  const [currentView, setCurrentView] = useState("month");
  const [events, setEvents] = useState([]);
  const [googleEvents, setGoogleEvents] = useState([]);
  const [dataModal, setData] = useState({
    open: false
  });
  const currentDateRange = useRef({
    from: moment()
      .startOf("week")
      .format("YYYY-MM-DDTHH:mm:ss"),
    to: moment()
      .endOf("week")
      .format("YYYY-MM-DDTHH:mm:ss")
  });

  const loadEvents = useCallback(
    (id, from, to) => {
      if (!id) return;
      getSchedules({
        targetId: id,
        from,
        to
      }).then(res => {
        setEvents(
          res.schedules.items.map(item => ({
            start: new Date(item.triggerDateTime),
            end: new Date(item.triggerDateTime),
            currentView: currentView,
            title: item.name
          }))
        );
      });
    },
    [currentView]
  );

  const loadGoogleEvents = useCallback(
    (email, from, to) => {
      if (!email) return;
      let isGmail = false;
      const tail = email.split("@");
      if (tail.length > 0 && tail[1].includes("gmail")) {
        isGmail = true;
      }
      if (!isGmail) return;
      getListCalendarDetails(email, from, to)
        .then(res => {
          if (res && res.data) {
            const { CalendarDetails } = res.data;
            const nextEvents =
              CalendarDetails &&
              CalendarDetails.map(item => ({
                id: item.Id,
                start: moment(item.StartTime).toDate(),
                end: moment(item.EndTime).toDate(),
                currentView: currentView,
                title: item.Name,
                isGoogleCalendarEvent: true
              }));
            setGoogleEvents(nextEvents || []);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [currentView]
  );

  useEffect(() => {
    const { from, to } = currentDateRange.current;
    loadEvents(id, from, to);
  }, [id, loadEvents]);

  useEffect(() => {
    const { from, to } = currentDateRange.current;
    loadGoogleEvents(email, from, to);
  }, [email, loadGoogleEvents]);

  const onRangeChange = dates => {
    let nextStart = null;
    let nextEnd = null;
    if (Array.isArray(dates)) {
      nextStart = dates[0];
      nextEnd = dates[dates.length - 1];
    } else {
      const { start, end } = dates;
      nextStart = start;
      nextEnd = end;
    }
    const from = moment(nextStart)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const to = moment(nextEnd)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    currentDateRange.current = {
      from,
      to
    };
    loadEvents(id, from, to);
    loadGoogleEvents(email, from, to);
  };

  const handleSelect = ({ start, end }) => {
    if (!moment(start).isSame(moment(end), "day")) return;
    if (moment(start).isBefore(moment()))
      return toast["error"]("Không thể đặt trước thời điểm hiện tại");
    const _events = [...events, ...googleEvents];
    // validate trung cac gio khac
    for (let event of _events) {
      // start or end in event start -> end
      if (
        moment(start).isAfter(moment(event.start).subtract(1, "minutes")) &&
        moment(start).isBefore(moment(event.end))
      ) {
        return toast["error"]("Không thể đặt trùng giờ");
      }
      if (
        moment(end).isAfter(moment(event.start)) &&
        moment(end).isBefore(moment(event.end))
      ) {
        return toast["error"]("Không thể đặt trùng giờ");
      }

      // start < event start, end > event end
      if (
        moment(start).isBefore(moment(event.start)) &&
        moment(end).isAfter(moment(event.end))
      ) {
        return toast["error"]("Không thể đặt trùng giờ");
      }
    }
    setData({
      open: true,
      start: start,
      end: end
    });
  };

  const onSuccess = () => {
    setData({
      open: false
    });
    const { from, to } = currentDateRange.current;
    loadGoogleEvents(email, from, to);
  };

  return (
    <>
      <Calendar
        views={["day", "week", "month", "agenda"]}
        defaultView="week"
        localizer={localizer}
        min={
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            minHourCalendar
          )
        }
        max={
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            maxHourCalendar
          )
        }
        events={[...events, ...googleEvents]}
        startAccessor="start"
        endAccessor="end"
        slotPropGetter={day => {
          if (moment(day).isBefore(moment()))
            return {
              className: "past"
            };
        }}
        dayPropGetter={day => {
          if (moment(day).isBefore(moment()))
            return {
              className: "past"
            };
        }}
        selectable="ignoreEvents"
        step={30}
        eventPropGetter={event => {
          // const { isGoogleCalendarEvent } = event;
          // let className =
          //   "schedule-event " + (isGoogleCalendarEvent ? "google" : "default");
          let className = "schedule-event default";
          return {
            className
          };
        }}
        onSelectEvent={event => {
          //console.log(event);
          // setOpen(true);
        }}
        onSelectSlot={handleSelect}
        onView={view => {
          setCurrentView(view);
        }}
        onRangeChange={onRangeChange}
        components={{
          event: Event
        }}
      />
      <ModalCreateEvent
        dataModal={dataModal}
        setData={setData}
        email={email}
        onSuccess={onSuccess}
      />
    </>
  );
}

export default UserSchedule;
