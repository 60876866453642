import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from '../reducers/index';
import { persistStore } from "redux-persist";

const defaultMiddlewares = [
    thunkMiddleware,
    promiseMiddleware
];

// export default function configureStore() {
//     return createStore(rootReducer, {}, compose(applyMiddleware(...defaultMiddlewares)));
// }

const store = createStore(rootReducer, {}, compose(applyMiddleware(...defaultMiddlewares)));

export const persistor = persistStore(store);


export default store;