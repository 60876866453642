import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Configuration from "../../configs/Config";
import { getVacationOrderDetail } from "../../services/OrderService";
import Common from "../../utils/Common";
import { t } from "../../utils/utils";
import "./style.scss";

type Props = {};

const Confirm = (props: Props) => {
  const { orderId } = useParams<{ orderId: string }>();

  const [productsDetail, setProductsDetail] = useState<any>();

  useEffect(() => {
    getVacationOrderDetail(orderId).then((res) => {
      if (res.vacationorder) {
        setProductsDetail(res.vacationorder);
      }
    });
  }, []);
  useEffect(() => {
    //console.log(productsDetail, "1999");
  }, [productsDetail]);
  const calculateGrandTotalLeft = () => {
    if (productsDetail) {
      const preResultAmount = productsDetail.payments.reduce((acc, value) => {
        if (
          (Configuration.PaymentStatus.Closed & value.allStatus) ===
          Configuration.PaymentStatus.Closed
        )
          acc += value.amount;
        return acc;
      }, 0);
      return {
        preResultAmount: preResultAmount + productsDetail.giftCodeAmount,
        grandTotalLeft: productsDetail.grandTotal - preResultAmount - productsDetail.giftCodeAmount,
      };
    }
  };
  return (
    <div className="card">
      <div className="card-body">
        <i className="bi bi-check-circle text-success fs-5hx text-center d-block mt-10"></i>
        <h2 className="fs-2x fw-bold mb-0 text-success d-block text-center my-19">
          {t("Confirm order successfully")}
        </h2>
        <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 mt-19">
          {/*begin::Order details*/}
          <div className="card card-flush card-bordered py-4 flex-row-fluid">
            {/*begin::Card header*/}
            <div className="card-header">
              <div className="card-title">
                <h2>{t("Order Details")}</h2>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body px-8 pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                  {/*begin::Table body*/}
                  <tbody className="fw-semibold text-gray-600">
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/finance/fin008.svg*/}
                          <span className="svg-icon svg-icon-2 me-2">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                                fill="currentColor"
                              />
                              <path
                                d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {t("Order Code")}
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        #{productsDetail?.code}
                      </td>
                    </tr>
                    {/*begin::Date*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/files/fil002.svg*/}
                          <span className="svg-icon svg-icon-2 me-2">
                            <svg
                              width={20}
                              height={21}
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                                fill="currentColor"
                              />
                              <path
                                d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {t("Created Date")}
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        {Common.formatDateTime(
                          productsDetail?.createdDate,
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                    {/*end::Date*/}
                    {/*begin::Payment method*/}

                    {/*end::Payment method*/}
                    {/*begin::Date*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm006.svg*/}
                          <span className="svg-icon svg-icon-2 me-2">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {t("Grand Total")}
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        {Common.formatMoney(productsDetail?.grandTotal)}
                      </td>
                    </tr>
                    {/*end::Date*/}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Order details*/}
          {/*begin::Customer details*/}
          <div className="card card-flush py-4 flex-row-fluid card-bordered">
            {/*begin::Card header*/}
            <div className="card-header">
              <div className="card-title">
                <h2>{t("Product Info")}</h2>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body px-8 pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                  {/*begin::Table body*/}
                  <tbody className="fw-semibold text-gray-600">
                    {/*begin::Customer name*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}

                          {/*end::Svg Icon*/}
                          {t("Name")}
                        </div>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="fw-bold text-hover-primary cursor-pointer text-end w-200px overflow-hidden d-inline-block"
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title={
                            productsDetail?.orderLines[0]?.targetName
                          }
                        >
                          {productsDetail?.orderLines[0]?.targetName}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}

                          {/*end::Svg Icon*/}
                          {t("Check-in date")}
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        <div className="d-flex align-items-center justify-content-end">
                          {/*begin:: Avatar */}

                          {/*end::Avatar*/}
                          {/*begin::Name*/}
                          <span className="text-gray-600">
                            {Common.formatDateTime(
                              productsDetail?.orderLines[0]?.product.fromDate,
                              "DD-MM-YYYY"
                            )}
                          </span>
                          {/*end::Name*/}
                        </div>
                      </td>
                    </tr>
                    {/*end::Customer name*/}
                    {/*begin::Customer email*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}

                          {/*end::Svg Icon*/}
                          {t("Check-out date")}
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        <span className="text-gray-600">
                          {Common.formatDateTime(
                            productsDetail?.orderLines[0]?.product.toDate,
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </td>
                    </tr>
                    {/*end::Payment method*/}
                    {/*begin::Date*/}

                    {/*end::Date*/}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Customer details*/}
          {/*begin::Documents*/}
          <div className="card card-flush py-4 flex-row-fluid card-bordered">
            {/*begin::Card header*/}
            <div className="card-header">
              <div className="card-title">
                <h2>Khấu trừ</h2>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body px-8 pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                  {/*begin::Table body*/}
                  <tbody className="fw-semibold text-gray-600">
                    {/*begin::Invoice*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                          {/*end::Svg Icon*/}
                          Số tiền giảm
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        <a
                          href="/metronic8/demo2/../demo2/apps/invoices/view/invoice-3.html"
                          className="text-gray-600 text-hover-primary"
                        >
                          {Common.formatMoney(
                            calculateGrandTotalLeft()?.preResultAmount
                          )}
                        </a>
                      </td>
                    </tr>
                    {/*end::Invoice*/}
                    {/*begin::Shipping*/}
                    <tr>
                      <td className="text-muted">
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm006.svg*/}
                          {/*end::Svg Icon*/}Số tiền cần thanh toán
                          
                        </div>
                      </td>
                      <td className="fw-bold text-end">
                        <a
                          href="#"
                          className="text-gray-600 text-hover-primary"
                        >
                          {Common.formatMoney(
                            calculateGrandTotalLeft()?.grandTotalLeft
                          )}
                        </a>
                      </td>
                    </tr>
                    {/*end::Shipping*/}
                    {/*begin::Rewards*/}
                    
                    {/*end::Rewards*/}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Documents*/}
        </div>
        <div className="card mt-10 pb-10">
          <div className="d-flex align-items-center justify-content-center mt-10">
            <Link to="/product-vacation/list" className="btn btn-primary">{t("Back To Product Page")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
