import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../../services/UserService";
import Config from "../../configs/Config";
import { connect } from "react-redux";
import {
  getUserInfoAction,
  getTotalPointAction,
  getInformationMemberAction,
} from "../../actions/User";
import { getMerchantDetailAction } from "../../actions/Merchant";
import Swal from "sweetalert2";
import Spinner from "../../layout/assets/icon/spinner/Spinner";
import { Link, useLocation } from "react-router-dom";
import { languageMapping, t } from "../../utils/utils";
import { setAuthSuccessAction } from "../../actions/Auth";

const Login = ({
  lang,
  merchant,
  getUserInfoAction,
  getTotalPointAction,
  getMerchantDetailAction,
  getInformationMemberAction,
  setAuthSuccessAction,
}) => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const refMerchantCode = useRef();

  useEffect(() => {
    let merchantCode = window.location.host.includes(".local")
      ? Config.merchantCode
      : window.location.host.split(".")[0];
    refMerchantCode.current = merchantCode;
  }, []);
  const { register, handleSubmit, errors } = useForm();

  //function lay du lieu
  const getUserData = async (token) => {
    //logic authen
    localStorage.setItem(Config.tokenId, token);

    await getUserInfoAction(token);
    await getTotalPointAction(token);
    await getMerchantDetailAction();
    await getInformationMemberAction(token, languageMapping[lang]);
  };

  const onSubmit = ({ email, password }) => {
    let objData = {
      UserName: email,
      Password: password,
      MerchantCode: merchant.code,
    };
    setLoading(true);
    login(objData).then((res) => {
      setLoading(false);

      const { Success, Data } = res.data;
      if (Success) {
        const { tokenId } = Data;
        if (tokenId) {
          getUserData(tokenId);
          setAuthSuccessAction();
        }
      } else {
        Swal.fire("Sai tên hoặc mật khẩu! Mời bạn đăng nhập lại.");
      }
    });
  };

  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      >
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-dark mb-3">
            {t("Sign In")} 
          </h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          {/* <div className="text-gray-400 fw-bold fs-4">
            {t("New Here")} ? {" "}
            <Link to="/auth/registration" className="link-primary fw-bolder">
              {t("Create an Account")}
            </Link>
          </div> */}
          {/*end::Link*/}
        </div>
        {/*begin::Heading*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          {/*begin::Label*/}
          <label className="form-label required fs-6 fw-bolder text-dark">
            Email
          </label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="email"
            ref={register({ required: "is a required property" })}
            autoComplete="off"
          />
          {/*end::Input*/}

          <div className="fv-plugins-message-container invalid-feedback">
            {errors.email && <div>{t(errors.email.message)}</div>}
          </div>
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-stack mb-2">
            {/*begin::Label*/}
            <label className="form-label fw-bolder required text-dark fs-6 mb-0">
              {t("Password")}
            </label>
            {/*end::Label*/}
            {/*begin::Link*/}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
            >
              {t("Forgot Password?")} 
            </Link>
            {/*end::Link*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Input*/}
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            ref={register({ required: "is a required property" })}
            autoComplete="off"
          />
          {/*end::Input*/}
          <div className="fv-plugins-message-container invalid-feedback">
            {errors.password && <div>{t(errors.password.message)}</div>}
          </div>
        </div>
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          {/*begin::Submit button*/}
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-lg btn-primary w-100 mb-5"
          >
            <span className="indicator-label">{t("Sign in")}</span>
            <span className="indicator-progress">
              {t("Please wait")}...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
          {/*end::Submit button*/}
          {/*begin::Separator*/}

          {/*end::Google link*/}
        </div>
        {/*end::Actions*/}
        <div />
      </form>
      {/*end::Form*/}
    </div>
  );
};
const mapStateToProp = (state) => ({
  userInfo: state.User.userInfo,
  merchant: state.Merchant.merchant,
  lang: state.i18n.lang,
});
const mapDispatchToProps = {
  getUserInfoAction,
  getTotalPointAction,
  getMerchantDetailAction,
  getInformationMemberAction,
  setAuthSuccessAction,
};
export default connect(mapStateToProp, mapDispatchToProps)(Login);
