import Configuration from "../../configs/Config";
import { t } from "../../utils/utils";
import moment from "moment";
import Axios from "axios";

export const depositStatus = {
  0: {
    name: "",
    color: "",
  },
  1: {
    name: t("Pending"),
    color: "warning",
  },
  2: {
    name: t("Approved"),
    color: "success",
  },
  3: {
    name: t("Canceled"),
    color: "danger",
  },
  4: {
    name: t("Sold"),
    color: "primary",
  },
  5: {
    name: t("Expiring"),
    color: "secondary",
  },
  6: {
    name: t("Pending Expired"),
    color: "danger",
  },
  7: {
    name: t("Expired"),
    color: "danger",
  },
};

export function getConversionRate(fromDate: any): {rate:any, before: any} {
  let conversionRate = {
    rate: 0,
    before: 0
  };
  var diff = moment(fromDate).diff(moment(), "months", true)
  if (diff >= 2 && diff <= 3) {
    conversionRate = {
      rate: 0.6,
      before: diff
    }
  } else if (diff > 3 && diff <= 6) {
    conversionRate = {
      rate: 0.8,
      before: diff
    }
  } else if (diff > 6 && diff <= 9) {
    conversionRate = {
      rate: 0.9,
      before: diff
    }
  } else if (diff > 9 && diff <= 12) {
    conversionRate = {
      rate: 0.95,
      before: diff
    }
  } else if (diff > 12) {
    conversionRate = {
      rate: 1,
      before: diff
    }
  }
  return conversionRate;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename);
}

export const handleUploadAvatar = async file => {
  const tokenId: any = localStorage.getItem(Configuration.tokenId);
  var bodyFormData = new FormData();
  bodyFormData.append("token", tokenId);
  bodyFormData.append("file", file);
  return await Axios.post(Configuration.urlApiCommand + "/Image/UploadFile", bodyFormData);
};

export function caculationDiscounts(discounts:any, price:number) {
  let discountResults = {
    price: price,
    oldPrice: 0,
    percent: 0,
    cash: 0
  };
  if (discounts == undefined || (discounts!=null && discounts.length == 0) ) {
    return discountResults;
  } else {
    var now = moment();
    var tempPrice = price;
    var percent = 0;
    var cash = 0;
    if (discounts.length > 0) {
      discounts.map((val) => {
        if (moment(val.from) <= now && moment(val.to) >= now) {
          if (val.resultType == 0) {
            tempPrice = tempPrice - (tempPrice * val.resultValue) / 100;
            percent += parseInt(val.resultValue);
          } else {
            tempPrice = tempPrice - val.resultValue;
            cash += parseInt(val.resultValue);
            percent += ((price - tempPrice)/price)*100;
          }
        }
        if (price == tempPrice) {
          return discountResults;
        } else {
          discountResults = {
            price: tempPrice,
            oldPrice: price,                
            percent: percent,
            cash: cash,                
          }
        }
      });
    }    
    return discountResults;      
  }
}
