import React, { createRef, useEffect, useMemo, useState, useCallback } from "react";
import { Spinner, Button, Form } from "react-bootstrap";
import {
  getFileService,
  googleDrivePostFile,
  getDriveFiles
} from "../../services/DriveService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import "./style.scss";
import Configuration from "../../configs/Config";
import Common from "../../utils/Common";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import FileType from "file-type/browser";

const DriveForm = props => {
  const id = props.match.params.id;
  const [isUploading, setUploading] = useState(false);
  const userInfo = useSelector(state => state.User.userInfo);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    name: ""
  });

  const refFile = createRef();

  const validate = () => {
    let isValid = true;
    let nextErrors = {};
    if (!state.name) {
      isValid = false;
      nextErrors = { ...nextErrors, name: "Vui lòng nhập tên file." };
    } else {
      nextErrors = { ...nextErrors, name: "" };
    }
    if (!id && !refFile.current.files.length === 0) {
      isValid = false;
      nextErrors = { ...nextErrors, file: "Vui lòng chọn file." };
    } else {
      nextErrors = { ...nextErrors, file: "" };
    }
    setErrors(nextErrors);
    return isValid;
  };

  const fileChange = () => {
    // eslint-disable-next-line
    const name = refFile.current.value.replace(/.*[\/\\]/, "");
    setState({
      ...state,
      name
    });
  };

  const loadData = async params => {
    setUploading(true);
    try {
      const response = await getFileService(params);
      if (response) {
        const name = Object.keys(response)[0];
        //console.log(response[name]);
        setState(state => ({
          ...state,
          name: response[name].name
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (merchantId && id) {
      loadData({ id, merchantId });
    }
  }, [id, merchantId]);

  const title = useMemo(() => {
    return id ? "Chỉnh sửa File" : "Tạo mới File";
  }, [id]);

  const checkFileType = async file => {
    const type = await FileType.fromBlob(file);
    if (/image.*/.test(type.mime)) {
      return {
        fileType: Configuration.FileTypes.IMAGE,
        storageType: Configuration.StorageTypes.GOOGLE
      };
    }
    if (type.mime === "application/pdf") {
      return {
        fileType: Configuration.FileTypes.PDF,
        storageType: Configuration.StorageTypes.GOOGLE
      };
    }
    return null;
  };

  const folderId = "1Xb7P22h3Ht9tXdbUOZno_EfdCGNA1KNg"

  const uploadFile = async () => {
    if (isUploading) return;
    if (!validate()) return;
    const file = refFile.current.files[0];
    const fileType = await checkFileType(file);
    if (!fileType) return;
    const formData = new FormData();
    formData.append("", file);
    setUploading(true);
    try {
      const response = await googleDrivePostFile(userInfo.email, formData, folderId);
      const { WebViewLink } = response.data;
      if (WebViewLink) {
        const body = {
          Id: Common.guid(),
          MerchantId: merchantId,
          Name: state.name,
          SubDescription: "",
          Description: "",
          TypeStorage: fileType.storageType,
          Type: fileType.fileType,
          Path: WebViewLink,
          ContentType: "",
          ContentLength: 0,
          CreatedDate: new Date(),
          CreatedBy: Common.getCookie("userId")
        };
        await Common.sendCommand("File", "CreateFile", body);
        props.history.push("/drive/list");
        return toast["success"]("Upload file thành công.");
      }
    } catch (error) {
      console.error(error);
      return toast["error"]("Upload file thất bại.");
    } finally {
      setUploading(false);
    }
  };

  const updateFile = async () => {
    if (!validate()) return;
    const body = {
      Id: id,
      Name: state.name,
      SubDescription: "",
      Description: "",
      ModifiedDate: new Date(),
      ModifiedBy: Common.getCookie("userId")
    };

    setUploading(true);
    try {
      const result = await Common.sendCommand("File", "UpdateFile", body);
      if (result.data.Success) {
        props.history.push("/drive/list");
        return toast["success"]("Cập nhật thông tin file thành công.");
      } else return toast["error"]("Cập nhật thông tin file thất bại.");
    } catch (error) {
      return toast["error"]("Cập nhật thông tin file thất bại.");
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (id) {
      updateFile();
    } else {
      uploadFile();
    }
  };

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
      <div className="row">
        <div className="col-xl-12">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">{title}</h3>
              </div>
            </div>
            <Form className="kt-form kt-form--label-right">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    {!id && (
                      <Form.Group as={Row} controlId="lastName">
                        <Form.Label column xl={3} lg={3}>
                          File
                        </Form.Label>
                        <Col
                          lg={9}
                          xl={6}
                          style={{ alignItems: "center", display: "flex" }}
                        >
                          <Form.Control
                            type="file"
                            placeholder="Chọn file ..."
                            className={errors.file && "is-invalid"}
                            ref={refFile}
                            name="file"
                            accept="image/*,.pdf"
                            onChange={fileChange}
                          />
                          {errors.file && (
                            <Form.Control.Feedback type="invalid">
                              {errors.file}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row} controlId="name">
                      <Form.Label column xl={3} lg={3}>
                        Tên file
                      </Form.Label>
                      <Col lg={9} xl={6}>
                        <Form.Control
                          type="text"
                          placeholder="Nhập tên file ..."
                          value={state.name}
                          name="name"
                          onChange={e => {
                            setState({ name: e.target.value });
                          }}
                          className={errors.name && "is-invalid"}
                        />
                        {errors.name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-lg-3 col-xl-3"></div>
                    <div className="col-lg-9 col-xl-9">
                      <Button
                        variant="primary"
                        disabled={isUploading}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {isUploading && (
                          <Spinner
                            style={{ marginRight: 8 }}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        <span>Cập nhật</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveForm;
