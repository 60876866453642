import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getOrdersAction } from "../../actions/Order";
import ActionTable from "../../partials/table/ActionTable";
import Table from "../../partials/table/Table";
import { getOrders } from "../../services/OrderService";
import Common from "../../utils/Common";
import { useComponentVisible } from "../../utils/customHook";
import { getOrderStatusById } from "../../utils/OrderUtils";

const Order = (props) => {
  const formatOrders = (orderLines) => {
    if (orderLines && orderLines.length > 0) {
      let orderLineArrStr = "";
      orderLines.forEach((item, i) => {
        orderLineArrStr = item.targetName + ", " + orderLineArrStr;
      });
      orderLineArrStr = orderLineArrStr.substring(
        0,
        orderLineArrStr.length - 2
      );
      orderLineArrStr =
        orderLineArrStr.length > 30
          ? orderLineArrStr.substring(0, 30) + " ..."
          : orderLineArrStr;

      return orderLineArrStr;
    }
  };
  const formatterOrderStatus = (value, row) => {
    return getOrderStatusById(value);
  };

  return (
    <Table
      dataSource={getOrders}
      columns={[
        {
          title: "Order Code",
          className: "min-w-100px",
          dataIndex: "code",
          render(value, row, index) {
            return (
              <Link
                to={`/order/detail/${row.id}`}
                className="text-gray-800 text-hover-primary fw-bolder"
              >
                {value}
              </Link>
            );
          },
        },
        {
          title: "Ngày mua",
          className: "min-w-100px text-center",
          dataIndex: "createdDate",
          render(value, row, index) {
            return (
              <span className="fw-bolder">
                {value
                  ? Common.formatDateTimeMoment(new Date(value))
                  : new Date().toString()}
              </span>
            );
          },
        },
        {
          title: "Product",
          className: "min-w-100px",
          dataIndex: "orderLines",
          render(value, row, index) {
            return <span className="fw-bolder">{formatOrders(value)}</span>;
          },
        },
        {
          title: "Grand Total",
          className: "min-w-100px",
          dataIndex: "grandTotal",
          render(value, row, index) {
            return (
              <span className="fw-bolder">{Common.formatMoney(value)}</span>
            );
          },
        },
        {
          title: "Status",
          className: "min-w-100px text-center",
          dataIndex: "allStatus",
          render(value, row, index) {
            return (
              <span
                className={`badge ${
                  formatterOrderStatus(value, row).className
                }`}
              >
                {formatterOrderStatus(value, row).status}
              </span>
            );
          },
        },
      ]}
      actions={[
        {
          name: "View",
          type: "navigation",
          render(item) {
            return `/order/detail/${item.id}`;
          },
        },
      ]}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    stateFull: state,
    orders: state.Order.orders,
  };
};

export default connect(mapStateToProps)(Order);
