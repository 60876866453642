import Common from "../utils/Common";
import Configuration from "../configs/Config";
import axios from "axios";

export const getSchedules = ({ from, to, targetId }) => {
  return Common.getDataGraphQl(`
    {
      schedules(param:{merchantId: "${Common.getCookie(
        "merchantId"
      )}", from:"${from}", to:"${to}", targetId:"${targetId}"})
      {
          items{
              id
              name
              targetId
              triggerDateTime
          }
          totalCount
      }
    }
    `);
};

export const getListCalendarDetails = (email, from, to) => {
  return axios.post(
    `${Configuration.urlApi}/Google/GetListCalendarDetails?emailAddress=${email}&startDate=${from}&endDate=${to}`
  );
};

export const createCalendarEvent = ({
  emailAddress,
  startTime,
  endTime,
  summary,
  location,
  description,
  mintues
}) => {
  return axios.post(
    `${Configuration.urlApi}/Google/CreateCalendarEvent?emailAddress=${emailAddress}&startTime=${startTime}&endTime=${endTime}&summary=${summary}&location=${location}&description=${description}&method=email&mintues=${mintues}`
  );
};
