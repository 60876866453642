import Common from "../utils/Common";
import Config from "../configs/Config";

export const cancelOrder = (cancelOrderDTO) => {
  const stringCommandName = "RejectOrder";
  const stringDomain = "Order";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(cancelOrderDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  };
  return Common.sendSyncCommand2(dataSend);
};
export const getDeposits = (options) => {
  //console.log(options.languageId, "depostioopon");
  const userId = localStorage.getItem(Config.userId);
  const merchantId = localStorage.getItem(Config.merchantId);

  if (options === undefined) options = {};
  if (options.languageId === undefined)
    options.languageId = localStorage.getItem(Config.languageId);
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.from === undefined) options.from = null;
  if (options.to === undefined) options.to = null;
  if (options.type === undefined) options.type = Config.ProductTypes.Undefined;
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  let param = `limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${options.sort}"
  ,merchantId:"${merchantId}",targetId:"${userId}", keyword:"${options.keyword}", languageId:"${options.languageId}", type:${options.type}`;
  if (options.from) {
    param += `,from: "${options.from}"`;
  }
  if (options.to) {
    param += `,to:"${options.to}"`;
  }
  return Common.getDataGraphQl(`
        {
          depositorders(param:{${param}})
            {
              items{
                code,
                id
                orderType,
                status
                price
                costPrice
                addressName
                fromDate
                toDate
                point
                owner
                customerName
                status
                productName
                displayStatus
              },
              totalCount,
              success,
              message
            }
        }
        `);
};
export const getDeposit = (id) => {
  const userId = localStorage.getItem(Config.userId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const languageId = localStorage.getItem(Config.languageId);

  let param = `
  merchantId:"${merchantId}",languageId:"${languageId}",id:"${id}"`;

  return Common.getDataGraphQl(`
        {
          depositorder(param:{${param}})
            {

                code,
                id
                orderType,
                createdDate
                status
                price
                costPrice
                latitude
                longitude
                detailAddress
                addressName
                fromDate
                toDate
                point
                owner
                ownerEmail
                ownerMobile
                note
                referenceCode
                standardOccupancy
                images{
                  id
                  name
                  path
                }
                
                customerName
                status
                productName
                displayStatus
            
            }
        }
        `);
};

export const getOrders = (options) => {
  const languageId = localStorage.getItem(Config.languageId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem(Config.tokenId);
  if (!options) options = {};
  options = {
    limit: options.limit || 10,
    offset: options.offset || 0,
    order: options.order || "desc",
    sort: options.sort || "createdDate",
    keyword: options.keyword || "",
    from: options.from || null,
    to: options.to || null,
  };
  let params = `limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${options.sort}"
  ,keyword:"${options.keyword}"
  ,tokenUser:"${tokenId}"
  ,languageId:"${languageId}"
  ,merchantId:"${merchantId}"`;
  if (options.from) {
    params += `,from: "${options.from}"`;
  }
  if (options.to) {
    params += `,to:"${options.to}"`;
  }
  var query = `
    {
      userorders(param:{${params}
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                paymentMethodName,
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile
                },
              },
             
              totalCount
        }
      }
    `;
  return Common.getDataGraphQl(query);
};

export const getOrderDetail = (orderId) => {
  const languageId = localStorage.getItem(Config.languageId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem(Config.tokenId);
  var query = `
    {
        order(param:{id:"${orderId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,tokenUser:"${tokenId}"
       })
        {
             
                id,
                code,
                allStatus,
                createdDate,
                grandTotal,
                code
                orderLines {
                  id
                  targetName
                  price
                  originalPrice
                  quantity
                  total
                }
                shippingAmount
                subTotal
                user{
                  userName
                  email
                  mobile
                }
                payments{
                  allStatus
                  amount
                  paymentMethodName
                }
                userEmail
                userName
                userMobile
               
              
              
        }
      }
    `;
  return Common.getDataGraphQl(query);
};
export const getVacationOrderDetail = (orderId) => {
  const languageId = localStorage.getItem(Config.languageId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem(Config.tokenId);
  var query = `
    {
      vacationorder(param:{id:"${orderId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,tokenUser:"${tokenId}"
       })
        {
          allStatus
          note
          giftCodeAmount,
          promotionAmount,
          id,
          code,
          adultTotal,
          childrenAges,
          createdDate,
          grandTotal,
          code
          orderLines {
            id
            targetName
            price
            originalPrice
            quantity
            discountAmount
            total
            product{
              images{
                path
                id
              }
              id,
              type,
              fromDate,
              toDate,
              expireDate
            }
          }
          shippingAmount
          subTotal
          user{
            userName
            email
            mobile
          }
          payments{
            allStatus
            amount
            paymentMethodName
          }
          userEmail
          userName
          userMobile             
        }
      }
    `;
  return Common.getDataGraphQl(query);
};
