import Configuration from "../configs/Config";
import Common from "../utils/Common";

export const getPointTransactions = (options) => {
  const languageId = localStorage.getItem(Configuration.languageId);
  const merchantId = localStorage.getItem(Configuration.merchantId);
  const tokenId = localStorage.getItem(Configuration.tokenId);
  const userId = localStorage.getItem(Configuration.userId);
  if (options === undefined) options = {};
  if (options.languageId === undefined)
    options.languageId = localStorage.getItem(Configuration.languageId);
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.from === undefined) options.from = null;
  if (options.to === undefined) options.to = null;
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  let param = `limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${options.sort}"
  
  ,keyword:"${options.keyword}"
  ,tokenUser:"${tokenId}"
  ,targetId:"${userId}"
  ,languageId:"${languageId}"
  ,merchantId:"${merchantId}"`;
  if (options.from) {
    param += `,from: "${options.from}"`;
  }
  if (options.to) {
    param += `,to:"${options.to}"`;
  }
  var query = `
      {
        userpointtransactions(param:{${param}
         })
          {
                items {
                  orderId
                  id
                  point
                  targetName
                  orderCode
                  transactionType
                  createdDate
                },
               
                totalCount
          }
        }
      `;
  return Common.getDataGraphQl(query);
};
