import React from 'react'
import { Link } from 'react-router-dom'

const Empty = () => {
  return (
    <div className="card">
  {/*begin::Card body*/}
  <div className="card-body p-0">
    {/*begin::Wrapper*/}
    <div className="card-px text-center py-20 my-10">
      {/*begin::Title*/}
      <h2 className="fs-2x fw-bolder mb-10">Welcome!</h2>
      {/*end::Title*/}
      {/*begin::Description*/}
      <p className="text-gray-400 fs-4 fw-bold mb-10">
        There are no subscriptions added yet.
        <br />
        Kickstart your business by adding a your first subscription
      </p>
      {/*end::Description*/}
      {/*begin::Action*/}
      <Link
        to="/"
        className="btn btn-primary"
      >
        Homepage
      </Link>
      {/*end::Action*/}
    </div>
    {/*end::Wrapper*/}
    {/*begin::Illustration*/}
    <div className="text-center px-4">
      <img
        className="mw-100 mh-300px"
        alt=""
        src="assets/media/illustrations/sigma-1/5.png"
      />
    </div>
    {/*end::Illustration*/}
  </div>
  {/*end::Card body*/}
</div>

  )
}

export default Empty