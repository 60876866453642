import { AFFILIATE } from '../configs/constants/AffiliateConstant';
import { REQUEST, SUCCESS, FAILURE } from './ActionType';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {

}

export const reducer = persistReducer(
    {
        storage,
        key: "affiliate",
        blacklist: ["isLoading", "registerAffiliateResult","shortLink" ]
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(AFFILIATE.REGISTER_AFFILIATE):
                return {
                    ...state,
                    isLoading: true
                };
            case FAILURE(AFFILIATE.REGISTER_AFFILIATE):
                return {
                    ...state,
                    isLoading: false
                };
            case SUCCESS(AFFILIATE.REGISTER_AFFILIATE):

                return {
                    ...state,
                    isLoading: false,
                    registerAffiliateResult: action.payload.Success ? 'Đăng ký thành công' : action.payload.Message,
                };


            case REQUEST(AFFILIATE.CREATE_SHORT_LINK):
                return {
                    ...state,
                    isLoading: true
                };
            case FAILURE(AFFILIATE.CREATE_SHORT_LINK):
                return {
                    ...state,
                    isLoading: false
                };
            case SUCCESS(AFFILIATE.CREATE_SHORT_LINK):
                //console.log('AFFILIATE.CREATE_SHORT_LINK',action.payload );
                return {
                    ...state,
                    isLoading: false,
                    shortLink: action.payload.data.url,
                };
            case AFFILIATE.CLEAR_MESSAGE:
                return {
                    ...state,
                    registerAffiliateResult: '',
                    shortLink: '',
                };

            default:
                return state;
        }
    });

