import { gql, useQuery } from "urql";
import { getCommonValues } from "../../../utils/typeUltils";

const queryListPromotion = gql`
  query ListPromotion(
    $keyword: String = ""
    $limit: Int = 10
    $offset: Int = 0
    $type: Int
    $active: Boolean
    $order: String = "desc"
    $sort: String = "createdDate"
    $merchantId: String = ""
    $languageId: String = ""
    $from: DateTime
    $to: DateTime
  ) {
    promotions(
      param: {
        keyword: $keyword
        limit: $limit
        offset: $offset
        order: $order
        type: $type
        active: $active
        sort: $sort
        merchantId: $merchantId
        languageId: $languageId
        fromUtc: $from
        toUtc: $to
      }
    ) {
      items {
        id
        active
        createdBy
        createdDate
        totalUsed
        totalAmountUsed
        totalCodes
        type
        fromDate
        toDate
        images{
            id
            path
            name
        }
        merchantId
        name
        promotionTargets {
          id
          targetName
          targetId
        }
        pluginDatas {
          id
          pluginId
          pluginName
          pluginType
          data
          dataContent
          priority
        }
      }
      totalCount
      success
      message
    }
  }
`;

export function useQueryListPromotion({
  keyword = "",
  limit = 10,
  offset = 0,
  type,
  active,
  order = "desc",
  sort = "createdDate",

  from,
  to,
  requestPolicy = "network-only",
}: any): { result: any; refetch: any } {
  const [result, refetch] = useQuery({
    query: queryListPromotion,
    variables: (() => {
      return {
        keyword,
        limit,
        offset,
        type,
        active,
        order,
        sort,
        ...getCommonValues(["MerchantId", "LanguageId"], true),
        from,
        to,
      };
    })(),
    requestPolicy,
  });

  return { result, refetch };
}
