import { ORDER } from "../configs/constants/OrderConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  orders: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "order",
    blacklist: ["orders", "isLoading", "cancelOrderResult"],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: "",
          isLoading: true,
        };
      case FAILURE(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: "",
          isLoading: false,
        };
      case SUCCESS(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: "",
          orders: action.payload.userorders,
          isLoading: false,
        };
      case REQUEST(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: "",
          isLoading: true,
        };
      case FAILURE(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: "",
          isLoading: false,
        };

      case SUCCESS(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: action.payload.Success
            ? "Hủy đơn hàng thành công"
            : action.payload.Message,
          isLoading: false,
        };
      case ORDER.CLEAR_ORDER_INFO_MESS:
        return {
          ...state,
          cancelOrderResult: "",
        };
      default:
        return state;
    }
  }
);
