const Configuration = {
  guidEmpty: "00000000-0000-0000-0000-000000000000",
  urlApi: "https://apicommand.izzi.asia",
  urlApiCommand: "https://apicommand.izzi.asia",
  merchantCode: "vpass",
  tokenId: "tokenId",
  returnUrl: "returnUrl",
  userId: "userId",
  merchantId: "merchantId",
  languageId: "memberLanguage",
  deviceToken: "deviceToken",
  tokenLanguage: "cmsLanguage",
  goongApiKey:"6RjmIw0Lhv59nNg7Tp4ceqmnhL4kgBIzhXAGq8Ri",
  urlGraphQl: "https://apicms.izzi.asia/graphql/", //'http://graphql.labo.io/graphql',
  staticDomain: "https://static.izzi.asia/",
  imageUrl: "https://static.izzi.asia/",
  imageDefault: "https://static.izzi.asia/images/default/default-image.jpg",
  imageNotAvailable: "https://static.izzi.asia/images/default/no-image.jpg",
  imageAvatarDefault: "https://static.izzi.asia/images/2023/4/24/2304249804_hepj9ZS.png",
  logoDefaultWhite: "https://static.izzi.asia/images/defaut_logo_white.png?mode=crop&height=45",
  logoDefault: "https://static.izzi.asia/images/defaut_logo.png?mode=crop&height=45",
  imageFormatBlog: "?mode=crop&width=304&height=170",
  imageFormatBlogList: "?mode=crop&width=327&height=246",
  imageFormatBlogRelate: "?mode=crop&width=95&height=56",
  imageFormatBook: "?mode=crop&width=300&height=390",
  imageFormatSlide: "?mode=crop&width=300&height=200",
  imageFormatVideo: "?mode=crop&width=367&height=190",
  imageFormatCourse: "?mode=crop&width=293&height=150",
  sampleUserExcelLink:
    "https://static.izzi.asia/TemplateImport/TemplateImportUser.xlsx",
  urlCheckout: "http://checkout.foodtalk.vn",

  MAX_FILE_SIZE: 512000,
  domainDev: ".labo.io",
  allLanguage: [
    {
      Vi: "838aef56-78bb-11e6-b5a6-00155d582814"
    },
    {
      En: "e3509252-c42d-4ae5-9779-d4805a687a8e"
    }
  ],
  userType: {
    default: Math.pow(2, 0),
    affiliate: Math.pow(2, 2),
    staff: Math.pow(2, 5),
    author: Math.pow(2, 6),
    editor: Math.pow(2, 8),
    eVoucher: Math.pow(2, 10),
    mod: Math.pow(2, 15),
    admin: Math.pow(2, 20)
  },
  bitLy: {
    accessToken: "7e1cf9963ef14b8a23fde44f4d6721312fc32679"
  },
  StorageTypes: {
    OWNER: 1,
    YOUTUBE: 4,
    VIMEO: 16,
    GOOGLE: 64
  },
  FileTypes: {
    UNDEFINED: 1,
    BOOK: 4,
    FILE: 32,
    PDF: 64,
    IMAGE: 256,
    VIDEO: 16
  },
  ProductTypes: {
    Undefined: 0,
    None: 1,
    EVoucher: 8,
    Course: 32,
    MerchantPackage: 256,
    RoomBooking: 2048,
    OfficeBooking: 4096,
    Vacation: 16384,
    Card: 32768,
    VacationExchange: 131072,
    Fee: 262144,
    RentVacation:33554432
  },
  OrderStatus :{
    Created : 1, // 1
    Process : 1 << 5, // 32
    Timeout : 1 << 8, //
    Failed : 1 << 15, // 32768
    Closed : 1 << 20, // 1048576
  },
   PaymentStatus : {
    Created : 1, //1
    Process : 1 << 5, //32
    Canceled : 1 << 10, //1024
    Timeout : 1 << 15, //32768
    Failed : 1 << 20, //1048576
    Closed : 1 << 25, //33554432
    Refunded : 1 << 30, //1073741824
  }
};

export default Configuration;
