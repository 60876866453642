import Configuration from "../configs/Config";
import axios from "axios";
import Common from "../utils/Common";

export const getListFileService = ({
  limit = 100,
  offset = 0,
  keyword = undefined,
  merchantId
}) => {
  let _param = `merchantId:"${merchantId}", limit: ${limit}, offset: ${offset}`;
  _param += keyword ? `, keyword: "${keyword}"` : "";
  return Common.getDataGraphQl(`
    {
        files(param:{${_param}}){
          items {
            id,
            typeStorage,
            name,
            published,
            path,
            description
          },
          message,
          success,
          totalCount
        }
      }
    `);
};

export const getDriveFiles = (email, folderId) => {
  return axios.post(
    `${Configuration.urlApi}/Google/GetListFileGoogleDrive?emailAddress=${email}&folderId=${folderId}`
  )
};

export const getFileService = ({ id, merchantId }) => {
  return Common.getDataGraphQl(
    `
    {
        file(param:{id:"${id}",merchantId:"${merchantId}"}){
          id,
    name
        }
      }
      
    `
  );
};

export const googleDrivePostFile = (email, formData, folderId) => {
  return axios.post(
    `${Configuration.urlApi}/Google/GoogleDrivePostFile?emailAddress=${email}&folderId=${folderId}`,
    formData
  );
};
