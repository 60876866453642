import { NOTIFICATION } from "../configs/constants/NotificaionConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  notifications: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "notifications",
    blacklist: ["notifications", "isLoading"],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(NOTIFICATION.GET_NOTIFICATION):
        return {
          ...state,
        };
      case FAILURE(NOTIFICATION.GET_NOTIFICATION):
        return {
          ...state,
        };
      case SUCCESS(NOTIFICATION.GET_NOTIFICATION):
        return {
          ...state,
          notifications: action.payload.notificationtransactions,
        };
      case REQUEST(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
        };
      case FAILURE(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
        };

      case SUCCESS(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
        };
      default:
        return state;
    }
  }
);
