import objectPath from "object-path";

export default class HtmlClassService {
  // Public properties
  config;
  classes;
  attributes;

  /**
   * Build html element classes from layout config
   * @param layoutConfig
   */
  setConfig(layoutConfig) {
    this.config = layoutConfig;

    // scope list of classes
    this.classes = {
      header: [],
      header_container: [],
      header_mobile: [],
      header_menu: [],
      aside: [],
      aside_menu: [],
      subheader: [],
      subheader_container: [],
      content: [],
      content_container: [],
      footer_container: [],
    };

    this.attributes = {
      aside_menu: {},
      header_mobile: {},
      header: {},
      header_menu: {},
    };

    // init base layout
    this.initLayout();
    this.initLoader();

    // not yet implemented
    // this.initAsideSecondary();

    // init header and subheader menu
    this.initHeader();
    this.initSubheader();

    this.initContent();
    // init aside and aside menu
    this.initAside();

    // init footer
    this.initFooter();
  }

  /**
   * Returns Classes
   *
   * @param path: string
   * @param toString boolean
   */
  getClasses(path, toString) {
    if (path) {
      const classes = objectPath.get(this.classes, path) || "";
      if (toString && Array.isArray(classes)) {
        return classes.join(" ");
      }
      return classes.toString();
    }
    return this.classes;
  }

  getAttributes(path) {
    if (path) {
      const attributes = objectPath.get(this.attributes, path) || [];
      return attributes;
    }
    return [];
  }

  getLayout() {
    if (objectPath.get(this.config, "self.layout") === "blank") {
      return "blank";
    }

    return "default";
  }

  /**
   * Init Layout
   */
  initLayout() {
    if (objectPath.has(this.config, "self.body.background-image")) {
      if (this.config.izziMember && JSON.parse(this.config.izziMember).ImagePath) {
        
        document.body.style.backgroundImage =
          'url("' + JSON.parse(this.config.izziMember).ImagePath + '")';
      } else {
        document.body.style.backgroundImage =
          'url("' +
          objectPath.get(this.config, "self.body.background-image") +
          '")';
      }
    }

    const _selfBodyClass = objectPath.get(this.config, "self.body.class");
    if (_selfBodyClass) {
      const bodyClasses = _selfBodyClass.toString().split(" ");
      bodyClasses.forEach((cssClass) => document.body.classList.add(cssClass));
    }

    // Offcanvas directions

    document.body.classList.add("header-fixed");
    document.body.classList.add("header-tablet-and-mobile-fixed");
  }

  /**
   * Init Loader
   */
  initLoader() {}

  /**
   * Init Header
   */
  initHeader() {
    // Fixed header
  }

  /**
   * Init Subheader
   */
  initSubheader() {
    // Fixed content head
  }

  /**
   * Init Content
   */
  initContent() {}

  /**
   * Init Aside
   */
  initAside() {}

  /**
   * Init Footer
   */
  initFooter() {
    // Fixed header
  }
}
