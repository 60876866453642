import { PRODUCT } from "../configs/constants/ProductConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  productsVacation: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "products",
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(PRODUCT.GET_PRODUCT_VACATION):
        return {
          ...state,

          isLoading: true,
        };
      case FAILURE(PRODUCT.GET_PRODUCT_VACATION):
        return {
          ...state,

          isLoading: false,
        };
      case SUCCESS(PRODUCT.GET_PRODUCT_VACATION):
        return {
          ...state,
          productVacation: { ...action.payload.data?.hits?.hits[0]?._source },
          isLoading: false,
        };

      default:
        return state;
    }
  }
);
