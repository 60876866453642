import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Config from "../../configs/Config";
import Swal from "sweetalert2";
import Spinner from "../../layout/assets/icon/spinner/Spinner";
import { Link } from "react-router-dom";
import Common from "../../utils/Common";
import { useHistory } from "react-router-dom";
import { t } from "../../utils/utils";
import { connect } from "react-redux";

const ForgotPassword = ({ merchant }) => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const refMerchantCode = useRef();
  useEffect(() => {
    let merchantCode = window.location.host.startsWith("localhost")
      ? Config.merchantCode
      : window.location.host.split(".")[0];
    refMerchantCode.current = merchantCode;
  }, []);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = ({ email }) => {
    setLoading(true);
    var id = Common.guid();
    let objData = {
      Id: id,
      Domain: merchant.code,
      Email: email,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: id,
    };
    Common.sendSyncCommand(
      "RecoveryPasswordByEmail",
      "CustomerRelationshipManagement",
      objData,
      () => {
        setLoading(false);
        Swal.fire("Mật khẩu mới đã được gửi tới email của bạn.");
        setTimeout(() => {
          history.push("/auth/login");
        }, 3000);
      },
      () => {
        setLoading(false);
        Swal.fire("Lấy lại mật khẩu thất bại!");
      }
    );
  };

  const siginButton = useMemo(() => {
    return isLoading ? <Spinner /> : <>Reset</>;
  }, [isLoading]);
  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-dark mb-3">{t("Forgot Password?")}</h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            {t("Enter your email to reset your password")}.
          </div>
          {/*end::Link*/}
        </div>
        {/*begin::Heading*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Email
          </label>
          <input
            className="form-control form-control-solid"
            type="email"
            placeholder=""
            name="email"
            ref={register({ required: "Email is required." })}
            autoComplete="off"
          />
          {errors.email && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span>{errors.email.message}</span>
            </div>
          )}
        </div>
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">{t("Reset Password")}</span>
            <span className="indicator-progress">
              {t("Please wait")}...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
          <Link
            to="/auth/login"
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            {t("Sign In")}
          </Link>
        </div>
        {/*end::Actions*/}
        <div />
      </form>
      {/*end::Form*/}
    </div>
  );
};
const mapStateToProp = (state) => ({
  userInfo: state.User.userInfo,
  merchant: state.Merchant.merchant,
  lang: state.i18n.lang,
});

export default connect(mapStateToProp)(ForgotPassword);
