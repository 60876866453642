import Common from "../utils/Common";
import Config from "../configs/Config";

export const getMerchantDetail = () => {
  const merchantId = localStorage.getItem(Config.merchantId);
  var query = `
  {
    merchant(param: {id: "${merchantId}"}) {
      id
      domains
      code
      izziMember
      configuration
      metaTag
      images {
        path
        isFeatured
        name
        displayOrder
      }
    }
  }
    `;
  return Common.getDataGraphQl(query);
};
export const getExchangeRage = () => {
  const merchantId = localStorage.getItem(Config.merchantId);
  var query = `
  {
    merchant(param: {id: "${merchantId}"}) {
     exchangeRate
    }
  }
  `;

  return Common.getDataGraphQl(query);
};
