import { Slide, toast } from "react-toastify";
import { ToastPromiseParams } from "react-toastify/dist/core/toast";
import { ToastOptions } from "react-toastify/dist/types";

export const asyncToast = async <TData = unknown, TError = unknown, TPending = unknown>(
  fn: Promise<TData> | (() => Promise<TData>),
  { pending, error, success }: ToastPromiseParams,
  options?: ToastOptions
): Promise<TData> => {
  return (await toast.promise(fn, { pending, error, success }, getToastOptions(options))) as any;
};

export const asyncToast2 = async <TData = unknown, TError = unknown, TPending = unknown>(
  fn: any,
  fnParams: any,
  { pending, error, success }: ToastPromiseParams,
  options?: ToastOptions
): Promise<TData> => {
  return (await toast.promise(
    fn(...fnParams),
    { pending, error, success },
    getToastOptions(options)
  )) as any;
};

export const asyncToast3 = async <TData = unknown, TError = unknown, TPending = unknown>(options: {
  handler: Promise<TData> | (() => Promise<TData>);
  messages: ToastPromiseParams;
  options?: ToastOptions;
}): Promise<TData> => {
  const { handler } = options;
  const { pending, error, success } = options.messages;
  return (await toast.promise(
    handler,
    { pending, error, success },
    getToastOptions(options.options)
  )) as any;
};

export const getToastOptions = (options?: ToastOptions): ToastOptions => {
  let theme: any = "light";
  try {
    theme = document.getElementsByTagName("html")[0].getAttribute("data-theme");
  } catch (e) {}
  return {
    theme: theme === "light" ? "colored" : "dark",
    position: "bottom-right",
    transition: Slide,
    ...(options || {}),
  };
};
