import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { getOrderDetailAction } from "../../actions/Order";
import Configuration from "../../configs/Config";
import { LayoutSplashScreen } from "../../layout/LayoutContext";
import Empty from "../../partials/Empty";
import { getOrderDetail } from "../../services/OrderService";
import Common from "../../utils/Common";
import { t, toAbsoluteUrl } from "../../utils/utils";

const PaymentStatus = {
  Created: 1,
  Process: 1 << 5,
  Canceled: 1 << 10, //1024
  Timeout: 1 << 15, //32768
  Failed: 1 << 20, //1048576
  Closed: 1 << 25, //33554432
  Refunded: 1 << 30,
};

export function getPaymentStatus(payment) {
  let paymentStatus = {
    title: t("Not applied"),

    className: "#EEEEEE",
  };
  if (payment) {
    if (
      (PaymentStatus.Refunded & payment.allStatus) ===
      PaymentStatus.Refunded
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Refunded"),
        className: "badge-danger",
      };
    } else if (
      (PaymentStatus.Closed & payment.allStatus) ===
      PaymentStatus.Closed
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Closed"),
        className: "badge-success",
      };
    } else if (
      (PaymentStatus.Failed & payment.allStatus) ===
      PaymentStatus.Failed
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Failed"),
        className: "badge-danger",
      };
    } else if (
      (PaymentStatus.Timeout & payment.allStatus) ===
      PaymentStatus.Timeout
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Timeout"),
        className: "badge-danger",
      };
    } else if (
      (payment.allStatus & PaymentStatus.Process) ===
      PaymentStatus.Process
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Processing"),
        className: "badge-warning",
      };
    } else if (
      (payment.allStatus & PaymentStatus.Created) ===
      PaymentStatus.Created
    ) {
      paymentStatus = {
        ...paymentStatus,
        title: t("Created"),
        className: "badge-info",
      };
    }
  } else {
    paymentStatus = { ...paymentStatus, className: "badge-dark" };
  }
  return paymentStatus;
}

const OrderDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [order, setOrder] = useState();

  useEffect(() => {
    getOrderDetail(id).then((res) => setOrder(res.order));
  }, []);
  if (!order) return <LayoutSplashScreen />;
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
        {/*begin:::Tabs*/}
        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-lg-n2 me-auto">
          {/*begin:::Tab item*/}
          <li className="nav-item">
            <a
              className="nav-link text-active-primary pb-4 active"
              data-bs-toggle="tab"
              href="#kt_ecommerce_sales_order_summary"
            >
              Order Summary
            </a>
          </li>
          {/*end:::Tab item*/}
          {/*begin:::Tab item*/}

          {/*end:::Tab item*/}
        </ul>
        {/*end:::Tabs*/}
        {/*begin::Button*/}

        {/*end::Button*/}
        {/*begin::Button*/}
        <span
          onClick={() => history.goBack()}
          className="btn btn-light-primary btn-sm"
        >
          <i class="bi bi-arrow-left-short fs-2x"></i>
          {t("Back")}
        </span>
        {/*end::Button*/}
        {/*begin::Button*/}

        {/*end::Button*/}
      </div>
      {/*begin::Order summary*/}
      <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
        {/*begin::Order details*/}
        <div className="card card-flush py-4 flex-row-fluid">
          {/*begin::Card header*/}
          <div className="card-header">
            <div className="card-title">
              <h2>
                {t("Order Details")} (#{order.code})
              </h2>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                {/*begin::Table body*/}
                <tbody className="fw-bold text-gray-600">
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/files/fil002.svg*/}
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={21}
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                              fill="black"
                            />
                            <path
                              d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {t("Created Date")}
                      </div>
                    </td>
                    <td className="fw-bolder text-end">
                      {Common.formatDateTimeMoment(order.createdDate)}
                    </td>
                  </tr>
                  {/*end::Date*/}
                  {/*begin::Payment method*/}
                 {/*  */}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/finance/fin008.svg*/}
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                              fill="black"
                            />
                            <path
                              d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {t("Payment Method")}
                      </div>
                    </td>
                    <td className="text-end">
                      {order.payments?.length > 0 ? (
                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                          <tbody>
                            {order.payments.map((payment) => {
                              return (
                                <tr>
                                  <td>{payment.paymentMethodName}</td>
                                  <td>{Common.formatMoney(payment.amount)}</td>
                                  <td>
                                    <span
                                      class={`badge ${
                                        getPaymentStatus(payment).className
                                      }`}
                                    >
                                      {getPaymentStatus(payment).title}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        "N / A"
                      )}
                    </td>
                  </tr>

                  {/*end::Payment method*/}
                  {/*begin::Date*/}

                  {/*end::Date*/}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Order details*/}
        {/*begin::Customer details*/}
        <div className="card card-flush py-4 flex-row-fluid">
          {/*begin::Card header*/}
          <div className="card-header">
            <div className="card-title">
              <h2>{t("Customer Info")}</h2>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                {/*begin::Table body*/}
                <tbody className="fw-bold text-gray-600">
                  {/*begin::Customer name*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                              fill="black"
                            />
                            <path
                              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {t("Customer")}
                      </div>
                    </td>
                    <td className="fw-bolder text-end">
                      <span className="text-gray-600">
                        {order.userName || "N/A"}
                      </span>
                    </td>
                  </tr>
                  {/*end::Customer name*/}
                  {/*begin::Customer email*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            />
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}Email
                      </div>
                    </td>
                    <td className="fw-bolder text-end">
                      <span className="text-gray-600">
                        {order.userEmail || "N/A"}
                      </span>
                    </td>
                  </tr>
                  {/*end::Payment method*/}
                  {/*begin::Date*/}
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M19 4H5V20H19V4Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {t("Mobile")}
                      </div>
                    </td>
                    <td className="fw-bolder text-end">
                      {order.userMobile || "N/A"}
                    </td>
                  </tr>
                  {/*end::Date*/}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Customer details*/}
        {/*begin::Documents*/}

        {/*end::Documents*/}
      </div>
      {/*end::Order summary*/}
      {/*begin::Tab content*/}
      <div className="tab-content">
        {/*begin::Tab pane*/}
        <div
          className="tab-pane fade show active"
          id="kt_ecommerce_sales_order_summary"
          role="tab-panel"
        >
          {/*begin::Orders*/}
          <div className="d-flex flex-column gap-7 gap-lg-10">
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
              {/*begin::Payment address*/}

              {/*end::Payment address*/}
              {/*begin::Shipping address*/}

              {/*end::Shipping address*/}
            </div>
            {/*begin::Product List*/}
            <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>
                    {t("Order")} #{order.code}
                  </h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    {/*begin::Table head*/}
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-175px">{t("Name")}</th>
                        <th className="min-w-100px text-end">{t("Price")}</th>
                        {/* <th className="min-w-70px text-end">
                          {t("Origin Price")}
                        </th> */}
                        <th className="min-w-100px text-end">
                          {t("Quantity")}
                        </th>
                        <th className="min-w-100px text-end">{t("Total")}</th>
                      </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="fw-bold text-gray-600">
                      {/*begin::Products*/}
                      {order.orderLines &&
                        order.orderLines.length > 0 &&
                        order.orderLines.map((orderLine) => {
                          const {
                            targetName,
                            price,
                            originalPrice,
                            quantity,
                            total,
                          } = orderLine;
                          return (
                            <tr>
                              {/*begin::Product*/}
                              <td>{targetName}</td>
                              {/*end::Product*/}
                              {/*begin::SKU*/}
                              <td className="text-end">
                                {Common.formatMoney(price)}
                              </td>
                              {/*end::SKU*/}
                              {/*begin::Quantity*/}
                              {/* <td className="text-end">
                                {Common.formatMoney(originalPrice)}
                              </td> */}
                              {/*end::Quantity*/}
                              {/*begin::Price*/}
                              <td className="text-end">{quantity}</td>
                              {/*end::Price*/}
                              {/*begin::Total*/}
                              <td className="text-end">
                                {Common.formatMoney(total)}
                              </td>
                              {/*end::Total*/}
                            </tr>
                          );
                        })}

                      {/*end::Products*/}
                      {/*begin::Subtotal*/}
                      <tr>
                        <td colSpan={3} className="text-end">
                          {t("Sub total")}
                        </td>
                        <td className="text-end">
                          {Common.formatMoney(order.subTotal)}
                        </td>
                      </tr>
                      {/*end::Subtotal*/}
                      {/*begin::VAT*/}

                      {/*end::VAT*/}
                      {/*begin::Shipping*/}
                      {/* <tr>
                        <td colSpan={4} className="text-end">
                          {t("Shipping Amount")}
                        </td>
                        <td className="text-end">
                          {Common.formatMoney(order.shippingAmount) ||
                            Common.formatMoney(0)}
                        </td>
                      </tr> */}
                      {/*end::Shipping*/}
                      {/*begin::Grand total*/}
                      <tr>
                        <td colSpan={3} className="fs-3 text-dark text-end">
                          {t("Grand Total")}
                        </td>
                        <td className="text-dark fs-3 fw-boldest text-end">
                          {Common.formatMoney(order.grandTotal)}
                        </td>
                      </tr>
                      {/*end::Grand total*/}
                    </tbody>
                    {/*end::Table head*/}
                  </table>
                  {/*end::Table*/}
                </div>
                {order.allStatus == 1? 
                  <div className="mt-3 text-end">
                    <span
                      onClick={() => {
                        history.replace(`/checkout/${order.id}`);
                      }}
                      className="btn btn-primary"
                    >
                      {t("Checkout")}
                    </span>
                  </div>
                : null}
                
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Product List*/}
          </div>
          {/*end::Orders*/}
        </div>
        {/*end::Tab pane*/}
        {/*begin::Tab pane*/}
        <div
          className="tab-pane fade"
          id="kt_ecommerce_sales_order_history"
          role="tab-panel"
        >
          {/*begin::Orders*/}
          <div className="d-flex flex-column gap-7 gap-lg-10">
            {/*begin::Order history*/}
            <div className="card card-flush py-4 flex-row-fluid">
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>Order History</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    {/*begin::Table head*/}
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-100px">{t("Created Date")}</th>
                        <th className="min-w-175px">Comment</th>
                        <th className="min-w-70px">Order Status</th>
                        <th className="min-w-100px">Customer Notifed</th>
                      </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="fw-bold text-gray-600">
                      <tr>
                        {/*begin::Date*/}
                        <td>31/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order completed</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-success">
                            Completed
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>30/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order received by customer</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-success">
                            Delivered
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>29/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order shipped from warehouse</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-primary">
                            Delivering
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>28/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment received</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-primary">
                            Processing
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>27/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Pending payment</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>26/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment method updated</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>25/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Payment method expired</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-danger">Failed</div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>24/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Pending payment</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>No</td>
                        {/*end::Customer Notified*/}
                      </tr>
                      <tr>
                        {/*begin::Date*/}
                        <td>23/12/2021</td>
                        {/*end::Date*/}
                        {/*begin::Comment*/}
                        <td>Order received</td>
                        {/*end::Comment*/}
                        {/*begin::Status*/}
                        <td>
                          {/*begin::Badges*/}
                          <div className="badge badge-light-warning">
                            Pending
                          </div>
                          {/*end::Badges*/}
                        </td>
                        {/*end::Status*/}
                        {/*begin::Customer Notified*/}
                        <td>Yes</td>
                        {/*end::Customer Notified*/}
                      </tr>
                    </tbody>
                    {/*end::Table head*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Order history*/}
            {/*begin::Order data*/}
            <div className="card card-flush py-4 flex-row-fluid">
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>Order Data</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                    {/*begin::Table body*/}
                    <tbody className="fw-bold text-gray-600">
                      {/*begin::IP address*/}
                      <tr>
                        <td className="text-muted">IP Address</td>
                        <td className="fw-bolder text-end">172.68.221.26</td>
                      </tr>
                      {/*end::IP address*/}
                      {/*begin::Forwarded IP*/}
                      <tr>
                        <td className="text-muted">Forwarded IP</td>
                        <td className="fw-bolder text-end">89.201.163.49</td>
                      </tr>
                      {/*end::Forwarded IP*/}
                      {/*begin::User agent*/}
                      <tr>
                        <td className="text-muted">User Agent</td>
                        <td className="fw-bolder text-end">
                          Mozilla/5.0 (Windows NT 10.0; Win64; x64)
                          AppleWebKit/537.36 (KHTML, like Gecko)
                          Chrome/96.0.4664.110 Safari/537.36
                        </td>
                      </tr>
                      {/*end::User agent*/}
                      {/*begin::Accept language*/}
                      <tr>
                        <td className="text-muted">Accept Language</td>
                        <td className="fw-bolder text-end">
                          en-GB,en-US;q=0.9,en;q=0.8
                        </td>
                      </tr>
                      {/*end::Accept language*/}
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Order data*/}
          </div>
          {/*end::Orders*/}
        </div>
        {/*end::Tab pane*/}
      </div>
      {/*end::Tab content*/}
    </div>
  );
};

export default OrderDetail;
