import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSetState } from "react-use";
import { GetProductVacationDetailAction } from "../../../../../../actions/Product";
import Configuration from "../../../../../../configs/Config";
import Table from "../../../../../../partials/table/Table";
import Common from "../../../../../../utils/Common";
import { t, toAbsoluteUrl } from "../../../../../../utils/utils";
import NavbarDetailAddress from "../../../component/NavbarDetailAddress";
import Search from "../../../component/Search";
import Product from "./Product";

const ListProduct = () => {
  const [dateParams, setDateParams] = useSetState({
    from: new Date(),
    to: "",
  });
  const [peopleParams, setPeopleParams] = useState();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const productVacation = useSelector((state) => state.Product.productVacation);

  const tempProducts = productVacation?.products;
  useEffect(() => {
    if (tempProducts?.length > 0) {
      const filteredProducts = tempProducts
        .filter((product) => {
          return dateParams.from && !dateParams.to
            ? product.variants?.filter((variant) =>
                moment(variant.fromDate).isAfter(moment(dateParams.from))
              ).length > 0
            : product.variants?.filter((variant) =>
                moment(variant.fromDate).isAfter(moment(dateParams.from))
              ).length > 0;
        })
        .filter((product) =>
          peopleParams
            ? peopleParams.child <= product.childMax &&
              peopleParams.adult <= product.adultsMax
            : true
        );
      setProducts(filteredProducts);
    }
  }, [tempProducts, peopleParams, dateParams]);
  return (
    <>
      <NavbarDetailAddress />
      <Search
        setLoading={setLoading}
        setPeopleParams={setPeopleParams}
        setDateParams={setDateParams}
      />
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/*begin::Col*/}
        {products.length > 0 ? (
          products.map((product) => {
            return (
              <Product
                loading={loading}
                dateParams={dateParams}
                key={product.id}
                product={product}
              />
            );
          })
        ) : (
          <div className="card">
            {/*begin::Card body*/}
            <div className="card-body p-0">
              {/*begin::Wrapper*/}
              <div className="card-px text-center py-20 my-10">
                {/*begin::Title*/}

                {/*end::Title*/}
                {/*begin::Description*/}
                <p className="text-gray-400 fs-4 fw-bold mb-10">
                  {t("No data")}.
                </p>
                {/*end::Description*/}
                {/*begin::Action*/}

                {/*end::Action*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Illustration*/}

              {/*end::Illustration*/}
            </div>
            {/*end::Card body*/}
          </div>
        )}

        {/*end::Col*/}
        {/*begin::Col*/}

        {/*end::Col*/}
      </div>
    </>
  );
};

export default ListProduct;
