import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Spinner, Button } from "react-bootstrap";
import { getListGmailService } from "../../services/MailService";
import { useSelector } from "react-redux";
import overlayFactory from "react-bootstrap-table2-overlay";
import moment from "moment";
import "./style.scss";

const Email = () => {
  const [isLoading, setLoading] = useState(false);
  const userInfo = useSelector(state => state.User.userInfo);
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 10,
    hideSizePerPage: true,
    totalSize: 0,
    offset: 0,
    textSearch: "",
    field: "createdDate",
    sort: "desc",
    orders: []
  });

  const refTable = useRef({
    textSearch: "",
    field: "createdDate",
    sort: "desc"
  });

  const sortCaretFormatter = useCallback((order, column) => {
    if (!order) return <span>&nbsp;&nbsp;</span>;
    else if (order === "asc")
      return (
        <span>
          &nbsp;&nbsp;
          <i
            style={{ color: "#6993ff", fontSize: ".7rem" }}
            className="flaticon2-arrow-up"
          ></i>
        </span>
      );
    else if (order === "desc")
      return (
        <span>
          &nbsp;&nbsp;
          <i
            style={{ color: "#6993ff", fontSize: ".7rem" }}
            className="flaticon2-arrow-down"
          ></i>
        </span>
      );
    return null;
  }, []);

  const handleSort = useCallback((field, order) => {
    const { orders } = refTable.current;
    const comapare = (a, b) => {
      if (a[field] < b[field]) {
        return order === "desc" ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return order === "desc" ? 1 : -1;
      }
      return 0;
    };
    const nextOrders = orders.sort(comapare);
    setState(state => ({
      ...state,
      orders: nextOrders,
      field: field,
      sort: order
    }));
  }, []);

  const handleTextSearch = value => {
    refTable.current.textSearch = value;
  };

  const onSearch = () => {
    const { orders } = refTable.current;
    const nextOrders = orders.filter(f =>
      f.FormatedFrom.includes(refTable.current.textSearch)
    );
    setState({
      ...state,
      offset: 0,
      orders: nextOrders,
      totalSize: nextOrders.length
    });
  };

  useEffect(() => {
    if (userInfo.email) {
      setLoading(true);
      getListGmailService(userInfo.email)
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            const nextOrders = res.data.map(item => {
              const formatedFrom = item.From.split("!")[0];
              // eslint-disable-next-line
              const pattern = /[&\/\\#,+()$~%.'":*?{}]/g;
              return {
                ...item,
                FormatedFrom: formatedFrom.replace(pattern, "")
              };
            });
            setState(state => ({
              ...state,
              totalSize: nextOrders.length,
              orders: nextOrders
            }));
            refTable.current.orders = nextOrders;
          }
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [userInfo]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    const offset = (page - 1) * sizePerPage;
    setState({
      ...state,
      page: page,
      offset
    });
  };

  const columns = useMemo(
    () => [
      {
        text: "Gửi từ",
        dataField: "FormatedFrom",
        headerAlign: (column, colIndex) => "center",
        align: "left",
        sort: true,
        sortCaret: sortCaretFormatter,
        onSort: handleSort
      },
      {
        dataField: "Title",
        text: "Tiêu đề",
        align: "left",
        headerAlign: (column, colIndex) => "center",
        sort: true,
        sortCaret: sortCaretFormatter,
        onSort: handleSort
      },
      {
        dataField: "SendFrom",
        text: "Ngày gửi",
        headerAlign: (column, colIndex) => "center",
        align: "center",
        formatter: (_, row) => {
          try {
            return moment(row.SendFrom).format("HH:mm DD/MM/YYYY");
          } catch {
            return row.SendFrom;
          }
        }
      }
    ],
    [sortCaretFormatter, handleSort]
  );

  const pagination = useMemo(() => {
    let pagination = { ...state };
    return pagination;
  }, [state]);

  const { orders } = state;

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
      <div className="row">
        <div className="col-xl-12">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Danh sách email </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-4 my-2 my-md-0">
                  <div className="input-icon">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      id="kt_datatable_search_query"
                      onChange={event => handleTextSearch(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                  <Button
                    variant="primary"
                    onClick={onSearch}
                    disabled={isLoading}
                    type="button"
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Tìm kiếm
                  </Button>
                </div>
              </div>
              <div className="kt-separator kt-separator--dashed"></div>
              <div className="row">
                <div className="table-responsive-xl">
                  <BootstrapTable
                    loading={isLoading}
                    wrapperClasses="uk-table uk-table-striped email-table"
                    keyField="id"
                    data={orders}
                    columns={columns}
                    pagination={paginationFactory(pagination)}
                    onTableChange={handleTableChange}
                    striped
                    hover
                    condensed
                    noDataIndication="Không có dữ liệu."
                    overlay={overlayFactory({
                      spinner: true
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
