import Common from "../utils/Common";
import Configuration from "../configs/Config";
import axios from "axios";

const merchantId = localStorage.getItem(Configuration.merchantId);

export const changePassword = changePasswordDTO => {
  const stringCommandName = "ChangePasswordUser";
  const stringDomain = "User";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(changePasswordDTO),
    ModifiedBy: localStorage.getItem(Configuration.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10
  };

  return Common.sendSyncCommand2(dataSend);
};
export const updateUserInfo = userInfo => {
  //console.log(userInfo,"222")
  const stringCommandName = "UpdatePersonalInformationUser";
  const stringDomain = "User";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(userInfo),
    ModifiedBy: localStorage.getItem(Configuration.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10
  };

  return Common.sendSyncCommand2(dataSend);
};

export const logout = () => {
  const tokenId = localStorage.getItem(Configuration.tokenId);
  // localStorage.removeItem(Configuration.tokenId);
  return axios
    .get(Configuration.urlApi + `/User/Logout?tokenId=${tokenId}`)
    .then(res => {
      return res;
    });
};

export const login = objData => {
  return axios.post(
    Configuration.urlApi + "/User/LoginByMerchantCode",
    objData
  );
};

export const getOauthUri = email => {
  return axios.post(
    `${Configuration.urlApi}/Google/GetOauthUri?emailAddress=${email}`
  );
};

export const uploadAvatar = file => {
  const tokenId: any = localStorage.getItem(Configuration.tokenId);
  var bodyFormData = new FormData();
  bodyFormData.append("token", tokenId);
  bodyFormData.append("file", file);
  return axios
    .post(Configuration.urlApiCommand + "/Image/UploadAvatar", bodyFormData)
    .then(res => {
      return res.data;
    });
};

export const getTotalPoint = token => {
  return axios
    .get(Configuration.urlApi + `/User/GetTotalPoint?token=${token}`)
    .then(res => {
      return res.data;
    });
};

export const getInformationMember = (token,languageId) => {
  return axios
    .get(Configuration.urlApi + `/User/GetInformation?token=${token}&languageId=${languageId}`)
    .then(res => {
      //console.log(res.data);
      return res.data;
    });
};


export const getUser = (id, merchantId) => {
  return Common.getDataGraphQl(
    `
{
    user(param:{id: "${id}",languageId:"${Configuration.languageVi}", merchantId:"${merchantId}"}){
        active,
        categories {
          id,
          name
        },
        createdBy,
        createdDate,
        email,
        id,
        allType,
        images {
          id,
          path,
          displayOrder,
          isFeatured
        },
        description,
        subDescription,
        merchantId,
        mobile,
        modifiedBy,
        modifiedDate,
        name,
        userName,
    }
  }
  
`
  );
};

export const getUsers = options => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 100;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const type = options.type === undefined ? "" : `type:${options.type}`;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map(val => `"${val}"`).join(",")}]`;

  return Common.getDataGraphQl(`
        {
            users(param:{limit:${options.limit},offset:${
    options.offset
  },order:"${options.order}",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${Common.getCookie(
    Configuration.tokenLanguage
  )}", ${categoryIds}, ${type}})
            {
              items{
                id,
                name,
                email,
                mobile,                
                createdDate,
                modifiedDate,
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getUserInfo = tokenId => {
  var query = `{
      userInformation(param: {tokenUser: "${tokenId}"}) {
          item {
              id
              firstName
              lastName
              userName
              email
              companyName,
              companySite,
              communication,
              languageId,
              dateOfBirth
              timeZone,
              active
              avatar
              mobile
              identifyNo
              name,
              merchantId,
              allType,
              images {
                displayOrder
                id
                path
                name
              }
            }
      }
    }`;
  //console.log("getUSerInfo", tokenId);
  return Common.getDataGraphQl(query);
};
