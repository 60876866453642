import { Divider, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSetState } from "react-use";
import * as Yup from "yup";

import FormItems, { FormItemLabel, scrollToField } from "../../partials/FormItems/FormItems";
import WidgetImage from "../../partials/Image";
import { useQueryAddresses } from "../../services/AddressService";
import { createRentProduct, RentProduct } from "../../services/commands/product";
import { useQueryImages } from "../../services/ImageService";
import { sendCommandAndAffect } from "../../utils/command";
import Common from "../../utils/Common";
import { asyncToast } from "../../utils/toast";
import {
  formatDateTime,
  getCommonValues,
  stringBooleanToRealBoolean,
} from "../../utils/typeUltils";
import { dataURLtoFile, handleUploadAvatar } from "../deposit/Functions";
import VoucherList, { MessagePromotionResponse } from "./VoucherList";

type Props = {};

const schema = Yup.object().shape({
  addressName: Yup.mixed().required("is a required property"),
  adult: Yup.number()
    .required("is a required property")
    .min(1, "Số người lớn phải lớn hơn 0")
    .max(10, "Số người lớn phải nhỏ hơn 10"),
  children: Yup.number()
    .required("is a required property")
    .min(1, "Số trẻ em phải lớn hơn 0 ")
    .max(10, "Số trẻ em phải nhỏ hơn 10"),
  bedRoom: Yup.mixed().required("is a required property"),

  price: Yup.number()
    .required("is a required property")
    .min(1000000, "Số tiền cho thuê không được dưới 1.000.000"),
});
interface ICalculatePromotion {
  PromotionItems: { PromotionId: string; ApplyBeforeCheckIn }[];
  FromDate: Date;
  Amount: number;
  ModifiedDate: Date;
  ModifiedBy: string;
}
export const calCulatePromotion = (values: ICalculatePromotion) => {
  return sendCommandAndAffect({
    domain: "Promotion",
    name: "CalculateDiscountProductVacationRent",
    values,
  });
};

const Form = (props: Props) => {
  const [date, setDate] = useSetState<any>({
    fromDate: moment().add(2, "months"),
    endDate: moment().add(2, "months").add(1, "days"),
    numberOfNights: 1,
  });
  const [state, setState] = useSetState<any>({
    images: {},
    addressImage: null,
  });
  const [priceList, setPriceList] = useSetState<any>({
    AllPromotionPrice: 0,
    ApplyCurrentlyPromotionPrice: 0,
  });

  const [thumbnail, setThumbnail] = useState<any>({});
  const [isVisible, setIsVisible] = useState(false);

  const form = useForm<any>({
    defaultValues: {
      price: 0,
    },
    validationSchema: schema,
  });

  const onSubmit = (formData: any) => {
    const uploadList: any[] = [];
    if (!form.getValues("verifyTerm")) {
      toast.error("Bạn chưa đồng ý với các chính sách cho thuê !");
      return;
    }
    if (
      form.getValues("verifyStatus") &&
      !state.images.image1 &&
      !state.images.image2 &&
      !state.images.image3
    ) {
      toast.error("Hãy chọn đầy đủ thông tin xác minh");
      return;
    }
    if (state.images.image1?.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(state.images.image1.Path, state.images.image1.Name))
      );
    }
    if (state.images.image2?.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(state.images.image2.Path, state.images.image2.Name))
      );
    }
    if (state.images.image3?.Path) {
      uploadList.push(
        handleUploadAvatar(dataURLtoFile(state.images.image3.Path, state.images.image3.Name))
      );
    }
    if (thumbnail.Path) {
      uploadList.push(handleUploadAvatar(dataURLtoFile(thumbnail.Path, thumbnail.Name)));
    }
    Promise.all(uploadList).then(async (uploadRes) => {
      const values: RentProduct = {
        Id: Common.guid(),
        AddressId: formData.addressName?.value,
        Adult: +formData.adult,
        Children: +formData.children,
        BathRoom: formData.bathRoom?.value,
        DoubleBed: formData.doubleBed?.value,
        FromAge: formData.ageRange[0],
        ToAge: formData.ageRange[1],
        IncludeBreakfast: stringBooleanToRealBoolean(formData.includeBreakfast),
        HasKitchen: stringBooleanToRealBoolean(formData.hasKitchen),
        Price: +formData.price,
        SingleBed: formData.singleBed?.value,
        BedRoom: formData.bedRoom?.value,
        VerifyStatus: formData.verifyStatus,
        VerifyTerm: formData.verifyTerm,
        ContractCode: formData.contractCode,
        FromDate: formatDateTime(date.fromDate, "MM/DD/YYYY"),
        ToDate: formatDateTime(date.endDate, "MM/DD/YYYY"),
        ContractInformationOwnerImageId: uploadRes[0]?.data.Data[0]?.Id,
        ContractRoomImageId: uploadRes[1]?.data.Data[0]?.Id,
        ContractVerifyImageId: uploadRes[2]?.data.Data[0]?.Id,
        PromotionVacationRents: formData.promotions || [],
        Images: Common.createRelationObjectArray(
          formData.images?.map((img) => img.id) || [],
          formData.images?.find((img) => img.isFeatured === true)?.id
        ),

        ...getCommonValues(["ActionBy", "MerchantId", "LanguageId"]),
      };
      asyncToast(createRentProduct(values), { pending: "Đang tạo sản phẩm..." });
    });
  };
  const uploadAvatar = (file: any, type: string) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function (e) {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          ctx?.drawImage(img, 0, 0);

          var MAX_WIDTH = 800;
          var MAX_HEIGHT = 600;
          var width = img.width;
          var height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);
          var dataUrl = canvas.toDataURL(file.type);
          if (type === "thumbnail") {
            setThumbnail({ Id: Common.guid(), Path: dataUrl, Name: file.name });
          } else {
            setState({
              images: {
                ...state.images,
                [type]: {
                  Id: Common.guid(),
                  Path: dataUrl,
                  Name: file.name,
                },
              },
            });
          }
        };
        img.src = e.target?.result as any;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(form.errors)) {
      scrollToField("form", Object.keys(form.errors)[0]);
    }
  }, [form.errors]);
  const handleApplyPromotion = (arr: any[], isDelete?: boolean, price?: number) => {
    form.setValue("test", 33333);
    asyncToast(
      calCulatePromotion({
        Id: Common.guid(),
        Amount: price || +form.watch("price"),
        FromDate: formatDateTime(date.fromDate, "MM/DD/YYYY"),
        PromotionItems: arr || [],
        ...getCommonValues(["ModifiedBy", "ModifiedDate"]),
      }),
      {
        pending: !isDelete
          ? "Đang áp dụng chương trình khuyến mãi..."
          : "Đang bỏ áp dụng mã giảm giá...",
      }
    ).then((res) => {
      //console.log(JSON.parse(res.data.Message)[MessagePromotionResponse.AllPromotion], "2533");
      setPriceList({
        AllPromotionPrice: JSON.parse(res.data.Message)[MessagePromotionResponse.AllPromotion],
      });
      setPriceList({
        ApplyCurrentlyPromotionPrice: JSON.parse(res.data.Message)[
          MessagePromotionResponse.ApplyCurrently
        ],
      });
    });
  };

  const priceAfterDiscount: number = useMemo(() => {
    return form.watch("promotionType") === "all"
      ? priceList.AllPromotionPrice
      : priceList.ApplyCurrentlyPromotionPrice;
  }, [priceList, form]);
  const ownerRevenue = useMemo(() => {
    return +form.watch("price") * (90 / 100);
  }, [form]);
  useEffect(() => {});

  return (
    <form
      id="form"
      onSubmit={form.handleSubmit(onSubmit)}
      className="form row fv-plugins-bootstrap5 fv-plugins-framework"
    >
      <Controller
        as={({ onChange, value, ref }) => (
          <VoucherList
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            onSubmit={handleApplyPromotion}
            onChange={onChange}
            value={value}
          />
        )}
        control={form.control}
        name={"promotions"}
      />

      <div className="tw-grid tw-grid-cols-9 tw-gap-2">
        <div className="tw-col-span-6  mb-7 me-7 me-lg-10 gap-7 gap-lg-10">
          {/*begin::Order details*/}

          {/*end::Order details*/}
          {/*begin::Order details*/}
          <div className="card card-flush py-4">
            {/*begin::Card header*/}
            <div className="card-header">
              <div className="card-title">
                <h2>Thông tin sản phẩm cho thuê</h2>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">
              {/*begin::Billing address*/}
              <div className="d-flex flex-column gap-5 gap-md-7">
                {/*begin::Title*/}

                {/*begin::Input group*/}
                <FormItems
                  form={form}
                  formKey="form"
                  items={[
                    {
                      key: "addressName",
                      label: "Tên khu nghỉ dưỡng",
                      required: true,
                      inputProps: {
                        noOptionsMessage: (
                          <span>
                            Khu nghỉ chưa có trong hệ thống. Để thêm khu nghỉ, liên hệ với chúng tôi{" "}
                            <a target="_blank" href="https://1share.labo.io/vi-vn/pages/lien-he">
                              tại đây
                            </a>
                          </span>
                        ),
                      },
                      type: "select",
                      dataSource: useQueryAddresses,
                    },
                  ]}
                />
                <Box
                  sx={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                    "@media (min-width: 780px)": {
                      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    },
                  }}
                >
                  <FormItems
                    form={form}
                    formKey="form"
                    items={[
                      {
                        key: "adult",
                        label: "Adult",
                        required: true,
                        inputProps: {
                          type: "number",

                          defaultValue: 0,
                        },
                      },
                      {
                        key: "children",
                        label: "Children",
                        required: true,
                        inputProps: {
                          type: "number",

                          defaultValue: 0,
                        },
                      },
                      {
                        key: "ageRange",
                        label: "Tuổi của trẻ em",
                        type: "slider",
                        inputProps: {
                          min: 0,
                          max: 11,
                        },
                      },
                    ]}
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                    "@media (min-width: 780px)": {
                      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    },
                  }}
                >
                  <FormItems
                    form={form}
                    formKey="form"
                    items={[
                      {
                        key: "bedRoom",
                        label: "Number Of Sleeping Room",
                        required: true,
                        type: "select",
                        dataSource: _.range(1, 9).map((v) => ({ label: v, value: v })),
                      },
                      {
                        key: "doubleBed",
                        label: "Number Of Double Bed",

                        type: "select",
                        dataSource: _.range(1, 9).map((v) => ({ label: v, value: v })),
                      },
                      {
                        key: "singleBed",
                        label: "Number Of Single Bed",

                        type: "select",
                        dataSource: _.range(1, 9).map((v) => ({ label: v, value: v })),
                      },
                    ]}
                  />
                </Box>
                <div className="tw-grid tw-grid-cols-1 tw-gap-5 md:tw-grid-cols-2">
                  <FormItems
                    form={form}
                    formKey="form"
                    items={[
                      {
                        key: "bathRoom",
                        label: "BathRoom",
                        required: true,
                        type: "select",
                        dataSource: _.range(1, 9).map((v) => ({ label: v, value: v })),
                      },
                      {
                        key: "hasKitchen",
                        label: "Kitchen",

                        type: "radio",
                        inputProps: {
                          defaultValue: "false",
                        },
                        dataSource: [
                          { value: true, label: "Có" },
                          { value: false, label: "Không" },
                        ],
                      },
                    ]}
                  />
                </div>

                <FormItems
                  form={form}
                  formKey="form"
                  items={[
                    {
                      key: "includeBreakfast",
                      label: "Include Breakfast",

                      type: "radio",
                      inputProps: {
                        defaultValue: "false",
                      },
                      dataSource: [
                        { value: true, label: "Có" },
                        { value: false, label: "Không" },
                      ],
                    },
                  ]}
                />
                <div className="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-3">
                  <div className="d-flex flex-column">
                    <FormItemLabel required label="Check-in date" />
                    <DateRangePicker
                      onCancel={(e, picker) => {
                        picker.element.val("");
                      }}
                      initialSettings={{
                        showDropdowns: true,
                        autoApply: true,
                        startDate: date.fromDate,
                        minDate: moment(new Date()).add(2, "months"),
                        maxDate: moment(new Date()).add(2, "years"),

                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: true,
                        },
                      }}
                      onApply={(event, picker) => {
                        setDate({
                          numberOfNights: moment(picker.endDate).diff(
                            moment(picker.startDate),
                            "days"
                          ),
                        });
                        setDate({
                          fromDate: picker.startDate,
                          endDate: picker.endDate,
                        });

                        /*  setFilterParams({
                    from: moment(picker.startDate).format("YYYY-MM-DD"),
                    to: moment(picker.endDate).format("YYYY-MM-DD"),
                  }); */
                      }}
                    >
                      <input
                        /*  placeholder={formatMessage({ id: "Pick date range" })} */
                        value={moment(date.fromDate).format("DD/MM/YYYY")}
                        className="form-control "
                      />
                    </DateRangePicker>
                  </div>
                  <div>
                    <FormItemLabel required label="Check-out date" />
                    <DateRangePicker
                      onCancel={(e, picker) => {
                        picker.element.val("");
                      }}
                      initialSettings={{
                        showDropdowns: true,
                        autoApply: true,
                        minDate: moment(new Date()).add(2, "months"),
                        maxDate: moment(new Date()).add(2, "years"),
                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: true,
                        },
                      }}
                      onApply={(event, picker) => {
                        setDate({
                          startDate: moment(picker.startDate).format("DD/MM/YYYY"),
                          endDate: moment(picker.endDate).format("DD/MM/YYYY"),
                        });
                        /*  setFilterParams({
                    from: moment(picker.startDate).format("YYYY-MM-DD"),
                    to: moment(picker.endDate).format("YYYY-MM-DD"),
                  }); */
                      }}
                    >
                      <input
                        /*  placeholder={formatMessage({ id: "Pick date range" })} */
                        value={moment(date.endDate).format("DD/MM/YYYY")}
                        className="form-control "
                      />
                    </DateRangePicker>
                  </div>
                  <div>
                    <FormItemLabel label="Number of nights" />
                    <div className="fw-bold mt-4 ms-8 fs-2">{date.numberOfNights}</div>
                  </div>
                </div>

                <div className="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2">
                  <FormItems
                    form={form}
                    formKey="form"
                    items={[
                      {
                        key: "price",
                        label: "Rent Price",
                        required: true,
                        inputProps: {
                          type: "number",
                          onBlur: (e) => {
                            if (form.watch("promotions")?.length > 0) {
                              handleApplyPromotion(form.watch("promotions"), false, e.target.value);
                            }
                          },
                        },
                      },
                    ]}
                  />
                </div>

                <div className="d-flex fs-5 align-items-center">
                  <span className="fw-bolder">Chương trình chiết khấu</span>
                  <Tooltip title="Chương trình chiết khấu sẽ được tính theo giá sau giảm của chương trình chiết khấu trước đó">
                    <i className="fas fa-exclamation-circle ms-2 mb-1"></i>
                  </Tooltip>
                  <div
                    onClick={() => {
                      if (+form.watch("price") > 0) {
                        setIsVisible(true);
                      } else {
                        toast.info(
                          "Giá cho thuê phải lơn hơn 0 để áp dụng chương trình khuyến mại"
                        );
                      }
                    }}
                    className="ms-4 d-flex align-items-center text-primary cursor-pointer hover:tw-underline"
                  >
                    <i className="bi bi-plus text-primary"></i>
                    <span>Thêm chương trình khuyến mãi</span>
                  </div>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  {form.watch("promotions")?.length > 0 &&
                    form
                      .watch("promotions")

                      .map((item) => (
                        <Stack direction="row" spacing={3}>
                          <Chip
                            sx={{ fontSize: "18px" }}
                            label={`${item.Name} | ${
                              item.ApplyBeforeCheckIn
                                ? "Áp dụng trước ngày check-in " + item.ApplyBeforeCheckIn + " ngày"
                                : "Áp dụng luôn"
                            }`}
                            color={!item.ApplyBeforeCheckIn ? "primary" : undefined}
                            onDelete={() => {
                              const filteredArr = form
                                .watch("promotions")
                                .filter((tempItem) => tempItem.PromotionId !== item.PromotionId);

                              form.setValue("promotions", filteredArr);
                              handleApplyPromotion(filteredArr, true);
                            }}
                          />
                        </Stack>
                      ))}
                </div>
                <Divider />

                <FormItems
                  form={form}
                  formKey="form"
                  items={[
                    {
                      key: "promotionType",
                      type: "radio",
                      containerProps: "mb-0",
                      inputProps: {
                        defaultValue: "all",
                      },
                      dataSource: [
                        {
                          label: "Tất cả các chương trình chiết khấu",
                          value: "all",
                        },
                        {
                          label: "Chỉ chương trình chiết khấu đang có hiệu lực",
                          value: "1",
                        },
                      ],
                    },
                  ]}
                />

                <div className="tw-grid tw-grid-cols-2 gap-5 ">
                  <Typography>Tổng tiền chiết khấu</Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(
                      priceAfterDiscount ? +form.watch("price") - priceAfterDiscount : undefined
                    )}
                  </Typography>
                  <Typography>Giá cho thuê sau chiết khấu</Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(priceAfterDiscount)}
                  </Typography>
                  <Typography>
                    Phí cho thuê thành công
                    <i className="d-block">10% giá cho thuê, tối thiểu 500000đ</i>
                  </Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(+form.watch("price") - ownerRevenue)}
                  </Typography>
                  <Typography>
                    Doanh thu cho thuê của chủ sở hữu(chưa trừ thuế)
                    <i className="d-block">90% giá cho thuê</i>
                  </Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(+form.watch("price") * (90 / 100))}
                  </Typography>
                  <Typography>
                    Thuế thu nhập cá nhân
                    <i className="d-block">10% doanh thu cho thuê</i>
                  </Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(ownerRevenue * (10 / 100))}
                  </Typography>
                  <Typography variant="h5">Tổng doanh thu ròng</Typography>
                  <Typography className="tw-justify-self-end">
                    {Common.formatMoney(
                      +form.watch("price") -
                        (+form.watch("price") - ownerRevenue) -
                        ownerRevenue * (10 / 100)
                    )}
                  </Typography>
                </div>
                <Divider />
                {/*end::Input group*/}
                {/*begin::Checkbox*/}
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    ref={form.register}
                    name="verifyStatus"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setState({
                          image: {},
                        });
                      }
                    }}
                    className="form-check-input"
                    type="checkbox"
                    id="verifyStatus"
                  />
                  <label className="form-check-label" htmlFor="verifyStatus">
                    Tôi muốn xác minh kỳ nghỉ
                  </label>
                </div>
                {form.watch("verifyStatus") && (
                  <div className="notice  bg-light-warning rounded border-warning border border-dashed rounded-3 p-6">
                    <FormItems
                      form={form}
                      formKey="form"
                      items={[
                        {
                          key: "contractCode",
                          label: "Contract Code",
                        },
                      ]}
                    />
                    <div className="tw-grid tw-grid-cols-3 tw-gap-3">
                      <div>
                        <label className="fs-5 fw-bolder mb-2"> Thông tin khu nghỉ</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <i className="pb-10 d-block h-lg-80px">
                          Upload ảnh trang có thông tin khu nghỉ trên hợp đồng
                        </i>
                        <div className="image-input image-input-outline">
                          <div
                            className="image-input-wrapper tw-bg-white w-125px h-125px"
                            style={{
                              backgroundImage: `url(${state.images.image1?.Path})`,
                              backgroundSize: "cover",
                            }}
                          />

                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Change avatar"
                          >
                            <i className="bi bi-pencil-fill fs-7" />
                            {/*begin::Inputs*/}
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event: any) =>
                                uploadAvatar(event.target.files[0], "image1")
                              }
                            />
                            {/*end::Inputs*/}
                          </label>
                        </div>
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      <div>
                        <label className="fs-5 fw-bolder mb-2">Thông tin chủ sở hữu</label>
                        <i className="pb-10 d-block h-lg-80px">
                          Upload ảnh trang có thông tin chủ sở hữủ trên hợp đồng
                        </i>
                        {/*end::Label*/}
                        {/*begin::Input*/}

                        <div className="image-input image-input-outline">
                          <div
                            className="image-input-wrapper tw-bg-white w-125px h-125px"
                            style={{
                              backgroundImage: `url(${state.images.image2?.Path})`,
                              backgroundSize: "cover",
                            }}
                          />

                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Change avatar"
                          >
                            <i className="bi bi-pencil-fill fs-7" />
                            {/*begin::Inputs*/}
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event: any) =>
                                uploadAvatar(event.target.files[0], "image2")
                              }
                            />

                            {/*end::Inputs*/}
                          </label>
                        </div>

                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      <div>
                        <label className="fs-5 fw-bolder mb-2">Xác nhận hợp lệ</label>
                        <i className="pb-10 d-block tw-line-clamp-1 h-lg-80px">
                          Upload ảnh trang có Chữ ký và Dấu mộc của chủ sở hữu và khu nghỉ trên hợp
                          đồng hoặc thẻ sở hữu cùng các văn bản khác nếu có
                        </i>
                        {/*end::Label*/}
                        {/*begin::Input*/}

                        <div className="image-input image-input-outline">
                          <div
                            className="image-input-wrapper w-125px tw-bg-white h-125px"
                            style={{
                              backgroundImage: `url(${state.images.image3?.Path})`,
                              backgroundSize: "cover",
                            }}
                          />

                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Change avatar"
                          >
                            <i className="bi bi-pencil-fill fs-7" />
                            {/*begin::Inputs*/}
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event: any) =>
                                uploadAvatar(event.target.files[0], "image3")
                              }
                            />

                            {/*end::Inputs*/}
                          </label>
                        </div>

                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="dropzone dz-clickable tw-text-left h-400px tw-overflow-y-auto">
                  <div className="fw-bolder mb-3 fs-3">Chính sách cho thuê dành cho hội viên</div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae possimus quam
                  facilis, cupiditate perspiciatis illum velit eveniet doloribus harum unde
                  voluptates porro quisquam, reiciendis distinctio ipsa. Qui a sequi pariatur
                  voluptate. Earum error iusto eveniet est, ea asperiores natus facilis inventore
                  recusandae. Blanditiis sequi beatae maiores expedita. Illo tempora totam
                  dignissimos nulla voluptatem eveniet hic tenetur inventore quae. Animi fugit vitae
                  ab totam sit voluptas nemo similique eius quasi? Veritatis ad ea nostrum quam
                  illum nesciunt architecto, aliquam, harum itaque cupiditate eum saepe vitae
                  deserunt fugit praesentium repellat, reprehenderit accusamus corrupti? Libero
                  voluptate sunt debitis hic quibusdam, aut repellendus consequuntur maiores, rerum
                  soluta sint quisquam amet cum deserunt, temporibus fuga illum dolores blanditiis
                  veniam voluptatum! Quas earum est quod eum minus quibusdam, reiciendis quaerat ab,
                  eaque voluptatum repellat ex, doloribus cumque eos cupiditate porro consectetur
                  reprehenderit a! Sit eaque quasi natus adipisci omnis a quam ea nisi reprehenderit
                  minus, aspernatur unde! Expedita, sed quisquam et eum similique eligendi magni
                  voluptatem nesciunt sint, illum facere officiis, assumenda ullam nostrum atque
                  possimus dignissimos? Culpa asperiores id voluptate in odio cupiditate quis
                  tempora quibusdam distinctio, suscipit fuga maiores beatae quas nostrum reiciendis
                  facilis molestiae corrupti dignissimos debitis dolor quod? Ducimus maxime ipsa
                  quod maiores, molestias vero inventore nemo, ratione repellendus est laborum
                  doloremque voluptates ea quaerat dicta perferendis velit sunt! Illo, doloribus
                  fugiat! Error suscipit voluptatum, dicta tenetur, tempore dolor, quae dolores
                  dolorum accusamus soluta dignissimos et perspiciatis nam. Eaque ducimus
                  consequuntur sunt eos error fugiat officiis, ipsum quod molestias possimus, alias
                  repellat, minus voluptatum dolor nostrum! Quae ullam sunt corporis reprehenderit
                  recusandae cupiditate distinctio quis atque sapiente aspernatur. Minus sit
                  corporis iusto soluta hic incidunt eum doloribus voluptates asperiores veritatis
                  quasi provident eius, mollitia, est ab necessitatibus rem aliquam facere
                  laudantium eveniet alias ipsum. Blanditiis, nemo quidem veniam dolore facere
                  harum, itaque hic quibusdam quis eos nesciunt earum cum dolorum labore adipisci
                  sint, quas cupiditate ut recusandae voluptates? Consequatur molestiae explicabo
                  quaerat quas saepe perspiciatis inventore eaque voluptatibus. Iste earum culpa
                  provident recusandae vitae tenetur natus, omnis ut libero deserunt illum incidunt
                  labore, sit fugit nobis ad pariatur commodi officiis? Laborum, dolores autem! Id
                  repellat quisquam reiciendis recusandae minima eum mollitia pariatur fuga nostrum
                  quasi iure facere, ex modi reprehenderit consectetur explicabo alias ullam fugit
                  dolorem, eveniet perferendis optio. Nam iste, perferendis error neque obcaecati
                  maiores iusto voluptatum distinctio et id aspernatur ratione blanditiis explicabo
                  aut, ullam alias repellat dolorum cum rem consequuntur quidem nemo inventore!
                  Repellendus totam praesentium perspiciatis dignissimos mollitia dolor assumenda,
                  error consequatur? Libero deleniti earum numquam quibusdam minus voluptatibus
                  reprehenderit rem suscipit voluptate vel. Dolores repudiandae, illo ipsa numquam
                  ducimus hic et impedit tempora amet doloremque cupiditate, aliquid iure
                  perferendis magni doloribus ut quae alias! Est eius recusandae pariatur blanditiis
                  quidem itaque perferendis veniam quaerat aliquam assumenda debitis ut doloremque
                  earum cupiditate delectus obcaecati dignissimos repellat nesciunt reprehenderit,
                  quisquam illum laborum sed quis minus. Animi libero autem odit excepturi quia
                  corrupti ratione soluta reiciendis, magni odio incidunt eos praesentium quibusdam
                  repellat. Perspiciatis magnam tempore perferendis molestias distinctio nam?
                </div>

                <div className="form-check form-check-custom form-check-solid">
                  <input
                    ref={form.register}
                    name="verifyTerm"
                    className="form-check-input"
                    type="checkbox"
                    id="verifyTerm"
                  />
                  <label className="form-check-label" htmlFor="verifyTerm">
                    Tôi đã đọc và đồng ý các điều khoản
                  </label>
                </div>

                {/*end::Checkbox*/}
                {/*begin::Shipping address*/}
                <div className="d-none d-flex flex-column gap-5 gap-md-7">
                  {/*begin::Title*/}
                  <div className="fs-3 fw-bold mb-n2">Shipping Address</div>
                  {/*end::Title*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column flex-md-row gap-5">
                    <div className="fv-row flex-row-fluid">
                      {/*begin::Label*/}
                      <label className="form-label">Address Line 1</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        className="form-control"
                        name="kt_ecommerce_edit_order_address_1"
                        placeholder="Address Line 1"
                        defaultValue=""
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="flex-row-fluid">
                      {/*begin::Label*/}
                      <label className="form-label">Address Line 2</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        className="form-control"
                        name="kt_ecommerce_edit_order_address_2"
                        placeholder="Address Line 2"
                      />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column flex-md-row gap-5">
                    <div className="flex-row-fluid">
                      {/*begin::Label*/}
                      <label className="form-label">City</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        className="form-control"
                        name="kt_ecommerce_edit_order_city"
                        placeholder=""
                        defaultValue=""
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="fv-row flex-row-fluid">
                      {/*begin::Label*/}
                      <label className="form-label">Postcode</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        className="form-control"
                        name="kt_ecommerce_edit_order_postcode"
                        placeholder=""
                        defaultValue=""
                      />
                      {/*end::Input*/}
                    </div>
                    <div className="fv-row flex-row-fluid">
                      {/*begin::Label*/}
                      <label className="form-label">State</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        className="form-control"
                        name="kt_ecommerce_edit_order_state"
                        placeholder=""
                        defaultValue=""
                      />
                      {/*end::Input*/}
                    </div>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}

                  {/*end::Input group*/}
                </div>
                {/*end::Shipping address*/}
              </div>
              {/*end::Billing address*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Order details*/}
          <div className="d-flex justify-content-end mt-5">
            {/*begin::Button*/}
            <a
              href="/metronic8/demo2/../demo2/apps/ecommerce/catalog/products.html"
              id="kt_ecommerce_edit_order_cancel"
              className="btn btn-light me-5"
            >
              Hủy
            </a>
            {/*end::Button*/}
            {/*begin::Button*/}
            <button type="submit" form="form" className="btn btn-primary">
              <span className="indicator-label">Đăng tin</span>
              <span className="indicator-progress">
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
            {/*end::Button*/}
          </div>
        </div>
        {/*begin::Aside column*/}
        <div className="tw-col-span-3 d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10">
          <div className="card card-flush py-4">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2>Ảnh sản phẩm</h2>
              </div>

              {/*end::Card title*/}
              {/*begin::Card toolbar*/}

              {/*begin::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body text-center pt-0">
              {/*begin::Image input*/}
              {/*begin::Image input placeholder*/}
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n                .image-input-placeholder {\n                    background-image: url('/metronic8/demo1/assets/media/svg/files/blank-image.svg');\n                }\n\n                [data-bs-theme=\"dark\"] .image-input-placeholder {\n                    background-image: url('/metronic8/demo1/assets/media/svg/files/blank-image-dark.svg');\n                }                \n            ",
                }}
              />
              {/*end::Image input placeholder*/}
              <div
                className="image-input w-75 image-input-empty image-input-outline image-input-placeholder mb-3"
                data-kt-image-input="true"
              >
                {/*begin::Preview existing avatar*/}
                <div
                  className="image-input-wrapper w-100 h-200px"
                  style={{
                    backgroundImage: `url(${thumbnail.Path})`,
                    backgroundSize: "cover",
                  }}
                />
                {/*end::Preview existing avatar*/}
                {/*begin::Label*/}
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  aria-label="Change avatar"
                  data-bs-original-title="Change avatar"
                  data-kt-initialized={1}
                >
                  <i className="bi bi-pencil-fill fs-7" />
                  {/*begin::Inputs*/}
                  <input
                    type="file"
                    onChange={(event: any) => uploadAvatar(event.target.files[0], "thumbnail")}
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                  />
                  <input type="hidden" name="avatar_remove" />
                  {/*end::Inputs*/}
                </label>
                {/*end::Label*/}
                {/*begin::Cancel*/}

                {/*end::Cancel*/}
                {/*begin::Remove*/}
                <span
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="remove"
                  data-bs-toggle="tooltip"
                  aria-label="Remove avatar"
                  data-bs-original-title="Remove avatar"
                  data-kt-initialized={1}
                >
                  <i className="bi bi-x fs-2" />
                </span>
                {/*end::Remove*/}
              </div>
              {/*end::Image input*/}
              {/*begin::Description*/}
              <div className="text-muted fs-7"></div>
              {/*end::Description*/}
            </div>
            {/*end::Card body*/}
          </div>
          <div className="card card-flush py-4">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title">
                <h2>Album ảnh sản phẩm</h2>
              </div>

              {/*end::Card title*/}
              {/*begin::Card toolbar*/}

              {/*begin::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body text-center pt-0">
              {/*begin::Image input*/}
              {/*begin::Image input placeholder*/}

              {/*end::Remove*/}

              <Controller
                as={({ onChange, value, ref }) => (
                  <WidgetImage onChange={onChange} value={value} dataSource={useQueryImages} />
                )}
                control={form.control}
                name={"images"}
              />

              {/*end::Image input*/}
              {/*begin::Description*/}
              <div className="text-muted fs-7"></div>
              {/*end::Description*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Thumbnail settings*/}
          {/*begin::Status*/}
          {/*end::Template settings*/}{" "}
        </div>
      </div>

      {/*end::Aside column*/}
      {/*begin::Main column*/}

      {/*end::Main column*/}
    </form>
  );
};

export default Form;
