import { ADDRESS } from '../configs/constants/AddressConstant';
import { REQUEST, SUCCESS, FAILURE } from './ActionType';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
    addresses: [],
}

export const reducer = persistReducer(
    {
        storage,
        key: "address",
       
    },
    (state = initialState, action) => {
       
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(ADDRESS.GET_ADDRESSES):
                return {
                    ...state,
                    cancelOrderResult: '',
                    isLoading: true
                };
            case FAILURE(ADDRESS.GET_ADDRESSES):
                return {
                    ...state,
                    cancelOrderResult: '',
                    isLoading: false
                    
                };
            case SUCCESS(ADDRESS.GET_ADDRESSES):
               //console.log(action.payload,"35")
                return {
                    ...state,
                    cancelOrderResult: '',
                    addresses: action.payload.addresses.items,
                    isLoading: false
                };
          
            default:
                return state;
        }
    });

