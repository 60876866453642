import React, { Component } from "react";
import { connect } from "react-redux";
import { LayoutSplashScreen } from "../../layout/LayoutContext";
import Configuration from "../../configs/Config";
import { logoutAction } from "../../actions/User";

class Logout extends Component {
  componentDidMount() {
    this.props
      .logoutAction()
      .then(() => {
        const returnUrl = localStorage.getItem(Configuration.returnUrl);
        window.location.href = returnUrl;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return <LayoutSplashScreen />;
  }
}

const mapDispatchToProps = {
  logoutAction
};
export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps
)(Logout);
