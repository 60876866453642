import axios from "axios";
import Configuration from "../configs/Config";
import _ from "lodash"

export const GoongGeocode = {
    async fromLatLng(lat, lng) {
      const { data } = await axios.get("https://rsapi.goong.io/Geocode", {
        params: {
          latlng: `${lat},${lng}`,
          api_key: Configuration.goongApiKey,
        },
      });
  
      return data;
    },
    async fromPlaceId(placeId) {
      const { data } = await axios.get("https://rsapi.goong.io/Geocode", {
        params: {
          place_id: placeId,
          api_key: Configuration.goongApiKey,
        },
      });
      const place = data.results[0];
  
      if (place) {
        place.address_components = _.map(place.address_components, (v) => ({
          types: [],
          ...v,
        }));
  
        const { lat, lng } = place.geometry.location;
        place.geometry.location.lat = () => lat;
        place.geometry.location.lng = () => lng;
      }
  
      return place;
    },
    async getPlacePredictions(searchText) {
      const { data } = await axios.get("https://rsapi.goong.io/Place/AutoComplete", {
        params: {
          api_key: Configuration.goongApiKey,
          input: searchText,
        },
      });
  
      return data.predictions || [];
    },
  };

