import { MERCHANT } from '../configs/constants/MerchantConstant';
import {
  getMerchantDetail
} from '../services/MerchantService';

export const getMerchantDetailAction = () => ({
  type: MERCHANT.GET_MERCHANT_DETAIL,
  payload: getMerchantDetail()
});






