import React from "react";
import SVGIcon from "../assets/icon/SVGIcon";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import Config from "../../configs/Config";
import { toAbsoluteUrl } from "../../utils/utils";

class MenuAsideApp extends React.Component {
  render() {
    let menuConfig = { ...this.props.menuConfig.appAside };
    let userInfo = { ...this.props.userInfo };
    
    
    //console.log(this.props.userInfoMember);
    return (
      <div
        className="kt-grid__item kt-app__toggle kt-app__aside"
        id="kt_user_profile_aside"
      >
        {/*begin:: Widgets/Applications/User/Profile1*/}
        <div className="kt-portlet ">
          <div className="kt-portlet__head  kt-portlet__head--noborder">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                <></>
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit-y">
            {/*begin::Widget */}
            <div className="kt-widget kt-widget--user-profile-1">
              <div className="kt-widget__head">
                <div className="kt-widget__media">
                  <img
                    src={
                      userInfo.avatar
                        ? Config.staticDomain + userInfo.avatar
                        : toAbsoluteUrl("/media/users/default.jpg")
                    }
                    alt="avatar"
                  />
                </div>
                <div className="kt-widget__content">
                  <div className="kt-widget__section">
                    <a href="#!" className="kt-widget__username">
                      {userInfo.lastName}
                    </a>
                  </div>
                </div>
              </div>
              <div className="kt-widget__body">
                <div className="kt-widget__content">
                  <div className="kt-widget__info">
                    <span className="kt-widget__label">Email:</span>
                    <a href="#!" className="kt-widget__data">
                      {userInfo.email}
                    </a>
                  </div>
                  <div className="kt-widget__info">
                    <span className="kt-widget__label">Phone:</span>
                    <a href="#!" className="kt-widget__data">
                      {userInfo.mobile ? userInfo.mobile : ""}
                    </a>
                  </div>
                  {this.props.userInfoMember && (
                    <div className="kt-widget__info">
                      <span className="kt-widget__label">Hạng:</span>
                      <a href="#!" className="kt-widget__data">
                        {this.props.userInfoMember}
                      </a>
                    </div>
                  )}
                  {this.props.totalPoint && (
                    <div className="kt-widget__info">
                      <span className="kt-widget__label">Điểm khả dụng:</span>
                      <a href="#!" className="kt-widget__data">
                        {this.props.totalPoint < 0 ? 0: this.props.totalPoint}
                      </a>
                    </div>
                  )}
                    
                </div>
                <div className="kt-widget__items">
                  {menuConfig.items.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/${item.page}`}
                        className="kt-widget__item "
                      >
                        <span className="kt-widget__section">
                          <span className="kt-widget__icon">
                            <SVGIcon name={item.icon} width={24} height={24} />{" "}
                          </span>
                          <span className="kt-widget__desc">{item.title}</span>
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            {/*end::Widget */}
          </div>
        </div>
        {/*end:: Widgets/Applications/User/Profile1*/}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  stateFull: store,
  menuConfig: store.builder.menuConfig,
  userInfo: store.User.userInfo,
  totalPoint: store.User.totalPoint,
  userInfoMember: store.User.userInfoMember,

});

export default connect(mapStateToProps)(MenuAsideApp);
